import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

import { DatePipe } from '@angular/common';
import { MatDatepickerModule } from '@angular/material';
import * as myGlobals from './models/globals';


import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { MainViewComponent } from './mainView/mainView.component';
//import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
//import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
//import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';

import { BookingFormComponent } from './booking-form/booking-form.component';
import { BookingListComponent } from './booking-list/booking-list.component';
//import { CEOFormComponent } from './ceo-form/ceo-form.component';
import { DoctorFormComponent } from './doctor-form/doctor-form.component';
import { DoctorSalaryFormComponent } from './doctorSalary-form/doctorSalary-form.component';
import { BillFormComponent } from './bill-form/bill-form.component';
import { ClinicServiceFormComponent } from './clinicService-form/clinicService-form.component';
import { VisitFormComponent } from './visit-form/visit-form.component';
import { VisitViewComponent } from './visit-view/visit-view.component';
import { PaymentFormComponent } from './payment-form/payment-form.component';
import { VisitClinicServiceFormComponent } from './visitClinicService-form/visitClinicService-form.component';
import { BillClinicServiceFormComponent } from './billClinicService-form/billClinicService-form.component';
import { PatientFormComponent } from './patient-form/patient-form.component';
import { PatientViewComponent } from './patient-view/patient-view.component';
import { MedicalProfileFormComponent } from './medicalProfile-form/medicalProfile-form.component';
//import { IdentityRoleFormComponent } from './identityRole-form/identityRole-form.component';
import { EmployeeFormComponent } from './employee-form/employee-form.component';
import { LoginComponent } from './login/login.component';
import { LoginMenuComponent } from './login-menu/login-menu.component'
import { MedicineFormComponent } from './medicine-form/medicine-form.component';
import { VisitMedicineFormComponent } from './visitMedicine-form/visitMedicine-form.component';
import { PasswordResetFormComponent } from './passwordReset-form/passwordReset-form.component';
import { ToothChartComponent } from './tooth-chart/tooth-chart.component';
import { VisitPurposeFormComponent } from './visitPurpose-form/visitPurpose-form.component';


import { BillListComponent } from './bill-list/bill-list.component';
//import { CEOListComponent } from './ceo-list/ceo-list.component';
import { DoctorListComponent } from './doctor-list/doctor-list.component';
import { DoctorSalaryListComponent } from './doctorSalary-list/doctorSalary-list.component';
import { DoctorExpenseListComponent } from './doctorExpense-list/doctorExpense-list.component';
import { PatientListComponent } from './patient-list/patient-list.component';
import { ClinicServiceListComponent } from './clinicService-list/clinicService-list.component';
import { ServiceDoctorShareListComponent } from './serviceDoctorShare-list/serviceDoctorShare-list.component';
import { VisitListComponent } from './visit-list/visit-list.component';
import { PaymentListComponent } from './payment-list/payment-list.component';
import { BillClinicServiceListComponent } from './billClinicService-list/billClinicService-list.component';
import { IdentityRoleListComponent } from './identityRole-list/identityRole-list.component';
import { IdentityRoleUsersListComponent } from './identityRoleUsers-list/identityRoleUsers-list.component';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import { LabServiceListComponent } from './labService-list/labService-list.component';
import { PaginationComponent } from './pagination/pagination.component';

import { CalenderComponent } from './calender/calender';

import { ExpenseListComponent } from './expense-list/expense-list.component';
import { ExpenseFormComponent } from './expense-form/expense-form.component';
import { ReportComponent } from './reports/report.component';
import { MedicineListComponent } from './medicine-list/medicine-list.component';
import { LabListComponent } from './lab-list/lab-list.component';
import { LabFormComponent } from './lab-form/lab-form.component';

import { printFormHeaderComponent } from './printForm/printForm-header/printForm-header.component';
import { printFormFooterComponent } from './printForm/printForm-footer/printForm-footer.component';



import { FrontEndService } from './services/fronEnd.service';
import { BookingService } from './services/booking.service';
//import { CEOService } from './services/ceo.service';
import { DoctorService } from './services/doctor.service';
import { PatientService } from './services/patient.service';
import { ClinicServiceService } from './services/clinicService.service';
import { BillService } from './services/bill.service';
import { PaymentService } from './services/payment.service';
import { BillClinicServiceService } from './services/billClinicService.service';
import { VisitService } from './services/visit.service';
import { ToothService } from './services/tooth.service';
import { VisitClinicServiceService } from './services/visitClinicService.service';
import { ServiceDoctorShareService } from './services/serviceDoctorShare.service';
import { DoctorSalaryService } from './services/doctorSalary.service';
import { DoctorExpenseService } from './services/doctorExpense.service';
import { EmployeeService } from './services/employee.service';
import { MedicalProfileService } from './services/medicalProfile.service'
import { IdentityRoleService } from './services/identityRole.service';
import { IdentityRoleUsersService } from './services/identityRoleUsers.service';
import { AuthService } from './services/auth.service';
import { ExpenseService } from './services/expense.service';
import { ReportService } from './services/report.service';
import { AuthGuard } from './Auth/auth.guard';
import { AuthInterceptor } from './Auth/auth.interceptor';
import { MedicineService } from './services/medicine.service';
import { VisitMedicineService } from './services/visitMedicine.service';
import { LabService } from './services/lab.service';
import { LabServiceFormComponent } from './labService-form/labService-form.component';
import { LabServiceService } from './services/labService.service';
import { VisitPurposeService } from './services/visitPurpose.service';
import { MyVisitsListComponent } from './myVisits-list/myVisits-list.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { LabExpensesComponent } from './labExpenses/labExpenses.component';
import { LabPaymentService } from './services/labPayment.service';
import { LabPaymentFormComponent } from './labPayment-form/labPayment-form.component';
import { BillDoctorEligiblePaymentsService } from './services/billDoctorEligiblePayments.service';



@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    MainViewComponent,

    BookingFormComponent,
    BookingListComponent,
   // CEOFormComponent,
    DoctorFormComponent,
    DoctorSalaryFormComponent,
    BillFormComponent,
    ClinicServiceFormComponent,
    VisitFormComponent,
    VisitViewComponent,
    PaymentFormComponent,
    MedicineFormComponent,
    VisitClinicServiceFormComponent,
    BillClinicServiceFormComponent,
    PatientFormComponent,
    PasswordResetFormComponent,
    PatientViewComponent,
    MedicalProfileFormComponent,
   // IdentityRoleFormComponent, // role will only initiallized
    EmployeeListComponent,
    MedicineListComponent,
    VisitMedicineFormComponent,
    EmployeeFormComponent,
    ToothChartComponent,
    PaginationComponent,
    VisitPurposeFormComponent,

    CalenderComponent,

    ExpenseListComponent,
    ExpenseFormComponent,
    ReportComponent,
    LabListComponent,
    LabFormComponent,
    LabServiceFormComponent,
    LabServiceListComponent,
    LabExpensesComponent,
    LabPaymentFormComponent,

    printFormHeaderComponent,
    printFormFooterComponent,

    LoginComponent,
    LoginMenuComponent,

    BillListComponent,
  //  CEOListComponent,
    DoctorListComponent,
    DoctorSalaryListComponent,
    DoctorExpenseListComponent,
    PatientListComponent,
    ClinicServiceListComponent,
    ServiceDoctorShareListComponent,
    VisitListComponent,
    MyVisitsListComponent,
    PaymentListComponent,
    BillClinicServiceListComponent,
    IdentityRoleListComponent,
    IdentityRoleUsersListComponent,
    SpinnerComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ progressBar: true }),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    

   // ApiAuthorizationModule,
    RouterModule.forRoot([
      //{ path: 'fetch-data', component: FetchDataComponent, canActivate: [AuthorizeGuard] },
      //{ path: 'bills/new', component: BillFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCEO } },
      //{ path: 'visitMedicines/:visitId', component: VisitMedicineFormComponent },
      //{ path: 'billClinicServices', component: BillClinicServiceListComponent },
      //{ path: 'billClinicServices/:billId/:doctorId', component: BillClinicServiceFormComponent },
      //{ path: 'identity/roles', component: IdentityRoleListComponent, canActivate: [AuthGuard], data: { permittedRoles: ['CEO'] } },
        // { path: 'ceos', component: CEOListComponent },
     // { path: 'ceos/new', component: CEOFormComponent },
      // { path: 'ceos/:id', component: CEOFormComponent },
       //{ path: 'visits', component: VisitListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCEOOrDoctorOrEmployee }, runGuardsAndResolvers: 'always' },
      //{ path: 'visits/new', component: VisitFormComponent },
      //{ path: 'payments/new', component: PaymentFormComponent },
      //{ path: 'identity/roles/:identityRoleId', component: IdentityRoleFormComponent },
      //{ path: 'identity/roles/newRole', component: IdentityRoleFormComponent },
      //{ path: 'calender', component: CalenderComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff } },
      //{ path: 'medicalProfile/new/:patientId', component: MedicalProfileFormComponent},
      //{ path: 'medicalProfile/:id/:patientId', component: MedicalProfileFormComponent },



      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'identity/roles', component: IdentityRoleListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEO } },
      { path: 'identity/roles/users/:identityRoleId', component: IdentityRoleUsersListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEO } },
      { path: 'identity/login', component: LoginComponent },
      { path: 'identity/resetUserPassword', component: PasswordResetFormComponent, canActivate: [AuthGuard] },

      { path: 'bookNow', component: BookingFormComponent },
      { path: 'bookingList', component: BookingListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff } },


      { path: 'mainView', component: MainViewComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff }},
      { path: 'doctors', component: DoctorListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEO } },
      { path: 'doctors/new', component: DoctorFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEO } },
      { path: 'doctors/:id', component: DoctorFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEO } },
      { path: 'employees', component: EmployeeListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEO } },
      { path: 'employees/new', component: EmployeeFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEO } },
      { path: 'employees/:id', component: EmployeeFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEO } },



      /*Disabaled for this clinic

      { path: 'bills', component: BillListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEOOrSuperEmployee } },

      { path: 'bills/:id', component: BillFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff } },
      { path: 'toothChart', component: ToothChartComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEOOrDoctor } },
      { path: 'bookNow', component: BookingFormComponent },
      { path: 'bookingList', component: BookingListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff } },
   
      
      { path: 'doctorSalary/new', component: DoctorSalaryFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEOOrSuperEmployee } },
      { path: 'doctorSalary/:id', component: DoctorSalaryFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEOOrSuperEmployee } },
      { path: 'doctorSalaries', component: DoctorSalaryListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEOOrSuperEmployee } },
      { path: 'doctorExpenses/:id', component: DoctorExpenseListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEOOrSuperEmployee } },
      { path: 'doctorExpenses', component: DoctorExpenseListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEOOrSuperEmployee } },
      { path: 'patients', component: PatientListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff } },
      { path: 'patients/new', component: PatientFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff } },
      { path: 'patients/:id', component: PatientFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff } },
      { path: 'patients/view/:id', component: PatientViewComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff } },
      { path: 'clinicServices', component: ClinicServiceListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEO }, runGuardsAndResolvers: 'always' },
      { path: 'clinicServices/new', component: ClinicServiceFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEO } },
      { path: 'clinicServices/:id', component: ClinicServiceFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEO } },
      { path: 'serviceDoctorShare', component: ServiceDoctorShareListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEO } },
   
      { path: 'visits/view/:visitId/:patientId', component: VisitViewComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff } },
      { path: 'visits/:id/:billId', component: VisitFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff } },
      { path: 'visits/myVisits', component: MyVisitsListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff } },
      { path: 'visits/thisDayVisits', component: MyVisitsListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff } },
      { path: 'patient/newVisit/:patientId', component: VisitFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff } },
      { path: 'patient/newLabService/:patientId', component: LabServiceFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff } },
      { path: 'payments', component: PaymentListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEO } },

      { path: 'payments/:id', component: PaymentFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEO } },
      { path: 'visitClinicServices/:visitId/:billId', component: VisitClinicServiceFormComponent, canActivate: [AuthGuard], data: { permittedRoles: [myGlobals.Doctor] } },

     

     
      { path: 'medicines', component: MedicineListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEOOrDoctor } },
      { path: 'medicines/new', component: MedicineFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEOOrDoctor } },
      { path: 'medicines/:id', component: MedicineFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEOOrDoctor } },
      { path: 'expenses', component: ExpenseListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEOOrSuperEmployee }, runGuardsAndResolvers: 'always' },
      { path: 'expenses/new', component: ExpenseFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEOOrSuperEmployee } },
      { path: 'expenses/:id', component: ExpenseFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEOOrSuperEmployee } },
      { path: 'reports', component: ReportComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEOOrSuperEmployee } },
      { path: 'labs', component: LabListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff }, runGuardsAndResolvers: 'always'},
      { path: 'labs/new', component: LabFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff } },
      { path: 'labs/:id', component: LabFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff } },
      { path: 'labServices', component: LabServiceListComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff }, runGuardsAndResolvers: 'always' },
      { path: 'labServices/:id', component: LabServiceFormComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AllStaff } },
      { path: 'labExpenses', component: LabExpensesComponent, canActivate: [AuthGuard], data: { permittedRoles: myGlobals.AdminOrCeoDoctorOrCEOOrDoctorOrSuperEmployee } },

      */


    ], 
      {
        
        //runGuardsAndResolvers: 'always',
        onSameUrlNavigation: 'reload',
        enableTracing: false
      }),
  ],
  exports: [RouterModule] ,
  providers: [
    FrontEndService,
    BillService,
    BookingService,
    //CEOService,
    DoctorService,
    PatientService,
    ClinicServiceService,
    PaymentService,
    BillClinicServiceService,
    VisitService,
    ToothService,
    VisitClinicServiceService,
    ServiceDoctorShareService,
    DoctorSalaryService,
    DoctorExpenseService,
    EmployeeService,
    MedicalProfileService,
    IdentityRoleService,
    IdentityRoleUsersService,
    AuthService,
    ExpenseService,
    ReportService,
    MedicineService,
    VisitMedicineService,
    DatePipe,
    LabService,
    LabServiceService,
    LabPaymentService,
    VisitPurposeService,
    BillDoctorEligiblePaymentsService,

    { provide: HTTP_INTERCEPTORS,  useClass:AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
