import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { FrontEndService } from '../services/fronEnd.service';
import { EmployeeService } from '../services/employee.service';

import { PasswordReset } from '../models/passwordReset'
import { Employee, SaveEmployee } from '../models/employee';

@Component({
  selector: 'employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.css']
})


export class EmployeeFormComponent implements OnInit {

  confEmployeePass: string;
  isEditForm: boolean = false; // to identify either adding new enmployee / editing employee
  isSalaryValid: boolean = false;
  isPasswordMatch: boolean = false;
  isPasswordValid: boolean = false;
  calenderDate: any;
  isDayPicked: boolean = false;

  employee: Employee = {
    name: '',
    hiringDate: new Date(),
    salaryAmount: 0,
    userName: '',
    password: '',
    email: '',
    userId: '',
    title: '',
    mobile: ''
  };
  saveEmployee: SaveEmployee = {
    id : 0,
    name: '',
    hiringDate: new Date(),
    salaryAmount: 0,
    userName: '',
    password: '',
    email: '',
    userId: '',
    title: '',
    mobile: ''
  };

  formH1Text = "";
  showSpinner = true;

	constructor(
    private route: ActivatedRoute,
    private frontEndService : FrontEndService,
    private employeeService: EmployeeService,
    private toaster: ToastrService,
    private router: Router) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
		this.route.params.subscribe(p => {
			this.saveEmployee.id = +p['id'] || 0;
		});
	}

  ngOnInit() {
    this.confEmployeePass = '';

    if (this.saveEmployee.id) {
      this.isEditForm = true;
      this.employeeService.getEmployee(this.saveEmployee.id).subscribe(result => {
        this.setSaveEmployee(result);
      },
        error => console.log(error),
        () => this.showSpinner = false);
      //TODO _ assign the CEO data here
      this.formH1Text = "Edit Employee Details";
    }
    else {
      this.formH1Text = "Add New Employee";
      this.showSpinner = false;
    }
	}
	
	submit() {
		// Check if its existing patient so we are updating it
      if (this.saveEmployee.id) {
        this.employeeService.updateEmployee(this.saveEmployee).subscribe(x => {
          this.toaster.success('Edit Employee!', 'Update successfully.');
          this.router.navigateByUrl('/employees');
        }, error => {
          this.toaster.error('Edit Employee!', error.error);
          });
        
		}
		// Check if its new patient so we are adding it
      else {
        
        this.setEmployee(this.saveEmployee);
        this.employeeService.createEmployee(this.employee).subscribe(x => {
          this.toaster.success('Add Employee!', 'Add successfully.');
          this.router.navigateByUrl('/employees');
        }, error => {
          this.toaster.error('Add Employee!', error.error);
          });
        
		}
  }

  private setSaveEmployee(saveEmployee: SaveEmployee) {

    // don't fill the id as it comes 0 from the server 
    //this.saveEmployee.id = saveEmployee.id;
    this.saveEmployee.name = saveEmployee.name;
    this.calenderDate = this.frontEndService.formatBkEndDate(saveEmployee.hiringDate);
    this.saveEmployee.hiringDate = saveEmployee.hiringDate;
    this.saveEmployee.salaryAmount = saveEmployee.salaryAmount;
    this.saveEmployee.email = saveEmployee.email;
    this.saveEmployee.userName = saveEmployee.userName;
    this.saveEmployee.userId = saveEmployee.userId;
    this.saveEmployee.title = saveEmployee.title;
    this.saveEmployee.mobile = saveEmployee.mobile;
  }

  private setEmployee(saveEmployee: SaveEmployee) {
    
    this.employee.name = saveEmployee.name;
    this.employee.hiringDate = saveEmployee.hiringDate;
    this.employee.salaryAmount = saveEmployee.salaryAmount;
    this.employee.email = saveEmployee.email;
    this.employee.userName = saveEmployee.userName;
    this.employee.password = saveEmployee.password;
    this.employee.userId = saveEmployee.userId;
    this.employee.title = saveEmployee.title;
    this.employee.mobile = saveEmployee.mobile;
  }

  confirmPassword(password1, password2) {
    this.isPasswordMatch = this.frontEndService.isMatch(password1, password2);
  }

  validatePassword(password) {
    this.isPasswordValid = this.frontEndService.validatePassword(password)
  }

  validateSalary() {
    var input;
    input = document.getElementById("employeeSalary");

    if (input.value < 0 || input.value == null || input.value == undefined || input.value.length == 0)
      this.isSalaryValid = false;
    else
      this.isSalaryValid = true;
  }

  datePiced() {
    var input;
    input = document.getElementById("employeeHireDate");
    this.saveEmployee.hiringDate = input.value;
    this.isDayPicked = true;
  }

}
