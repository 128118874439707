import { Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/forkJoin';

import { SaveDoctorSalary } from '../models/doctorSalary';
import { DoctorVisitService } from '../models/DoctorVisitService';
import { SaveDoctor } from '../models/doctor';
import { DoctorBillExpenseList } from '../models/DoctorBillExpenseList';
import { BillDoctorPaymentsQuery } from '../models/BillDoctorPaymentsQuery';

import { DoctorExpenseService } from '../services/doctorExpense.service';
import { DoctorService } from '../services/doctor.service';
import { FilterDates } from '../models/filterDates';
import { FrontEndService } from '../services/fronEnd.service';
import { BillDoctorEligiblePaymentsService } from '../services/billDoctorEligiblePayments.service';




@Component({
  selector: 'doctorExpense-list',
  templateUrl: './doctorExpense-list.component.html',
  styleUrls: ['./doctorExpense-list.component.css']
})

export class DoctorExpenseListComponent implements OnInit {

  doctorSalaries: SaveDoctorSalary[];
  doctorVisitServices: DoctorVisitService[];
  doctors: SaveDoctor[];
  doctorBillExpenseList: DoctorBillExpenseList = {
    doctorBillExpenses: [],
    drTotalBalance: 0
  }
  BillDoctorPaymentsQuery: BillDoctorPaymentsQuery = {
    doctorId : 0,
    index : 0
  }


  reportTypeList: any[] = [{ name: "Balance Report" }, { name: "Salary Report" }, { name: "Service Report" }]
  reportType: any;

  doctorId: number = 0;
  doctorTotalSalaries: number = 0;
  doctorTotalNumberOfServices: number = 0;
  doctorBalance: number = 0;

  sources: any[];
  details: string = "feesDetails";


  filterDates: FilterDates = {
    id: 0,
    startDateDay: 0,
    startDateMonth: 0,
    startDateYear: 0,
    endDateDay: 0,
    endDateMonth: 0,
    endDateYear: 0,
  }

  startDate = new Date();
  calenderStartDate: any;
  calenderEndDate: any;
  endDate = new Date();
  reportViewId = 0;

  constructor(
    private route: ActivatedRoute,
    private frontEndService: FrontEndService,
    private doctorExpenseService: DoctorExpenseService,
    private billDoctorEligiblePaymentsService: BillDoctorEligiblePaymentsService,
    private doctorService: DoctorService)
  {
    this.route.params.subscribe(p => {
      this.doctorId = +p['id'] || 0;
    });

  }

  showSpinner = true;

  ngOnInit() {
    this.calenderStartDate = this.frontEndService.formatBkEndDate(this.startDate);
    this.calenderEndDate = this.frontEndService.formatBkEndDate(this.endDate);

    this.doctorService.getDoctors().subscribe(result => {
      this.doctors = result;
    },
      error => console.log(error),
      () => this.showSpinner = false);

    if (this.doctorId != 0) {
      this.showSpinner = true;
      this.fillExpensesLists();
    }
      

  }

  fillExpensesLists() {
    this.sources = [
      this.doctorExpenseService.getDoctorSalaries(this.filterDates),
      this.doctorExpenseService.getDoctorServiceReport(this.filterDates)
    ];

    Observable.forkJoin(this.sources).subscribe(data => {
      this.setDoctorSalaries(data[0]);
      this.setDoctorVisitServices(data[1]);
      this.setDoctorReportData(data[0], data[1]);
    },
      error => console.log(error),
      () => this.showSpinner = false);
  }

  setDoctorReportData(salaries: any, doctorVisitServices: any) {

      if (salaries.length > 0) {
        for (var i = 0; i < salaries.length; i++) {
          this.doctorTotalSalaries += salaries[i].salaryAmount;
        }
      }
      else {
        this.doctorTotalSalaries = 0;
      }

    if (doctorVisitServices.length > 0) {
      for (var i = 0; i < doctorVisitServices.length; i++) {
          this.doctorTotalNumberOfServices += 1;
        }
      }
      else {
      this.doctorTotalNumberOfServices = 0;
      }

    }

  setDoctorSalaries(salaries : any) {
    this.doctorSalaries = salaries;
  }

  setDoctorVisitServices(doctorVisitServices : any) {
    this.doctorVisitServices = doctorVisitServices;
  }

  doctorChanged(id) {
    //view reset
    this.reportType = '';
    this.reportViewId = 0;
    this.doctorBillExpenseList.doctorBillExpenses = [];
    this.doctorBillExpenseList.drTotalBalance = 0;
    this.BillDoctorPaymentsQuery.index = 0;
    this.BillDoctorPaymentsQuery.doctorId = 0;

    this.doctorTotalSalaries = 0;
    this.doctorTotalNumberOfServices= 0;
    this.doctorBalance = 0;
    this.doctorSalaries = [];
    this.doctorVisitServices = [];
    this.doctorId = id;
    //this.fillExpensesLists(id);
  }

  reportTypeChanged(reportType) {
    //view setting
    this.reportType = reportType;
    this.reportViewId = 0;
  }

  runReport(strDate: Date, endDate: Date) {
    this.showSpinner = true;
    var tempstart = new Date(strDate);
    var tempend = new Date(endDate);

    this.formFilterDates(tempstart, tempend);
    this.fillExpensesLists();
    
  }

  balanceReport() {
    this.getDoctorExpenses();
  }


  getDoctorExpenses() {
    this.showSpinner = true;
    this.BillDoctorPaymentsQuery.doctorId = this.doctorId;

    this.billDoctorEligiblePaymentsService.getAllBillDoctorEligiblePaymentsByDoctorId(this.BillDoctorPaymentsQuery).subscribe(
      data => {
        //this.doctorBillExpenseList = data;
        this.setDoctorBillExpenseList(data)
      },
      error => console.log(error),
      () => {
        this.showSpinner = false;
      }
    );

  }

  private setDoctorBillExpenseList(doctorBillExpenseList) {
    //this.doctorBillExpenseList.doctorBillExpense = doctorBillExpenseList.doctorBillExpensesResource;
    for (var i = 0; i < doctorBillExpenseList.doctorBillExpenses.length; i++) {
      this.doctorBillExpenseList.doctorBillExpenses.push(doctorBillExpenseList.doctorBillExpenses[i]);
      this.BillDoctorPaymentsQuery.index++;
    }


    this.doctorBillExpenseList.drTotalBalance = doctorBillExpenseList.drTotalBalance;   
  }

  formFilterDates(strDate: Date, endDate: Date) {
    this.filterDates.id = this.doctorId;
    //This function forms the start/end filter dates to be sent through api to backend
    this.filterDates.startDateDay = strDate.getUTCDate();
    this.filterDates.startDateMonth = strDate.getUTCMonth() + 1;
    this.filterDates.startDateYear = strDate.getFullYear();
    this.filterDates.endDateDay = endDate.getUTCDate();
    this.filterDates.endDateMonth = endDate.getUTCMonth() + 1;
    this.filterDates.endDateYear = endDate.getFullYear();
  }

  datePicked() {
    var fromDate;
    var toDate;
    fromDate = document.getElementById("startDate");
    toDate = document.getElementById("endDate");
    this.startDate = fromDate.value;
    this.endDate = toDate.value;
    //this.isDayPicked = true;
  }
}


