import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/forkJoin';

import { BillService } from '../services/bill.service';
import { PatientService } from '../services/patient.service';
import { DoctorService } from '../services/doctor.service';
import { BillClinicServiceService } from '../services/billClinicService.service';
import { VisitService } from '../services/visit.service';
import { MedicalProfileService } from '../services/medicalProfile.service';

import { AuthService } from '../services/auth.service';

import { SaveDoctor } from '../models/doctor';
import { Patient, SavePatient } from '../models/patient';
import { SaveBill } from '../models/bill';
import { SaveVisit } from '../models/visit';
import { MedicalProfile, SaveMedicalProfile } from '../models/medicalProfile';

@Component({
	selector: 'patient-view',
  templateUrl: './patient-view.component.html',
  styleUrls: ['./patient-view.component.css']
})


export class PatientViewComponent implements OnInit {

  @Input() refreshPatientView: any;
  @Input() amIChildOfMainView: any;
  @Input() patientIdModal: any;

  sources: any[];
  tabId: any = 1;
  medicalProfileId: number;
  isMedicalProfileExists: boolean = false;
  showSpinner = true;
  userRoleValue: number = 100;

  bills: SaveBill[] = [];
  doctors: SaveDoctor[] = [];
  visits: SaveVisit[] = [];


  openedBillId: number = 0;
  openedBill: SaveBill;

  savePatient: SavePatient = {
    id: 0,
    name: '',
    mobile: '',
    gender: '',
    address: '',
    age: '',
    occupation: '',
    joiningDate: null,
    email: '',
    nationality: '',
    referral: "",
    insuranceCompany: '',
    doctorId: 0,
    doctorName:'',
    bills: [],
    visits: []
  };

  patient: Patient = {
    name: '',
    mobile: '',
    gender: '',
    address: '',
    age: '',
    occupation: "",
    email: "",
    nationality: "",
    referral: "",
    insuranceCompany: "",
    doctorId: 0,
    bills: [],
    visits: []
  };


  constructor(
		private route: ActivatedRoute,
    private billService: BillService,
    private patientService: PatientService,
    private doctorService: DoctorService,
    private billClinicServiceService: BillClinicServiceService,
    private visitService: VisitService,
    private medicalProfileService: MedicalProfileService,
    private authService: AuthService) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
		this.route.params.subscribe(p => {
			this.savePatient.id = +p['id'] || 0;
		});
	}

  ngOnInit() {

    if (!this.amIChildOfMainView)
      this.initialize();

  }

  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    if (changes['refreshPatientView']) {
      this.savePatient.id = this.patientIdModal;
      this.initialize();
    }
  }


  initialize() {
    this.userRoleValue = this.authService.getRoleValue();

    this.sources = [
      this.doctorService.getDoctors(),
    ];

    if (this.savePatient.id) {
      this.sources.push(this.patientService.getPatient(this.savePatient.id));
    }

    Observable.forkJoin(this.sources).subscribe(data => {
      this.setDoctors(data[0]);

      if (this.savePatient.id) {
        this.setSavePatient(data[1]);
      }

    },
      error => console.log(error),
      () => this.showSpinner = false);

    this.medicalProfileService.getMedicalProfileByPatietnId(this.savePatient.id).subscribe(data => {
      //Chech if there is a Medical Profile or not.
      if (data != null) {
        this.setMedicalProfile(data);
        this.isMedicalProfileExists = true;
      }

    });
  }

  private setSavePatient(savePatient: any) {
    this.savePatient.id = savePatient.id;
    this.savePatient.name = savePatient.name;
    this.savePatient.mobile = savePatient.mobile;
    this.savePatient.age = savePatient.age;
    this.savePatient.address = savePatient.address;
    this.savePatient.gender = savePatient.gender;
    this.savePatient.occupation = savePatient.occupation;
    this.savePatient.joiningDate = savePatient.joiningDate;
    this.savePatient.email = savePatient.email;
    this.savePatient.nationality = savePatient.nationality;
    this.savePatient.referral = savePatient.referral;
    this.savePatient.insuranceCompany = savePatient.insuranceCompany;
    this.savePatient.doctorId = savePatient.doctorId;
    this.savePatient.doctorName = savePatient.doctorName;
    this.savePatient.bills = savePatient.bills;
    this.savePatient.visits = savePatient.visits;
    this.setBills(this.savePatient.bills);
    this.setVisits(this.savePatient.visits); 
  }

  private setPatient(savePatient: any) {
    this.patient.name = savePatient.name;
    this.patient.mobile = savePatient.mobile;
    this.patient.bills = savePatient.bills;
    this.patient.visits = savePatient.visits;
    //this.setBills(this.savePatient.bills);
    //this.setVisits(this.savePatient.visits);
  }

  private setDoctors(doctors: any) {
    this.doctors = doctors;
  }

  private setMedicalProfile(medicalProfile: any) {
    this.medicalProfileId = medicalProfile.id;
  }

  private setBills(bills: any) {
    //I have done this code in this way to get the list of Bills for this patient so I have collect all httpRequests in the "billSource"
    //then I have called all with frokJoin and the data that I got I filled with it the bills.

    // TODO -- Try to minimize the code here as its repeated in setBills and setVisits
    var billSources = [];
    for (let i = 0; i < bills.length; i++) {
      billSources.push(this.billService.getBill(bills[i]));
    }

    Observable.forkJoin(billSources).subscribe(data => {
      this.setBillList(data);
      this.billClinicServiceService.getBillClinicServicesByBillId(this.setOpenedBillId(data)).subscribe(data => {
      });
    });
  }

  private setBillList(b: any) {
    this.bills = b;
  }

  private setOpenedBillId(b: any) {

    for (let i = 0; i <= b.length; i++) {
      if (b[i].status == "Open") {
        this.openedBillId = b[i].id;
        this.openedBill = b[i];
        return this.openedBillId;
      }
    }
  }



  private setVisits(visits: any) {

    // TODO -- Try to minimize the code here as its repeated in setBills and setVisits
    var visitSources = [];
    for (let i = 0; i < visits.length; i++) {
      visitSources.push(this.visitService.getVisit(visits[i]));
    }

    Observable.forkJoin(visitSources).subscribe(data => {
      this.setVisitList(data);
    });
  }

  private setVisitList(v: any) {
    for (let i = 0; i < v.length; i++) {
      if(v[i] != null)
      this.visits.push(v[i]);
    }
    
  }

	submit() {
		// Check if its existing patient so we are updating it
		if (this.savePatient.id) {
          this.patientService.updatePatient(this.savePatient).subscribe(x => console.log());
		}
		// Check if its new patient so we are adding it
    //TODO This should be removed because this is view only for existing patient. 
    else {
          this.setPatient(this.savePatient);
          this.patientService.createPatient(this.patient).subscribe(x => console.log());
		}
  }

  exit() {
    window.location.reload();
  }

  navTabToggle(tabId: any) {
    this.tabId = tabId;
  }
}
