import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DoctorSalary , SaveDoctorSalary } from '../models/doctorSalary';
import { DoctorSalarywithBillsDistribution } from '../models/doctorSalarywithBillsDistribution';

@Injectable()
export class DoctorSalaryService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getDoctorSalaries() {
    return this.http.get<SaveDoctorSalary[]>(this.baseUrl + 'api/doctorSalaries');
	}

  getDoctorSalary(id: number) {
    return this.http.get<SaveDoctorSalary>(this.baseUrl + 'api/doctorSalaries' + '/' + id);
	}

  createDoctorSalary(doctorSalary: DoctorSalarywithBillsDistribution) {
    console.log("Doctor Salary");
    return this.http.post<DoctorSalarywithBillsDistribution>(this.baseUrl + 'api/doctorSalaries', doctorSalary);
	}

  updateDoctorSalary(saveDoctorSalary: SaveDoctorSalary) {
    return this.http.put<SaveDoctorSalary>(this.baseUrl + 'api/doctorSalaries' + '/' + saveDoctorSalary.id, saveDoctorSalary);
	}
}
