import { Component, OnInit, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { SaveClinicService } from '../models/clinicService';
import { ClinicServiceService } from '../services/clinicService.service' 
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'clinicService-list',
  templateUrl: './clinicService-list.component.html',
  styleUrls: ['./clinicService-list.component.css']
})

export class ClinicServiceListComponent implements OnInit {

  public clinicServices: SaveClinicService[];

  srvEdited: SaveClinicService = {
    id: 0,
    name: '',
    price: 0,
    isMedical:true,
    discount: 0 
  }

  contentEdit: boolean; // used to enable table contents editing
  srvId: number; //passes chosen service ID from template to logic
  isSaved: boolean; // indicates whether saveChanges button is pressed

  navigationSubscription;

  showSpinner = true;

  constructor(private clinicService: ClinicServiceService,
    private toaster: ToastrService,
    private router: Router,
 
  ) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        
        this.clinicService.getClinicServices().subscribe(result => {
          this.clinicServices = result;
        });
      }
    });
  }


  ngOnInit() {
    
      this.clinicService.getClinicServices().subscribe(result => {
      this.clinicServices = result;
      },
        error => console.log(error),
        () => this.showSpinner = false);
  }

  onRequest() {
    this.srvEdited.id = this.srvId;
    for (var i = 0; i < document.getElementsByClassName((this.srvId).toString()).length; i++) {
      var td = document.getElementsByClassName((this.srvId).toString())[i];
      td.setAttribute('contenteditable', 'true');
      td.setAttribute('style', 'background-color:lightgray;');
    }

    this.isSaved = false;

    for (var i = 0; i < document.getElementsByClassName('edit-btn').length; i++) {
      var btn = <HTMLInputElement>document.getElementsByClassName('edit-btn')[i];
      btn.disabled = true;
    }

    for (var i = 0; i < document.getElementsByClassName('delete-btn').length; i++) {
      var btn = <HTMLInputElement>document.getElementsByClassName('delete-btn')[i];
      btn.disabled = true;
    }
    
  }

  onSaveChanges() {
    this.showSpinner = true;
    this.clinicService.updateClinicService(this.srvEdited).subscribe(x => {
      this.toaster.success('Edit Service!', 'Update successfully.');
    }, error => {
      this.toaster.error('Edit Service!', error.error);
      },
      () => {
        this.showSpinner = false;
        this.router.navigateByUrl('/clinicServices')
        });

  }

  deleteExpense(): void {

    this.clinicService.deleteClinicService(this.srvId).subscribe();
    this.router.navigate(['/clinicServices']);
  }

  afterRequest() {
    for (var i = 0; i < document.getElementsByClassName((this.srvId).toString()).length; i++) {
      var td = document.getElementsByClassName((this.srvId).toString())[i];
      td.setAttribute('contenteditable', 'false');
      td.setAttribute('style', 'background-color:white;');
    }

    for (var i = 0; i < document.getElementsByClassName('delete-btn').length; i++) {
      var btn = <HTMLInputElement>document.getElementsByClassName('delete-btn')[i];
      btn.disabled = false;
    }

    this.isSaved = true;

    for (var i = 0; i < document.getElementsByClassName('edit-btn').length; i++) {
      var btn = <HTMLInputElement>document.getElementsByClassName('edit-btn')[i];
      btn.disabled = false;
    }

    this.router.navigateByUrl('/clinicServices');
  }
}


