import { Component, OnInit, Inject } from '@angular/core';
import { BillServiceWithDrProgress } from '../models/billServiceWithDrProgress';
import { BillClinicServiceService} from '../services/billClinicService.service' 

@Component({
	selector: 'billClinicService-list',
  templateUrl: './billClinicService-list.component.html'
})

export class BillClinicServiceListComponent implements OnInit {

  public billClinicServices: BillServiceWithDrProgress[];

  constructor(private billClinicServiceService: BillClinicServiceService) {  }


	ngOnInit() {
      this.billClinicServiceService.getBillClinicServices().subscribe(result => {
        this.billClinicServices = result;
      });
	}
}


