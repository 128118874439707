import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MedicineService } from '../services/medicine.service';
import { Medicine, SaveMedicine } from '../models/medicine';

@Component({
	selector: 'medicine-form',
  templateUrl: './medicine-form.component.html'
})


export class MedicineFormComponent implements OnInit {

  medicine: Medicine = {
    name: '',
    activeSubstance: '',
    type: '',
    note: ''
  };
  saveMedicine: SaveMedicine = {
    id : 0,
    name: '',
    activeSubstance: '',
    type: '',
    note: ''
  };

  formH1Text = "Add New Medicine";
  showSpinner = true;

	constructor(
		  private route: ActivatedRoute,
      private medicineService: MedicineService,
      private toaster: ToastrService,
      private router: Router) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
		this.route.params.subscribe(p => {
          this.saveMedicine.id = +p['id'] || 0;
		});
	}

	ngOnInit() {
      if (this.saveMedicine.id) {
        this.medicineService.getMedicine(this.saveMedicine.id).subscribe(result => {
          this.saveMedicine = result;
        },
          error => console.log(error),
          () => this.showSpinner = false);;
        this.formH1Text = "Edit Medicine Details";
      }
      else {
        this.showSpinner = false;
        this.formH1Text = "Add New Medicine";
      }
	}
	
	submit() {
		// Check if its existing Medicine so we are updating it
      if (this.saveMedicine.id) {
        this.medicineService.updateMedicine(this.saveMedicine).subscribe(x => {
          this.toaster.success('Edit Medicine!', 'Update successfully.');
          this.router.navigateByUrl('/medicines');
        });
		}
		// Check if its new Medicine so we are adding it
      else {
        this.setMedicine(this.saveMedicine);
        this.medicineService.createMedicine(this.medicine).subscribe(x => {
          this.toaster.success('Add medicince!', 'Add successfully.');
          this.router.navigateByUrl('/medicines');
        });
      }
      
  }

  private setMedicine(saveMedicine: SaveMedicine) {
    this.medicine.name = saveMedicine.name;
    this.medicine.activeSubstance = saveMedicine.activeSubstance;
    this.medicine.note = saveMedicine.note;
    this.medicine.type = saveMedicine.type;
  }
}
