import { Component, OnInit, Inject } from '@angular/core';
import { SaveEmployee } from '../models/employee';
import { EmployeeService } from '../services/employee.service';
import { AuthorizeService } from 'src/api-authorization/authorize.service'; 

@Component({
	selector: 'employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})

export class EmployeeListComponent implements OnInit {

  public employees: SaveEmployee[];
  showSpinner = true;

  constructor(private employeeService: EmployeeService,
    private authorizeService: AuthorizeService) { }


	ngOnInit() {
      this.employeeService.getEmployees().subscribe(result => {
        this.employees = result;
      },
        error => console.log(error),
        () => this.showSpinner = false);
  }
}


