import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SaveIdentityRole } from '../models/identityRole';
import { IdentityRoleUsers } from '../models/identityRoleusers';
import { IdentityRoleUsersEdit } from '../models/identityRoleUsersEdit';
import { SaveIdentityUser } from '../models/identityUser';

import { IdentityRoleService } from '../services/identityRole.service';
import { IdentityRoleUsersService } from '../services/identityRoleUsers.service';
import { CEOService } from '../services/ceo.service';


@Component({
  selector: 'identityRoleUsers-list',
  templateUrl: './identityRoleUsers-list.component.html'
})

export class IdentityRoleUsersListComponent implements OnInit {

  saveIdentityRole: SaveIdentityRole = {
    id: '',
    name: ''
  };

  identityRoleUsers: IdentityRoleUsers = {
    role: null,
    members: [],
    nonMembers: []
  }

  identityRoleUsersEdit: IdentityRoleUsersEdit = {
    roleId: '',
    roleName: '',
    addIds: [],
    deleteIds: [],
  }


  // these two lists are only for showing the summry interface 
  addUsers: SaveIdentityUser[] = [];
  deleteUsers: SaveIdentityUser[] = [];

  showSpinner = true;

  constructor(private route: ActivatedRoute,
    private identityRoleService: IdentityRoleService,
    private identityRoleUsersService: IdentityRoleUsersService) {

    this.route.params.subscribe(p => {
      this.saveIdentityRole.id = p['identityRoleId'] || '';
    });
  }


  ngOnInit() {
    if (this.saveIdentityRole.id) {
      this.identityRoleService.getIdentityRole(this.saveIdentityRole.id).subscribe(result => {
        this.saveIdentityRole = result;
        this.identityRoleUsersEdit.roleId = this.saveIdentityRole.id;
        this.identityRoleUsersEdit.roleName = this.saveIdentityRole.name;
      });
      this.identityRoleUsersService.getIdentityRoleUsers(this.saveIdentityRole.id).subscribe(result => {
        this.identityRoleUsers = result;
      },
        error => console.log(error),
        () => this.showSpinner = false);
    }
  }


  onClinicAddIdsToggle(user: any, $event: any) {
    if ($event.target.checked) {
      this.identityRoleUsersEdit.addIds.push(user.id);
      this.addUsers.push(user);
    }
    else {
      var index = this.identityRoleUsersEdit.addIds.indexOf(user.id);
      this.identityRoleUsersEdit.addIds.splice(index, 1);

      index = this.addUsers.indexOf(user.id);
      this.addUsers.splice(index, 1);
    }
  }

  onClinicDeleteIdsToggle(user: any, $event: any) {
    if ($event.target.checked) {
      this.identityRoleUsersEdit.deleteIds.push(user.id);
      this.deleteUsers.push(user);
    }

    else {
      var index = this.identityRoleUsersEdit.deleteIds.indexOf(user.id);
      this.identityRoleUsersEdit.deleteIds.splice(index, 1);

      index = this.deleteUsers.indexOf(user.id);
      this.deleteUsers.splice(index, 1);
    }
  }

  submit() {
    this.showSpinner = true;
    this.identityRoleUsersService.updateIdentityRoleUsers(this.identityRoleUsersEdit).subscribe(
      console.clear,
      error => console.log(error),
      () => this.showSpinner = false);
     window.location.reload();

  }
}
