import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/forkJoin';
import { ToastrService } from 'ngx-toastr';

import { FrontEndService } from '../services/fronEnd.service';
import { DoctorService } from '../services/doctor.service';
import { ClinicServiceService } from '../services/clinicService.service';
import { Doctor, SaveDoctor } from '../models/doctor';
import { SaveClinicService } from '../models/clinicService';



@Component({
	selector: 'doctor-form',
  templateUrl: './doctor-form.component.html',
  styleUrls: ['./doctor-form.component.css']
})


export class DoctorFormComponent implements OnInit {

  confDoctorPass: string;
  isEditForm: boolean = false; // to identify either adding new enmployee / editing employee
  isSalaryValid: boolean = false;
  isPasswordMatch: boolean = false;
  isPasswordValid: boolean = false; 

  weekDay: any = [
    {
      id: 0,
      name: 'Sunday',
    },
    {
      id: 1,
      name: 'Monday',
    },
    {
      id: 2,
      name: 'Tuesday',
    },
    {
      id: 3,
      name: 'Wednesday',
    },
    {
      id: 4,
      name: 'Thursday',
    },
    {
      id: 5,
      name: 'Friday',
    },
    {
      id: 6,
      name: 'Saturday',
    }
  ];

	clinicServices: SaveClinicService[];
	doctor: Doctor = {
      name: '',
      mobile: '',
    workingDays: [],
    specialization: '',
    serviceDoctorShares: [],
    userName: '',
    password: '',
    email: '',
    userId: '',
    title: ''
  };

  saveDoctor: SaveDoctor = {
    id: 0,
    name: '',
    mobile: '',
    workingDays: [],
    specialization: '',
    serviceDoctorShares: [],
    userName: '',
    password: '',
    email: '',
    userId: '',
    title: ''
  }
  sources: any[];

  formH1Text = "";
  showSpinner = true;

  constructor(
    private route: ActivatedRoute,
    private frontEndService: FrontEndService,
		private doctorService: DoctorService,
    private clinicServiceService: ClinicServiceService,
    private toaster: ToastrService,
    private router: Router) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
		this.route.params.subscribe(p => {
			this.saveDoctor.id = +p['id'] || 0;
		});
	}

	ngOnInit() {
      this.confDoctorPass = '';
      this.sources = [
        this.clinicServiceService.getClinicServices(),
      ];

      if (this.saveDoctor.id) {
        this.isEditForm = true;
        this.sources.push(this.doctorService.getDoctor(this.saveDoctor.id));
        this.formH1Text = "Edit Doctor Details";
      }
      else {
        this.formH1Text = "Add New Doctor";

      }
        

      Observable.forkJoin(this.sources).subscribe(data => {
        this.setClinciServices(data[0]);

        if (this.saveDoctor.id)
          this.setSaveDoctor(data[1]);
      },
        error => console.log(error),
        () => this.showSpinner = false);

	}

  private setClinciServices(clinicServices: any) {
    this.clinicServices = clinicServices;
  }

  private setSaveDoctor(d: any) {
    this.saveDoctor.id = d.id;
    this.saveDoctor.name = d.name;
    this.saveDoctor.mobile = d.mobile;
    this.saveDoctor.workingDays = d.workingDays;
    this.saveDoctor.specialization = d.specialization;
    this.saveDoctor.serviceDoctorShares = d.serviceDoctorShares;

    this.saveDoctor.email = d.email;
    this.saveDoctor.userName = d.userName;
    this.saveDoctor.password = d.password;
    this.saveDoctor.userId = d.userId;
    this.saveDoctor.title = d.title;
  }

  private setDoctor(d: any) {
    this.doctor.name = d.name;
    this.doctor.mobile = d.mobile;
    this.doctor.workingDays = d.workingDays;
    this.doctor.specialization = d.specialization;
    this.doctor.serviceDoctorShares = d.serviceDoctorShares;

    this.doctor.email = d.email;
    this.doctor.userName = d.userName;
    this.doctor.password = d.password;
    this.doctor.userId = d.userId;
    this.doctor.title = d.title;
  }

  submit() {
    this.showSpinner = true;
		// Check if its existing patient so we are updating it
      if (this.saveDoctor.id) {
          this.doctorService.updateDoctor(this.saveDoctor).subscribe(x => {
            this.toaster.success('Edit Doctor!', 'Updated successfully.');
            this.showSpinner = false;
            this.router.navigateByUrl('/serviceDoctorShare');
          }, error => {
            this.toaster.error('Edit Doctor!', error.error);
          });
		}
		// Check if its new patient so we are adding it
        else {
          this.setDoctor(this.saveDoctor);
          this.doctorService.createDoctor(this.doctor).subscribe(x => {
            this.toaster.success('Add Doctor!', 'Added successfully.');
            this.showSpinner = false;
            this.router.navigateByUrl('/serviceDoctorShare');
          }, error => {
            this.toaster.error('Add Doctor!', error.error);
          });
    }
    //this.router.navigateByUrl('/doctors');
	}

	onClinicServiceToggleDoctorServices(serviceId: any, $event : any) {
		if ($event.target.checked)
			this.saveDoctor.serviceDoctorShares.push(serviceId);
		else {
			var index = this.saveDoctor.serviceDoctorShares.indexOf(serviceId);
			this.saveDoctor.serviceDoctorShares.splice(index, 1);
		}

  }

  onClinicServiceToggleWrokingDays(weekDayId: any, $event: any) {
    if ($event.target.checked)
      this.saveDoctor.workingDays.push(weekDayId);
    else {
      var index = this.saveDoctor.workingDays.indexOf(weekDayId);
      this.saveDoctor.workingDays.splice(index, 1);
    }
  }

  confirmPassword(password1, password2) {
    this.isPasswordMatch = this.frontEndService.isMatch(password1, password2);
  }

  validatePassword(password) {
    this.isPasswordValid = this.frontEndService.validatePassword(password)
  }
}
