import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Payment, SavePayment } from '../models/payment';
import { PaymentQuery } from '../models/paymentQuery';

@Injectable()
export class PaymentService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getPayments(paymentQuery: PaymentQuery) {
    return this.http.get<SavePayment[]>(this.baseUrl + 'api/payments' + '/' + '?' + this.toQueryString(paymentQuery));
	}

 // getPayment(id: number) {
 //   return this.http.get<SavePayment>(this.baseUrl + 'api/payments' + '/' + id);
 // }

  getPayment(paymentQuery: PaymentQuery) {
    return this.http.get<SavePayment>(this.baseUrl + 'api/payments' + '/' + 0 + '/' + '?' + this.toQueryString(paymentQuery));
  }


  createPayment(payment: Payment) {
    return this.http.post<Payment>(this.baseUrl + 'api/payments', payment);
	}

  updatePayment(savePayment: SavePayment) {
    return this.http.put<SavePayment>(this.baseUrl + 'api/payments' + '/' + savePayment.id, savePayment);
  }

  toQueryString(obj: any) {
    var parts = [];
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined)
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
    }
    return parts.join('&');
  }

}
