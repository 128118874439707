import { Injectable, Inject } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    if (localStorage.getItem('token') != null) {
      let roles = next.data['permittedRoles'] as Array<string>;
      if (roles) {
        if (this.authService.roleMatch(roles))
          return true;
        else {
          // Navigate to Home page
          this.router.navigate(['']);
          return false;
        }
      }
      return true;
    }
     
    else {
      if (this.router.url != '')
      this.router.navigate(['/identity/login']);
      return false;
    }
      
  }

}
