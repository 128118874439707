// 1- Single Role 
export const  Admin = 'Admin';
export const CeoDoctor = 'CeoDoctor';
export const  CEO = 'Ceo';
export const  Doctor = 'Doctor';
export const  SuperEmployee = 'SuperEmployee';
export const  Employee = 'Employee';
export const  Patient = 'Patient';

// 2- Double Role
export const  AdminOrEmployee = ['Admin' , 'Employee'];
export const  AdminOrPatient = ['Admin' , 'Patient'];

// 3- Triple Role
export const AdminOrCeoDoctorOrCEO = ['Admin', 'CeoDoctor', 'Ceo'];
export const AdminOrCeoDoctorOrDoctor = ['Admin', 'CeoDoctor', 'Doctor'];

// 4- Quadruple Role
export const AdminOrCeoDoctorOrCEOOrSuperEmployee = ['Admin', 'CeoDoctor', 'Ceo', 'SuperEmployee'];
export const AdminOrCeoDoctorOrCEOOrDoctor = ['Admin', 'CeoDoctor', 'Ceo', 'Doctor'];
export const AdminOrCEODoctorOrEmployee = ['Admin', 'CeoDoctor', 'Ceo', 'Employee'];

// 5- Quintuple Role
export const AdminOrCeoDoctorOrCEOOrDoctorOrSuperEmployee = ['Admin', 'CeoDoctor', 'Ceo', 'Doctor', 'SuperEmployee'];

// All staff Role
export const AllStaff = ['Admin', 'CeoDoctor', 'Ceo', 'Doctor', 'SuperEmployee', 'Employee'];
