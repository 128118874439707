import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Bill, SaveBill } from '../models/bill';
import { Pagination } from '../models/pagination';

@Injectable()
export class BillService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getBills() {
    return this.http.get<SaveBill[]>(this.baseUrl + 'api/bills');
  }

  getSomeBills(pagination: Pagination) {
    return this.http.get<SaveBill[]>(this.baseUrl + 'api/bills' + '/' + '?' + this.toQueryString(pagination));
  }

  toQueryString(obj: any) {
    var parts = [];
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined)
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
    }
    return parts.join('&');
  }

  getBill(id: number) {
    return this.http.get<SaveBill>(this.baseUrl + 'api/bills' + '/' + id);
  }

  createBill(bill: Bill) {
    console.log(bill.patientId);
    return this.http.post<Bill>(this.baseUrl + 'api/bills', bill);
  }

  updateBill(saveBill: SaveBill) {
    return this.http.put<SaveBill>(this.baseUrl + 'api/bills/' + saveBill.id, saveBill);
  }
}
