import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/forkJoin';
import { ToastrService } from 'ngx-toastr';

import { ServiceDoctorShareService } from '../services/serviceDoctorShare.service';
import { BillClinicServiceService } from '../services/billClinicService.service';
import { ToothService } from '../services/tooth.service';
import { ClinicServiceService } from '../services/clinicService.service';

import { ServiceDoctorShare } from '../models/serviceDoctorShare';
import { Tooth } from '../models/tooth';
import { BillServiceWithDrProgress } from '../models/billServiceWithDrProgress';


@Component({
  selector: 'billClinicService-form',
  templateUrl: './billClinicService-form.component.html',
  styleUrls: ['./billClinicService-form.component.css']
})


export class BillClinicServiceFormComponent implements OnInit {

  @Input() billIdModal: any;
  @Input() doctorIdModal: any;
  @Output() onSave = new EventEmitter<string>();

  clinicServicePrice: number;
  clinicServiceDiscount: number = 0;
  isToothFreeService: boolean = false;
  swapButtonText: string = 'Add non-medical service';

  pickedTooth: string = '';
  toothId: number = 0;
  sources: any[];
  status: any = 0;
  doctorId: any = 0;
  isToothUpdated = false;
  isToothPicked = false;
  discountInvalid = false;
  billClinicServices: BillServiceWithDrProgress[]
  doctorclinicServices: ServiceDoctorShare[] = [] ;
  //availableClinicServices: ServiceDoctorShare[] = [];
  nonMeicalClinicServices: any[] = [];

  teeth: Tooth[];
  showSpinner = true;


  billServiceWithDrProgress: BillServiceWithDrProgress = {
    billClinicService:
    {
      billId: 0,
      clinicServiceId: 0,
      specialDiscount: 0,
      toothId: 0,
      serviceProgress: 0,
      servicePrice: 0,
      note: ''
    },
    doctorServiceProgress: []
  }
  

 
  
  constructor(
    private route: ActivatedRoute,
    private toothService : ToothService,
    private serviceDoctorShareService: ServiceDoctorShareService,
    private billClinicServiceService: BillClinicServiceService,
    private clinicServiceService: ClinicServiceService,
    private toaster: ToastrService) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
    route.params.subscribe(p => {
      this.billServiceWithDrProgress.billClinicService.billId = +p['billId'] || 0;
      this.doctorId = +p['doctorId'] || 0;
		});
	}

	ngOnInit() {
      if (this.billServiceWithDrProgress.billClinicService.billId == 0)
        this.billServiceWithDrProgress.billClinicService.billId = this.billIdModal;
      if (this.doctorId == 0)
        this.doctorId = this.doctorIdModal;

      this.sources = [
        this.serviceDoctorShareService.getSeriveceDoctorSharesByDoctorId(this.doctorId),
        this.toothService.getTeeth(),
        this.billClinicServiceService.getBillClinicServicesByBillId(this.billServiceWithDrProgress.billClinicService.billId)
      ];

    Observable.forkJoin(this.sources).subscribe(data => {
      this.setDoctorClinicServices(data[0]);
      this.setTeeth(data[1]);
      this.setBillClinicServices(data[2]);

    });

	}

  private setDoctorClinicServices(doctorClinicServices: any) {
    this.doctorclinicServices = doctorClinicServices;
    this.showSpinner = false;
  }

  private setTeeth(teeth: any) {
    this.teeth = teeth
  }

  private setBillClinicServices(billClinicServices: any) {
    this.billClinicServices = billClinicServices;
  }

  // this method to create a dynamic list that changes by changing the tooth selected
  /*
  private setAvailableClinicServices(availableClinicServices: any) {

    for (let i = 0; i < availableClinicServices.length; i++)
    {
      if (this.nonMeicalClinicServices != null) {
    
        for (let y = 0; y < this.nonMeicalClinicServices.length; y++) {
          if (this.nonMeicalClinicServices[y].id != availableClinicServices[i].clinicServiceId)
          this.availableClinicServices.push(availableClinicServices[i]);
        }
      }
      else
        this.availableClinicServices.push(availableClinicServices[i]);

    }

  } */


 // todo-- Documentation as spect onToothChange() method to update the available services could be done on the tooth
  // prevent selecting service twice on the same  tooth. 
  onToothChange(toothPicked: string) {

    this.pickedTooth = toothPicked;
    this.toothId = 0;
    this.billServiceWithDrProgress.billClinicService.clinicServiceId = 0;
    this.clinicServicePrice = 0;
    this.isToothUpdated = true;
    this.isToothPicked = true;

    //this.availableClinicServices = [];

    //this.setAvailableClinicServices(this.doctorclinicServices);

    this.toothService.getTooth(toothPicked).subscribe(x => {
      if (x == null) {
        this.toaster.error('Tooth Not found in Database');
        //this.availableClinicServices = [];
      }
      else {
        this.toothId = x.id;
        this.billServiceWithDrProgress.billClinicService.toothId = this.toothId;

        /*
        for (let i = 0; i < this.availableClinicServices.length; i++) {
          for (let x = 0; x < this.billClinicServices.length; x++) {
            if ((this.toothId == this.billClinicServices[x].toothId) && (this.availableClinicServices[i].clinicServiceId == this.billClinicServices[x].clinicServiceId))
              this.availableClinicServices.splice(i, 1);
          }
        }*/
      }
    });

  }

  onServiceChange(id) {
    this.isToothUpdated = false;
    // get the service price
    this.clinicServiceService.getClinicService(id).subscribe(x => {
      this.clinicServicePrice = x.price;
    })
  }

  onNonMedicalServiceChange(id) {
    // get the service price
    this.clinicServiceService.getClinicService(id).subscribe(x => {
      this.clinicServicePrice = x.price;
    })
  }

  swapService() {
    this.isToothFreeService = !this.isToothFreeService;
    this.resetView();
    if(this.isToothFreeService)
      this.swapButtonText = 'Add medical service';
    else
      this.swapButtonText = 'Add non-medical service';
  }

  resetView() {
    this.isToothPicked = false;
    this.clinicServicePrice = 0;
    this.billServiceWithDrProgress.billClinicService.toothId = 0;
    this.pickedTooth = '';
    //this.availableClinicServices = [];
  }

  submit() {
    this.showSpinner = true;
    this.billServiceWithDrProgress.billClinicService.specialDiscount = this.clinicServiceDiscount;
    //Todo -- the added service should be from unadded services. so the list of services shown to the user should be 
    // without the added services.
    this.billClinicServiceService.createBillClinicService(this.billServiceWithDrProgress.billClinicService).subscribe(x => {
      this.toaster.success('Add Service!', 'Add successfully.');
      // Disable save button and update the bill clinic service
      this.resetView();

      //I get the new bill clinic service to exclude the service for the addd service to this tooth
      this.billClinicServiceService.getBillClinicServicesByBillId(this.billServiceWithDrProgress.billClinicService.billId).subscribe(x => {
        this.setBillClinicServices(x);
      });
    }, error => {
      this.toaster.error('Add Service!', error.error);
      },
      () => {
        this.showSpinner = false;
        this.onSave.emit()
      });

  }

  validate() {
    var input, table, i;
    input = document.getElementById("clinicServiceDiscount");

    if (input.value > 100 || input.value < 0 || input.value == null || input.value == undefined || input.value.length == 0) {
      input.classList.add('is-invalid');
      this.discountInvalid = true;
    }
    else
    {
      input.classList.remove('is-invalid');
      this.discountInvalid = false;
    }
    
  }

}
