import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BillServiceWithDrProgress } from '../models/billServiceWithDrProgress';
import { BillClinicService } from '../models/billClinicService'; 

@Injectable()
export class BillClinicServiceService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getBillClinicServices() {
    return this.http.get<BillServiceWithDrProgress[]>(this.baseUrl + 'api/billClinicServices');
	}

  getBillClinicServicesByBillId(billId: number) {
    return this.http.get<BillServiceWithDrProgress>(this.baseUrl + 'api/billClinicServices' + '/' + billId);
	}

  createBillClinicService(billClinicService: BillClinicService) {
    return this.http.post<BillServiceWithDrProgress>(this.baseUrl + 'api/billClinicServices', billClinicService);
	}

}
