import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { ToastrService } from 'ngx-toastr';

import { LabPayment, SaveLabPayment } from '../models/labPayment';
import { LabPaymentService } from '../services/labPayment.service';



@Component({
  selector: 'labPayment-form',
  templateUrl: './labPayment-form.component.html',
  styleUrls: ['./labPayment-form.component.css']
})


export class LabPaymentFormComponent implements OnInit {

  @Input() labId: number;

  isEditForm: boolean = false; // to identify either adding new enmployee / editing employee
  isSalaryValid: boolean = false;
  isDayPicked: boolean = false; // is used in new Doctor salary.

  calenderDate: any;

  labPayment: LabPayment = {
    labId: 0,
    paymentDate: new Date(),
    paymentAmount: 0
  };
  saveLabPayment: SaveLabPayment = {
    id : 0,
    labId: 0,
    paymentDate: new Date(),
    paymentAmount: 0
  };

  formH1Text = "";
  showSpinner = true;

	constructor(
    private route: ActivatedRoute,
    private labPaymentService: LabPaymentService,
    private toaster: ToastrService,
    private router: Router) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
		this.route.params.subscribe(p => {
      this.saveLabPayment.id = +p['id'] || 0;
		});
	}

  ngOnInit() {

    if (this.saveLabPayment.id) {
      this.labPaymentService.getPayment(this.saveLabPayment.id).subscribe(result => {
        if (result != null) {
          this.saveLabPayment = result;
        }
        this.formH1Text = "Edit Payment Details";
      },
        error => console.log(error),
        () => this.showSpinner = false);;
    }
    else {
      this.formH1Text = "Add New Lab Payment";
      this.showSpinner = false;
    }
  }


  submit() {
    // Check if its existing patient so we are updating it
    if (this.saveLabPayment.id) {
      this.labPaymentService.updateLabPayment(this.saveLabPayment).subscribe(x => {
        this.toaster.success('Edit payment!', 'Update successfully.');
      }, error => {
        this.toaster.error('Edit payment!', error.error);
      });
    }
    // Check if its new patient so we are adding it
    else {

      this.setLabPayment(this.saveLabPayment);
      this.labPaymentService.createLabPayment(this.labPayment).subscribe(x => {
        this.toaster.success('Add payment!', 'Add successfully.');
        //this.router.navigateByUrl('/doctorSalaries');
      }, error => {
        this.toaster.error('Add salary!', error.error);
      });
    }
  }


  private setLabPayment(saveLabPayment: SaveLabPayment) {

    this.labPayment.labId = this.labId;
    this.labPayment.paymentDate = saveLabPayment.paymentDate;
    this.labPayment.paymentAmount = saveLabPayment.paymentAmount;

  }

  validatePayment() {
    var input;
    input = document.getElementById("paymentAmount");

    if (input.value <= 0 || input.value == null || input.value == undefined || input.value.length == 0)
      this.isSalaryValid = false;
    else
      this.isSalaryValid = true;
  }

  datePiced() {
    var input;
    input = document.getElementById("labPaymentDate");
    this.saveLabPayment.paymentDate = input.value;
    this.isDayPicked = true;
  }

  
}
