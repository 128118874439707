import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { Patient, SavePatient } from '../models/patient';
import { Pagination } from '../models/pagination';

import { PatientService} from '../services/patient.service';

@Component({
	selector: 'patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})

export class PatientListComponent implements OnInit {

  @Input() amIChildOfMainView: any;
  @Output() addNewPatient = new EventEmitter<boolean>();
  @Output() addNewVisit = new EventEmitter<number>();
  @Output() viewPatientProfile = new EventEmitter<number>();

  patients: SavePatient[] = [];
  searchedPatients: SavePatient[] = [];
  pagination: Pagination = { page: 1, limit: 15, mobile: '', name: '' };
  searchPagination: Pagination = { page: 0, limit: 0, mobile: '', name: '' };
  total = 0;
  page = 1;
  limit = 15;
  loading = false;
  searchMode: boolean = false;
  showSpinner = true;
  
  constructor(private patientService: PatientService) {
    
   }

  
  ngOnInit() {
    
    this.getPatients();
     
       }

  getPatients(): void {

    this.searchedPatients = [];
    this.patientService.getSomePatients(this.pagination)
      .subscribe(res => {
        this.patients = res['page']['data'];
        this.total = res['page'].total;
        //this.loading = false;
      },
        error => console.log(error),
        () => this.showSpinner = false);
  }

  goToPrevious(): void {
    this.page--;
    this.pagination.page--;
    this.getPatients();
  }

  goToNext(): void {
    this.page++;
    this.pagination.page++;
    this.getPatients();
  }

  goToPage(n: number): void {
    this.page = n;
    this.pagination.page = n;
    this.getPatients();
  }

  searchByMobile() {
    if (this.searchPagination.mobile != '' && this.searchPagination.mobile.length >= 5 ) {
      this.searchMode = true;
      this.patientService.getSomePatients(this.searchPagination)
        .subscribe(res => {
          this.patients = res['page']['data'];
          this.searchedPatients = res['page']['data'];
          this.total = res['page'].total;
          //this.loading = false;
        });
    }
    else {
      this.getPatients();
    }
  }

  searchByName() {
    
    if (this.searchPagination.name != '' && this.searchPagination.name.length >= 3) {
      this.searchMode = true;
      this.patientService.getSomePatients(this.searchPagination)
        .subscribe(res => {
          this.patients = res['page']['data'];
          this.searchedPatients = res['page']['data'];
          this.total = res['page'].total;
          //this.loading = false;
        });
    }
    else {
      this.getPatients();
    }
  }

  addNewPatientInMainView() {
    this.addNewPatient.emit(true);
  }

  addVisitInMainView(patientId: number) {
    this.addNewVisit.emit(patientId);
  }

  viewPatientProInMainView(patientId: number) {
    this.viewPatientProfile.emit(patientId);
  }


}


