import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Doctor , SaveDoctor } from '../models/doctor';

@Injectable()
export class DoctorService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getDoctors() {
    console.log("@ Doctors Service");
    return this.http.get<SaveDoctor[]>(this.baseUrl + 'api/doctors');
	}

	getDoctor(id: number) {
      return this.http.get<SaveDoctor>(this.baseUrl + 'api/doctors' + '/' + id);
	}

  createDoctor(doctor: Doctor) {
   
      return this.http.post<Doctor>(this.baseUrl + 'api/doctors', doctor);
	}

	updateDoctor(saveDoctor: SaveDoctor) {
      return this.http.put<SaveDoctor>(this.baseUrl + 'api/doctors' + '/' + saveDoctor.id, saveDoctor);
	}
}
