import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { IdentityUser } from '../models/identityUser';
import { VisitService } from '../services/visit.service';
import { AuthService } from '../services/auth.service';


@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  isClinicOptions = false;
  isTeamOptions = false;
  isManageOptions = false;
  isStatisitcsOptions = false;
  userRoleValue : number = 100;


  public isAuthenticated: boolean;

  identityUser: IdentityUser =  {
    userName: '',
    title : '',
    email : ''
  };

  constructor(private router: Router,
              private visitService : VisitService,
              private authService: AuthService ) { }

  ngOnInit() {
    this.authService.getUserProfile().subscribe(x => {
      this.setuserDetails(x);
    });
    this.isAuthenticated = this.authService.isAuthenticated();
    this.userRoleValue = this.authService.getRoleValue();
  }

  private setuserDetails(data: any) {
    this.identityUser.userName = data.userName;
    this.identityUser.title = data.title;
    this.identityUser.email = data.email;
  }

  goToTodayVisits() {
    this.visitService.goToTodayVisits();
  }

  onLogout() {
    localStorage.removeItem('token');
    this.router.navigate(['']);
  }

  collapse() {
    this.isExpanded = false;
    this.isClinicOptions = false;
    this.isTeamOptions = false;
    this.isManageOptions = false;
    this.isStatisitcsOptions = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
    console.log(this.isExpanded);
  }

  toggleClinicOptions() {
    this.isClinicOptions = !this.isClinicOptions;
  }

  toggleTeamOptions() {
    this.isTeamOptions = !this.isTeamOptions;
  }

  toggleManageOptions() {
    this.isManageOptions = !this.isManageOptions;
  }

  toggleStatisitcsOptions() {
    this.isStatisitcsOptions = !this.isStatisitcsOptions;
  }
}
