import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { PasswordReset } from '../models/passwordReset'
import { IdentityUser } from '../models/identityUser';


@Injectable()
export class AuthService {

  currentUser: IdentityUser = {
    userName: '',
    title: '',
    email: ''
  };

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  login(user: User) {
    this.currentUser.userName = user.userName;
    return  this.http.post(this.baseUrl + 'api/identity/login', user, );
  }

  getUserProfile() {
    this.http.get(this.baseUrl + 'api/identity/userProfile').subscribe(x => {
      this.setuserDetails(x)
    });
    return this.http.get(this.baseUrl + 'api/identity/userProfile');
  }

  private setuserDetails(data: any) {
    this.currentUser.userName = data.userName;
    this.currentUser.title = data.title;
    this.currentUser.email = data.email;
  }

  ResetUserPasswordByUserId(passwordReset: PasswordReset) {
    return this.http.put(this.baseUrl + 'api/applicationUser/resetUserPassword', passwordReset);
  }

  ResetUserPasswordByUserProfile(passwordReset: PasswordReset) {
    return this.http.put(this.baseUrl + 'api/identity/resetUserPassword', passwordReset);
  }

  roleMatch(allowRoles): boolean {
    var isMatch = false;
    //check user is authenticated 
    if (!this.isAuthenticated())
      return false;
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payLoad.role;
    allowRoles.forEach(element => {
      if (userRole == element) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }

  getRole(): string {
    if (!this.isAuthenticated())
      return '';
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payLoad.role;
    return userRole;
  }

  getRoleValue(): number {
    if (!this.isAuthenticated())
      return 100;
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payLoad.role;

    if (userRole == 'Admin')
      return 0;
    if (userRole == 'CeoDoctor')
      return 1
    if (userRole == 'Ceo') 
      return 2;      
    if (userRole == 'Doctor')
      return 3;
    if (userRole == 'SuperEmployee')
      return 4;
    if (userRole == 'Employee')
      return 5;
    if (userRole == 'Patient')
      return 6;
  }

  public isAuthenticated(): boolean {

    //Check that the user is not logged out
    if (localStorage.getItem('token') == null)
      return false;

    // Check whether the current time is past the
    // Access Token's expiry time
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    const expiresAt = payLoad.exp;
    var current_time = new Date().getTime() / 1000;
    return current_time < expiresAt;
  }
}
