import { Injectable, Inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators'
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (localStorage.getItem('token') != null) {
      const clonedReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token'))
      });
      return next.handle(clonedReq).pipe(
        tap(
          succ => { },
          err => {
            //check that I am not on the home page to prevent geting the user from home page directly to login page. 
            if (err.status == 401 && this.router.url != '') {
              localStorage.removeItem('token');
              this.router.navigateByUrl('identity/login');
            }
            else if (err.status == 403)
              this.router.navigateByUrl('');
          }
        )
      )
    }
    else   return next.handle(req.clone());
    
  }
}
