import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { DoctorBillExpense } from '../models/DoctorBillExpense';
import { BillDoctorPaymentsQuery } from '../models/BillDoctorPaymentsQuery';
import { DoctorBillExpenseList } from '../models/DoctorBillExpenseList';

@Injectable()
export class BillDoctorEligiblePaymentsService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getAllBillDoctorEligiblePaymentsByDoctorId(query: BillDoctorPaymentsQuery) {
    return this.http.get<DoctorBillExpenseList>(this.baseUrl + 'api/billDoctorPayments' + '/' + '?' + this.toQueryString(query));
  }

  getBillDoctorEligiblePaymentsByDoctorId(doctorId: number) {
    return this.http.get<DoctorBillExpense[]>(this.baseUrl + 'api/billDoctorPayments' + '/' + doctorId);
  }


  toQueryString(obj: any) {
    var parts = [];
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined)
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
    }
    return parts.join('&');
  }


}
