import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/forkJoin';
import { ToastrService } from 'ngx-toastr';

import { VisitMedicineService } from '../services/visitMedicine.service';


import { SaveMedicine } from '../models/medicine';
import { VisitMedicine } from '../models/visitMedicine';
import { MedicineService } from '../services/medicine.service';

@Component({
  selector: 'visitMedicine-form',
  templateUrl: './visitMedicine-form.component.html',
  styleUrls: ['./visitMedicine-form.component.css']
})


export class VisitMedicineFormComponent implements OnInit {

  @Input() visitIdModal: any;
  @Input() patientName: string;

  date = new Date();

  sources: any[];
  visitMedicines: VisitMedicine[] = [];
  medicines: SaveMedicine[] = []; 
  

  visitMedicine: VisitMedicine = {
    visitId: 0, 
    medicineId: 0, 
    dose: '', 
    note: ''
  };


  constructor(
    private route: ActivatedRoute,
    private medicineService : MedicineService,
    private visitMedicineService: VisitMedicineService,
    private toaster: ToastrService) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
		this.route.params.subscribe(p => {
          this.visitMedicine.visitId = +p['visitId'] || 0;
		});
	}

  ngOnInit() {

    this.visitMedicine.visitId = this.visitIdModal;
    
    this.sources = [
      this.medicineService.getMedicines(),
      this.visitMedicineService.getVisitMedicinesByVisitId(this.visitMedicine.visitId)
    ];

      Observable.forkJoin(this.sources).subscribe(data => {
        this.setMedicines(data[0]);
        this.setVisitMedicines(data[1]);
      });
  }

  private setMedicines(medicines: any) {
    this.medicines = medicines;
  }

  private setVisitMedicines(visitMedicines: any) {
    this.visitMedicines = visitMedicines;
  }



  submit() {
    this.visitMedicineService.addVisitMedicine(this.visitMedicine).subscribe(x => {
      this.toaster.success('Add Medicine to visit!', 'Added successfully.');
      this.visitMedicineService.getVisitMedicinesByVisitId(this.visitMedicine.visitId).subscribe(x => {
        this.setVisitMedicines(x);
      });

    }, error => {
        this.toaster.error('Add Medicine to visit!', error.error);
    });
  }

  print() {
    window.print();
}

}
