import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../services/auth.service';

import { PaymentService } from '../services/payment.service';
import { Payment, SavePayment } from '../models/payment';
import { PaymentQuery } from '../models/paymentQuery';

@Component({
	selector: 'payment-form',
	templateUrl: './payment-form.component.html'
})


export class PaymentFormComponent implements OnInit {
   
  @Input() billIdModal: any;
  @Input() visitIdModal: any;

  @Input() amIChildOfMyVisitList: boolean = false;

  isPaymentFound: boolean = false;
  justAdded:boolean = false;
  userRoleValue: number = 100;

  paymentQuery: PaymentQuery = {
    paymentId: 0,
    visitId: 0,
    billId: 0
  }

  payment: Payment = {
    amount : 0,
    billId: 0,
    visitId: 0,
    visa: false
  };

  savePayment: SavePayment = {
    id:0,
    amount: 0,
    billId: 0,
    visitId: 0,
    visa: false
  };

  formH1Text = "";
  showSpinner = true;
   

	constructor(
		private route: ActivatedRoute,
    private paymentService: PaymentService,
    private toaster: ToastrService,
    private router: Router,
    private authService: AuthService) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
		this.route.params.subscribe(p => {
			this.savePayment.id = +p['id'] || 0;
		});
	}

  ngOnInit() {

    if (!this.amIChildOfMyVisitList)
      this.initialize();

  }


  //is used to pass async data to child component
  // as ngOnChanges detect change in "billIdModal" and apply the action after detection 
  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    if (changes['billIdModal']) {
      //this.savePayment.amount = 0;
      //this.isPaymentFound = false;
      this.savePayment.billId = this.billIdModal;
      this.initialize();
      
    }
    if (changes['visitIdModal']) {
      this.savePayment.amount = 0;
      this.isPaymentFound = false;

      this.savePayment.visitId = this.visitIdModal;
      this.paymentQuery.visitId = this.visitIdModal;
      this.paymentService.getPayment(this.paymentQuery).subscribe(result => {
        if (result != null) {
          this.savePayment = result;
          this.isPaymentFound = true;
        }
        else {
          this.savePayment.billId = this.billIdModal;
        }
      });;

      this.initialize();
    }
  }

  initialize() {
    this.userRoleValue = this.authService.getRoleValue();

    if (this.billIdModal) {
      this.savePayment.id = 0;
      this.savePayment.billId = this.billIdModal;
      this.savePayment.visitId = this.visitIdModal;
    }

    if (this.savePayment.id) {
      this.paymentQuery.paymentId = this.savePayment.id;
      this.paymentService.getPayment(this.paymentQuery).subscribe(result => {
        if (result != null) {
          this.savePayment = result;
          this.isPaymentFound = true;
          this.showSpinner = false;
        }
        this.formH1Text = "Edit Payment Details";
      },
        error => console.log(error),
        () => this.showSpinner = false);
    }
    else {
      this.formH1Text = "Add New Payment";
      this.showSpinner = false;
    }
  }
	
  submit() {
    this.showSpinner = true;
		// Check if its existing payment so we are updating it
      if (this.savePayment.id) {
        this.paymentService.updatePayment(this.savePayment).subscribe(x => {
          this.toaster.success('Update Payment!', 'Updated successfully.');
        }, error => {
            this.toaster.error('Edit Payment!', error.error);
          },
          () => this.showSpinner = false);
		}
		// Check if its new payment so we are adding it
      else {
        this.setPayment(this.savePayment);
        this.paymentService.createPayment(this.payment).subscribe(x => {
          this.toaster.success('Add Payment!', 'Added successfully.');
          this.isPaymentFound = true;
          this.justAdded = true;
        }, error => {
          this.toaster.error('Add Payment!', error.error);
        },
        ()=> this.showSpinner = false);
		}
  }

  private setPayment(savePayment : SavePayment) {
    this.payment.amount = savePayment.amount;
    this.payment.billId = savePayment.billId;
    this.payment.visitId = savePayment.visitId;
    this.payment.visa = savePayment.visa;
  }
}
