import { Component, OnInit, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { User } from '../models/user';

import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';


@Component({
	selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  user: User = {
    userName: '',
    password: ''
  }
  showSpinner = false;

  constructor(private authService: AuthService,
              private router: Router,
              private toaster: ToastrService, ) { }


  ngOnInit() {
    if (localStorage.getItem('token') != null)
      this.router.navigate(['']);
  }

  submit() {
    this.authService.login(this.user).subscribe(
      (result: any) => {
        this.showSpinner = true;
        localStorage.setItem('token', result.token);

      },
      err => {
        this.toaster.error('Login Error!', err.error)
      },
      () => {
        this.router.navigateByUrl('/mainView');
        this.showSpinner = false
      });
  }

  

}


