import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { MedicalProfileService } from '../services/medicalProfile.service';
import { MedicalProfile, SaveMedicalProfile } from '../models/medicalProfile';

@Component({
  selector: 'medicalProfile-form',
  templateUrl: './medicalProfile-form.component.html',
  styleUrls: ['./medicalProfile-form.component.css']
})


export class MedicalProfileFormComponent implements OnInit {

  @Input() patientIdModal: any;
  @Input() medicalProfileIdModal: any;

  medicalProfile: MedicalProfile = {
    patientId: 0,
    hepatitisC: false,
    hepatitisB: false,
    diabetes: false,
    hypertension: false,
    reductionOfBloodPressure: false,
    anginaPectoris: false,
    shortnessOfBreath: false,
    anemia: false,
    anticoagulants: false,
    hemophilia: false,
    excessiveBleeding: false,
    thyroid: false,
    kidneyDiseases: false,
    dialysis: false,
    epilepsyOrSeizures: false,
    dizzinessOrFainting: false,
    psychiatricNeurologicalDiseases: false,
    brainNervesDiseases: false,
    sinusitis: false,
    mowTeeth: false,
    symptomsOfJawJoint: false,
    pregnancy: false,
    breastFeeding: false,
    contraceptiveDrugs: false,
    pregnancyDate: new Date(),
    pregnancyMonth: 0,
    moreInfo: ''
  };
  saveMedicalProfile: SaveMedicalProfile = {
    id: 0,
    patientId: 0,
    hepatitisC: false,
    hepatitisB: false,
    diabetes: false,
    hypertension: false,
    reductionOfBloodPressure: false,
    anginaPectoris: false,
    shortnessOfBreath: false,
    anemia: false,
    anticoagulants: false,
    hemophilia: false,
    excessiveBleeding: false,
    thyroid: false,
    kidneyDiseases: false,
    dialysis: false,
    epilepsyOrSeizures: false,
    dizzinessOrFainting: false,
    psychiatricNeurologicalDiseases: false,
    brainNervesDiseases: false,
    sinusitis: false,
    mowTeeth: false,
    symptomsOfJawJoint: false,
    pregnancy: false,
    breastFeeding: false,
    contraceptiveDrugs: false,
    pregnancyDate: new Date(),
    pregnancyMonth: 0,
    moreInfo: ''
  };

	constructor(
		private route: ActivatedRoute,
      private medicalProfileService: MedicalProfileService,
      private toaster: ToastrService) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
		this.route.params.subscribe(p => {
          this.saveMedicalProfile.patientId = +p['patientId'] || 0;
          this.saveMedicalProfile.id = +p['id'] || 0;
		});
	}

  ngOnInit() {

    if (this.patientIdModal)
      this.saveMedicalProfile.patientId = this.patientIdModal;
    if (this.medicalProfileIdModal || this.medicalProfileIdModal == 0) {
      this.saveMedicalProfile.id = this.medicalProfileIdModal;
    }
    

    if (this.saveMedicalProfile.id && this.medicalProfileIdModal != 0 ) {
        this.medicalProfileService.getMedicalProfileByPatietnId(this.saveMedicalProfile.patientId).subscribe(result => {
          this.saveMedicalProfile = result;
        });;
			};
	}
	
	submit() {
		// Check if its existing patient so we are updating it
      if (this.saveMedicalProfile.id && this.medicalProfileIdModal != 0) {
        this.medicalProfileService.updateMedicalProfile(this.saveMedicalProfile).subscribe(x => {
          this.toaster.success('Medical Profile Updated!', 'Update successfully.');
        });
		}
		// Check if its new patient so we are adding it
      else {
        this.setMedicalProfile(this.saveMedicalProfile);
        this.medicalProfileService.createMedicalProfile(this.medicalProfile).subscribe(x => {
          this.toaster.success('Medical Profile Added!', 'Add successfully.');
        });
		}
  }

  private setMedicalProfile(saveMedicalProfile: SaveMedicalProfile) {
    
    this.medicalProfile.patientId = saveMedicalProfile.patientId;
    this.medicalProfile.hepatitisC = saveMedicalProfile.hepatitisC;
    this.medicalProfile.hepatitisB = saveMedicalProfile.hepatitisB;
    this.medicalProfile.diabetes = saveMedicalProfile.diabetes;
    this.medicalProfile.hypertension = saveMedicalProfile.hypertension;
    this.medicalProfile.reductionOfBloodPressure = saveMedicalProfile.reductionOfBloodPressure;
    this.medicalProfile.anginaPectoris = saveMedicalProfile.anginaPectoris;
    this.medicalProfile.shortnessOfBreath = saveMedicalProfile.shortnessOfBreath;
    this.medicalProfile.anemia = saveMedicalProfile.anemia;
    this.medicalProfile.anticoagulants = saveMedicalProfile.anticoagulants;
    this.medicalProfile.hemophilia = saveMedicalProfile.hemophilia;
    this.medicalProfile.excessiveBleeding = saveMedicalProfile.excessiveBleeding;
    this.medicalProfile.thyroid = saveMedicalProfile.thyroid;
    this.medicalProfile.kidneyDiseases = saveMedicalProfile.kidneyDiseases;
    this.medicalProfile.dialysis = saveMedicalProfile.dialysis;
    this.medicalProfile.epilepsyOrSeizures = saveMedicalProfile.epilepsyOrSeizures;
    this.medicalProfile.dizzinessOrFainting = saveMedicalProfile.dizzinessOrFainting;
    this.medicalProfile.psychiatricNeurologicalDiseases = saveMedicalProfile.psychiatricNeurologicalDiseases;
    this.medicalProfile.brainNervesDiseases = saveMedicalProfile.brainNervesDiseases;
    this.medicalProfile.sinusitis = saveMedicalProfile.sinusitis;
    this.medicalProfile.mowTeeth = saveMedicalProfile.mowTeeth;
    this.medicalProfile.symptomsOfJawJoint = saveMedicalProfile.symptomsOfJawJoint;
    this.medicalProfile.pregnancy = saveMedicalProfile.pregnancy;
    this.medicalProfile.breastFeeding = saveMedicalProfile.breastFeeding;
    this.medicalProfile.contraceptiveDrugs = saveMedicalProfile.contraceptiveDrugs;
    //this.medicalProfile.pregnancyDate = saveMedicalProfile.pregnancyDate;
    this.medicalProfile.pregnancyDate = new Date();
    this.medicalProfile.pregnancyMonth = saveMedicalProfile.pregnancyMonth;
    this.medicalProfile.moreInfo = saveMedicalProfile.moreInfo;

  }
}
