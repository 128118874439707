import { Component, OnInit, Inject } from '@angular/core';
import { splitAtColon } from '@angular/compiler/src/util';

import { SaveVisit } from '../models/visit';
import { VisitService } from '../services/visit.service';
import { AuthService } from '../services/auth.service';





@Component({
	selector: 'visit-list',
  templateUrl: './visit-list.component.html',
  styleUrls: ['./visit-list.component.css']
})

export class VisitListComponent implements OnInit {

  public visits: SaveVisit[];
  showSpinner = true;

  constructor(private visitService: VisitService,
              private authService: AuthService) { }


	ngOnInit() {
      this.visitService.getVisits().subscribe(result => {
        this.visits = result;
      },
        error => console.log(error),
        () => this.showSpinner = false);
  }

  isOnRole(roleList) {
    return this.authService.roleMatch(roleList);
  }


  filter() {
  var input, filter, table, tr, td, i, txtValue;
  input = document.getElementById("myInput");
  filter = input.value.toUpperCase();
  table = document.getElementById("myTable");
  tr = table.getElementsByTagName("tr");
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[1];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
}
  
}
