import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SaveDoctorSalary } from '../models/doctorSalary';
import { LabPayment, SaveLabPayment  } from '../models/labPayment';

@Injectable()
export class LabPaymentService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getPaymentsByLab(id: number) {
    return this.http.get<LabPayment[]>(this.baseUrl + 'api/labPayments' +'/' + 'lab' + '/' + id );
  }

  getPayment(id: number) {
    return this.http.get<SaveLabPayment>(this.baseUrl + 'api/labPayments' + '/' + 'payment' + '/' + id);
  }

  createLabPayment(labPayment: LabPayment) {
    return this.http.post<LabPayment>(this.baseUrl + 'api/labPayments', labPayment);
  }

  updateLabPayment(saveLabPayment: SaveLabPayment) {
    return this.http.put<SaveLabPayment>(this.baseUrl + 'api/labPayments' + '/' + saveLabPayment.id, saveLabPayment);
  }

}

