import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Booking , SaveBooking } from '../models/booking';

@Injectable()
export class BookingService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getBookings() {
    return this.http.get<SaveBooking[]>(this.baseUrl + 'api/bookings');
	}

	getBooking(id: number) {
    return this.http.get<SaveBooking>(this.baseUrl + 'api/bookings' + '/' + id);
	}

  createBooking(booking: Booking) {
    return this.http.post<Booking>(this.baseUrl + 'api/bookings', booking);
	}

  updateBookingStatus(saveBooking: SaveBooking) {
    return this.http.put<SaveBooking>(this.baseUrl + 'api/bookings' + '/' + saveBooking.id, saveBooking);
	}
}
