import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';

import { ExpenseService } from '../services/expense.service';
import { Expense, SaveExpense } from '../models/expense';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';


interface ExpenseItem {
  value: string;
}

@Component({
	selector: 'expense-form',
	templateUrl: './expense-form.component.html'
})


export class ExpenseFormComponent implements OnInit {
  expense: Expense = {
    description: '',
    amount: 0,
    date: null,
    comment: ''
  }

  saveExpense: SaveExpense ={
    id: 0,
    description: '',
    amount: 0,
    date: null,
    comment: ''
  }

  isNew: boolean;
  expId: number;
  status: boolean;

  ExpenseOptions: string[] = [
    "Tools", "Monthly Rent Fees",
    "Electricty Bill", "Water Bill",
    "Gas Bill", "Phone Bill", "Salaries",
    "Maintanence", "Marketing", "Others"
    ]

	constructor(
      private route: ActivatedRoute,
      private rte: Router,
      private expenseService: ExpenseService,
      private pipeDate: DatePipe,
      private toaster: ToastrService,
      private router: Router
        ) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
		this.route.params.subscribe(p => {
			this.saveExpense.id = +p['id'] || 0;
		});
	}

  ngOnInit() {

    // Determine whether the expense form is for a new one or to edit an existing expense
    if (this.rte.url.search('new') == -1) { this.isNew = false;}
    else { this.isNew = true; }

    this.expId = +this.rte.url.slice(10); // Get the expense Id being updated

    if (this.saveExpense.id) {
      this.expenseService.getExpense(this.saveExpense.id).subscribe(result => {
        this.saveExpense = result;
      })
      
    };

    this.status = false;
	}
	
	submit() {
		// Check if its existing expense so we are updating it
      if (this.saveExpense.id) {
        this.expenseService.updateExpense(this.saveExpense).subscribe(console.clear);
		}
		// Check if its new expense so we are adding it
      else {
        this.setExpense(this.saveExpense);
        this.expenseService.createExpense(this.expense).subscribe(x => {
          this.toaster.success('Expense', 'Added successfully.');
          this.status = true;
          this.router.navigateByUrl('/expenses');
        });
        //this.rte.navigate(['/expenses']);
      }
  }

  private setExpense(saveExpense : SaveExpense) {
    this.expense.amount = saveExpense.amount;
    this.expense.date = saveExpense.date;
    this.expense.comment = saveExpense.comment;
    this.expense.description = saveExpense.description;
  }

  deleteExpense(): void {
    console.log(this.expId);
    this.expenseService.deleteExpense(this.expId).subscribe(
      () => console.log(this.expId)
    );
  }

}
