import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { FrontEndService } from '../services/fronEnd.service';
import { AuthService } from '../services/auth.service';

import { PasswordReset } from '../models/passwordReset'

@Component({
  selector: 'passwordReset-form',
  templateUrl: './passwordReset-form.component.html',
  styleUrls: ['./passwordReset-form.component.css']
})


export class PasswordResetFormComponent implements OnInit {

  @Input() userIdModal: any;

  confEmployeePass: string;
  isPasswordMatch: boolean = false;
  isPasswordValid: boolean = false; 

  passwordReset: PasswordReset = {
    userId : '',
    password: '',
    confPassword: ''
  }


	constructor(
    private route: ActivatedRoute,
    private authService : AuthService,
    private frontEndService : FrontEndService,
    private toaster: ToastrService) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.

		//this.route.params.subscribe(p => {
		//	this.saveEmployee.id = +p['id'] || 0;
		//});
	}

  ngOnInit() {
   
  }
	
  confirmPassword(password1, password2) {
    this.isPasswordMatch = this.frontEndService.isMatch(password1, password2);
  }

  validatePassword(password) {
    this.isPasswordValid = this.frontEndService.validatePassword(password)
  }

  resetPassword() {
    if (this.userIdModal == null)
    {
      this.passwordReset.userId = '0';
      this.authService.ResetUserPasswordByUserProfile(this.passwordReset).subscribe(x => {
        this.toaster.success('Password Reset!', 'Password Changed successfully');
      }, error => {
        this.toaster.error('Password Reset!', error.error);
      });
    }
    else
    {
      this.passwordReset.userId = this.userIdModal;
      this.authService.ResetUserPasswordByUserId(this.passwordReset).subscribe(x => {
        this.toaster.success('Password Reset!', 'Password Changed successfully');
      }, error => {
        this.toaster.error('Password Reset!', error.error);
      });
    }
  }
}
