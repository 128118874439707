import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IdentityRole, SaveIdentityRole } from '../models/identityRole';

@Injectable()
export class IdentityRoleService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getIdentityRoles() {
    return this.http.get<SaveIdentityRole[]>(this.baseUrl + 'api/identity/roles');
	}

  getIdentityRole(id: string) {
    return this.http.get<SaveIdentityRole>(this.baseUrl + 'api/identity/roles' + '/' + id);
	}

  createIdentityRole(identityRole: IdentityRole) {
    return this.http.post<IdentityRole>(this.baseUrl + 'api/identity/roles', identityRole);
	}

  updateIdentityRole(saveIdentityRole: SaveIdentityRole) {
      return this.http.put<IdentityRole>(this.baseUrl + 'api/ceos' + '/' + saveIdentityRole.id, saveIdentityRole);
	}
}
