import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/forkJoin';
import { ToastrService } from 'ngx-toastr';



import { BillClinicServiceService } from '../services/billClinicService.service';
import { VisitClinicServiceService } from '../services/visitClinicService.service';

import { VisitClinicService } from '../models/visitClinicService';
import { BillServiceWithDrProgress } from '../models/billServiceWithDrProgress';
import { ClinicService } from '../models/clinicService';
import { ClinicServiceService } from '../services/clinicService.service';

@Component({
	selector: 'visitClinicService-form',
  templateUrl: './visitClinicService-form.component.html',
  styleUrls: ['./visitClinicService-form.component.css']
})


export class VisitClinicServiceFormComponent implements OnInit {

  @Input() billIdModal: any;
  @Input() visitIdModal: any;
  @Input() refresh: any;

  isSubmitInProgress : boolean = false;

  sources: any[];
  status: any = 0;
  billClinicServices: BillServiceWithDrProgress[] = [];
  visitClinicServices: VisitClinicService[] = [];
  notYetAddedServices: VisitClinicService[] = [];
  meicalClinicServices: any[] = [];

  visitClinicService: VisitClinicService = {
    billClinicServiceId:0,
    visitId: 0, //filled through route
    billId: 0, //filled through route
    clinicServiceId: 0, // from table
    toothId: 0, // from table 
    specialDiscount: 0, // from table 
    serviceProgress: 0, // from table 
    visitProgress: 0, //  from input table 
    doctorFees: 0 // in server
  };

  showSpinner = true;

  constructor(
		private route: ActivatedRoute,
    private visitClinicServiceService: VisitClinicServiceService,
    private billClinicServiceService: BillClinicServiceService,
    private clinicServiceService: ClinicServiceService,
    private toaster: ToastrService) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
		this.route.params.subscribe(p => {
          this.visitClinicService.visitId = +p['visitId'] || 0;
          this.visitClinicService.billId = +p['billId'] || 0;
		});
	}

  ngOnInit() {

    if (this.visitClinicService.visitId == 0)
      this.visitClinicService.visitId = this.visitIdModal;
    if (this.visitClinicService.billId  == 0)
      this.visitClinicService.billId = this.billIdModal;

    //initialization
    this.initialize();

      
  }

  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    if (changes['refresh']) {
      console.log("I am here for refresh");
      
      //this.initialize();
    }
  }

  private initialize() {
    this.billClinicServices = [];
    this.visitClinicServices = [];
    this.notYetAddedServices = [];

    this.sources = [
      this.billClinicServiceService.getBillClinicServicesByBillId(this.visitClinicService.billId),
      this.clinicServiceService.getClinicServices(),
      this.visitClinicServiceService.getVisitClinicServicesByVisitId(this.visitClinicService.visitId),
    ];

    Observable.forkJoin(this.sources).subscribe(data => {
      this.setBillClinicServices(data[0]);
      this.setMedicalClinicServices(data[1]);
      this.setVisitClinicServices(data[2]);
    },
      error => console.log(error),
      () => this.showSpinner = false
    );
  }

  private setBillClinicServices(billClinicServices: any) {
    this.billClinicServices = billClinicServices;
  }

  private setMedicalClinicServices(clinicServices: any) {

    for (let i = 0; i < clinicServices.length; i++) {
      if (clinicServices[i].isMedical)
        this.meicalClinicServices.push(clinicServices[i]);
    }
  }

  private setVisitClinicServices(visitClinicServices: any) {
    this.visitClinicServices = visitClinicServices;

    console.log("visit clinic services length" + this.visitClinicServices.length);
    this.setNotYetAddedServices();
  }

  private fillNotYetAddedList(billClinicService: any) {
    var notaddedService: any = {
      visitId: 0,
      billClinicServiceId: billClinicService.billClinicService.id ,
      billId: billClinicService.billClinicService.billId,
      clinicServiceId: billClinicService.billClinicService.clinicServiceId,
      toothId: billClinicService.billClinicService.toothId,
      specialDiscount: 0,
      serviceProgress: billClinicService.billClinicService.serviceProgress,
      visitProgress: 0,
      doctorFees: 0,
      tooth: billClinicService.billClinicService.tooth,
      clinicService: billClinicService.billClinicService.clinicService
    }

    return notaddedService;
    
  }

 

  private setNotYetAddedServices() {

    this.notYetAddedServices.length = 0;
    let addedBefore: Boolean = false;
    if (this.visitClinicServices.length == 0) {
      for (let i = 0; i < this.billClinicServices.length; i++) {
        for (let y = 0; y < this.meicalClinicServices.length; y++) {
          if (this.meicalClinicServices[y].id == this.billClinicServices[i].billClinicService.clinicServiceId)
            this.notYetAddedServices.push(this.fillNotYetAddedList(this.billClinicServices[i]));
        }
      }
    }
    else {
      for (let i = 0; i < this.billClinicServices.length; i++) {
        
        for (let x = 0; x < this.visitClinicServices.length; x++) {
          if (((this.billClinicServices[i].billClinicService.toothId == this.visitClinicServices[x].toothId) && (this.billClinicServices[i].billClinicService.clinicServiceId == this.visitClinicServices[x].clinicServiceId))
            || this.billClinicServices[i].billClinicService.serviceProgress == 100 ) {
            {
              addedBefore = true;
            }
          }
        }
        if (!addedBefore) {
          for (let y = 0; y < this.meicalClinicServices.length; y++) {
            if (this.meicalClinicServices[y].id == this.billClinicServices[i].billClinicService.clinicServiceId) {
              this.notYetAddedServices.push(this.fillNotYetAddedList(this.billClinicServices[i]));
            }
           }
        }
        addedBefore = false;
      }
    }



  }

  save(clinicServiceId: any, toothId: any, specialDiscount: any, servciceProgress: any, billClinicServiceId:any, visitProgress: any) {

    //set  isSubmitInProgress = true; to disable adding new progress while the current one is being saved. 
    this.isSubmitInProgress = true;
    //Add to method
   
    this.visitClinicService.billClinicServiceId = billClinicServiceId;
    this.visitClinicService.clinicServiceId = clinicServiceId;
    this.visitClinicService.toothId = toothId;
    this.visitClinicService.specialDiscount = specialDiscount;
    if (servciceProgress == null)
      servciceProgress = 0;
    this.visitClinicService.serviceProgress = servciceProgress;
    this.visitClinicService.visitProgress = visitProgress;
    this.visitClinicServiceService.addVisitClinicService(this.visitClinicService).subscribe(x => {
      this.toaster.success('Add Service!', 'Add successfully.');
      this.isSubmitInProgress = false;
      //Update the  visit services list
      this.visitClinicServiceService.getVisitClinicServicesByVisitId(this.visitClinicService.visitId).subscribe(x => {
        this.notYetAddedServices = [];
        this.setVisitClinicServices(x);
      })
      this.status = 1;
    }, error => {
      this.toaster.error('Add Service!', error.error);
    },
    ()=> this.showSpinner=false);
   
  }


  validate(clinicServiceId, toothId, serviceProgress : number) {
    var input, totalProgress: number
    
    input = document.getElementById("visitProgress" + clinicServiceId + toothId);

    // adding + before input.value to convert it to a number 
    totalProgress = +input.value + serviceProgress;

    if (input.value == 0 || input.value > 100 || input.value < 0 || input.value == null || input.value == undefined || input.value.length == 0 || totalProgress > 100)
      input.classList.add('is-invalid');
  
    else
      input.classList.remove('is-invalid');
  }

  disable(input, servciceProgress) {
    if (this.isSubmitInProgress == true || input == 0 || input > 100 || input < 0 || input == null || input == undefined || input.length == 0 || ((input + servciceProgress) > 100))
      return true;
    else
      return false;
  }
}
