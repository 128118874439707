import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute,  } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/forkJoin';
import { ToastrService } from 'ngx-toastr';

import { BillService } from '../services/bill.service';
import { PatientService } from '../services/patient.service';
import { DoctorService } from '../services/doctor.service';
import { BillClinicServiceService } from '../services/billClinicService.service';

import { Doctor } from '../models/doctor';
import { Bill, SaveBill } from '../models/bill';
import { BillServiceWithDrProgress } from '../models/billServiceWithDrProgress';
import { Pagination } from '../models/pagination';
import { DoctorServiceProgress } from '../models/doctorServiceProgress';

@Component({
	selector: 'bill-form',
  templateUrl: './bill-form.component.html',
  styleUrls: ['./bill-form.component.css']
})


export class BillFormComponent implements OnInit {

  @Input() billIdModal: any;

  date = new Date();

  billDoctorName: string = "";
  srvDoctors: DoctorServiceProgress[] = [];

  doctors: string[] = [];
  drIds: number[] = [];

  saveBill: SaveBill = {
    id: 0,
    patientId: 0,
    expectedPayment: 0,
    actualPayment: 0,
    eligiblePayment: 0,
    status: '',
    clinicServices: [],
    payments: [],
    patient: {
      id: 0,
      name: '',
      mobile: '',
      gender: '',
      address: '',
      age: '',
      occupation: "",
      joiningDate: null,
      email: '',
      nationality: '',
      referral: "",
      insuranceCompany: '',
      doctorId: null,
      doctorName: '',
      bills: [],
      visits: []
    }
  };

  bill: Bill = {
    patientId: 0,
    expectedPayment: 0,
    actualPayment: 0,
    eligiblePayment: 0,
    clinicServices: [],
    payments: []
  };

  billClinicServices: BillServiceWithDrProgress[] =[];
  tempDrSrvProgress: DoctorServiceProgress[] = [];

  sources: any[];
  showSpinner = true;

  constructor(
		private route: ActivatedRoute,
    private billService: BillService,
    private patientService: PatientService,
    private doctorService: DoctorService,
    private billClinicServiceService: BillClinicServiceService,
    private toaster: ToastrService) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
		this.route.params.subscribe(p => {
			this.saveBill.id = +p['id'] || 0;
		});
	}

	ngOnInit() {
      this.billClinicServices.forEach(srv => {
        srv.billClinicService = {
          billId: 0,
          clinicServiceId: 0,
          toothId: 0,
          servicePrice: 0,
          serviceProgress: 0,
          specialDiscount: 0,
          note: ""
        };
        srv.doctorServiceProgress = [];
      });


    if (this.billIdModal)
    {
      this.saveBill.id = this.billIdModal
    }

    this.initialize()


  }

  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    if (changes['billIdModal']) {
      this.saveBill.id = this.billIdModal;
      this.initialize()
    }
  }

  initialize() {
    this.sources = [];

    if (this.saveBill.id) {
      this.sources.push(this.billService.getBill(this.saveBill.id));
      this.sources.push(this.billClinicServiceService.getBillClinicServicesByBillId(this.saveBill.id));

    }

    Observable.forkJoin(this.sources).subscribe(data => {

      if (this.saveBill.id) {
        this.setSaveBill(data[0]);
        this.setBillClinicServices(data[1]);
      }
    },
      error => console.log(error),
      () => this.showSpinner = false);

  }

  private setBillClinicServices(billClinicServices: any) {
    this.billClinicServices = billClinicServices;

    this.billClinicServices.forEach(srv => {
      srv.doctorServiceProgress.forEach(dr => {
        if (this.doctors.indexOf(dr.doctorName) == -1) {
          this.doctors.push(dr.doctorName);
          this.drIds.push(dr.doctorId);
        }
      })
    });

    this.billClinicServices.forEach(srv => {
      this.tempDrSrvProgress = srv.doctorServiceProgress;
      srv.doctorServiceProgress = [];
      this.drIds.forEach(dr => {
        var newDocotrSrvProgress: DoctorServiceProgress = {
          doctorId: dr,
          serviceProgress: 0,
          doctorName: ""
        }
        if (this.tempDrSrvProgress.find(d => d.doctorId == dr) != null) {
          newDocotrSrvProgress.serviceProgress = this.tempDrSrvProgress.find(d => d.doctorId == dr).serviceProgress;
        }
        srv.doctorServiceProgress.push(newDocotrSrvProgress);
      })
    });
    console.log(this.doctors);
    console.log(this.billClinicServices);
  }

	private setSaveBill(saveBill: any) {
      this.saveBill.id = saveBill.id;
      this.saveBill.patientId = saveBill.patientId;
      this.saveBill.expectedPayment = saveBill.expectedPayment;
      this.saveBill.actualPayment = saveBill.actualPayment;
      this.saveBill.eligiblePayment = saveBill.eligiblePayment;
      this.saveBill.status = saveBill.status;
      this.saveBill.clinicServices = saveBill.clinicServices;
      this.saveBill.payments = saveBill.payments;
      this.saveBill.patient = saveBill.patient;
  }

  private setBill(saveBill: any) {
    this.bill.patientId = saveBill.patientId;
    this.bill.expectedPayment = saveBill.expectedPayment;
    this.bill.actualPayment = saveBill.actualPayment;
    this.bill.eligiblePayment = saveBill.eligiblePayment;
    this.bill.clinicServices = saveBill.clinicServices;
    this.bill.payments = saveBill.payments;
  }

	submit() {
		// Check if its existing patient so we are updating it
		if (this.saveBill.id) {
          this.billService.updateBill(this.saveBill).subscribe(x => {
            this.toaster.success('Edit Bill!', 'Update successfully.');
          });
		}
		// Check if its new patient so we are adding it
    else {
          this.setBill(this.saveBill);
          this.billService.createBill(this.bill).subscribe(x => {
            this.toaster.success('Add Bill!', 'Add successfully.');
          });
		}
  }


  print() {
    this.date = new Date();
    window.print();
  }
}
