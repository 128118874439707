import { Component, OnInit, Inject } from '@angular/core';
import { splitAtColon, error } from '@angular/compiler/src/util';

import { SaveLabRequest } from '../models/labRequest';
import { LabServiceService } from '../services/labService.service';
import { AuthService } from '../services/auth.service';
import { Pagination } from '../models/pagination';


@Component({
	selector: 'labService-list',
  templateUrl: './labService-list.component.html',
  styleUrls: ['./labService-list.component.css']
})

export class LabServiceListComponent implements OnInit {

  public labServices: SaveLabRequest[];

  showSpinner = true;

  pagination: Pagination = { page: 1, limit: 15, mobile: '', name: '' };
  total = 0;
  page = 1;
  limit= 15;
  constructor(private labServiceService: LabServiceService,
              private authService: AuthService) { }


  ngOnInit() {

    this.getLabServices();
  }

  getLabServices(): void {

    this.labServiceService.getSomeLabServices(this.pagination)
      .subscribe(res => {
        this.labServices = res['page']['data'];
        this.total = res['page'].total;
        //this.loading = false;
      },
        error => console.log("Error: ", error),
        () => { this.showSpinner = false }
      );
  }

  isOnRole(roleList) {
    return this.authService.roleMatch(roleList);
  }

  goToPrevious(): void {
    // console.log('Previous Button Clicked!');
    this.page--;
    this.pagination.page--;
    this.getLabServices();
  }

  goToNext(): void {
    // console.log('Next Button Clicked!');
    this.page++;
    this.pagination.page++;
    this.getLabServices();
  }

  goToPage(n: number): void {
    this.page = n;
    this.pagination.page = n;
    this.getLabServices();
  }

  filter() {
  var input, filter, table, tr, td, i, txtValue;
  input = document.getElementById("myInput");
  filter = input.value.toUpperCase();
  table = document.getElementById("myTable");
  tr = table.getElementsByTagName("tr");
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[1];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
}
  
}
