import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SaveTooth } from '../models/tooth';

@Injectable()
export class ToothService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getTeeth() {
    return this.http.get<SaveTooth[]>(this.baseUrl + 'api/teeth');
  }

  getTooth(id: string) {
    return this.http.get<SaveTooth>(this.baseUrl + 'api/teeth' + '/' + id);
  }


}
