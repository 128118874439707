import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Medicine , SaveMedicine } from '../models/medicine';

@Injectable()
export class MedicineService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getMedicines() {
    return this.http.get<SaveMedicine[]>(this.baseUrl + 'api/medicines');
	}

	getMedicine(id: number) {
      return this.http.get<SaveMedicine>(this.baseUrl + 'api/medicines' + '/' + id);
    //      .subscribe(res => res.json());
	}

	createMedicine(medicine: Medicine) {
		//return this.http.post('/api/ceos', ceo)
    //      .subscribe(res => res.json());
      return this.http.post<Medicine>(this.baseUrl + 'api/medicines', medicine);
	}

	updateMedicine(saveMedicine: SaveMedicine) {
		//return this.http.put('/api/ceos/' + ceo.id, ceo)
     //     .subscribe(res => res.json());

      console.log(saveMedicine)
      return this.http.put<SaveMedicine>(this.baseUrl + 'api/medicines' + '/'+ saveMedicine.id, saveMedicine);
	}
}
