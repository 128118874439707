import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/forkJoin';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { BillService } from '../services/bill.service';
import { PatientService } from '../services/patient.service';
import { DoctorService } from '../services/doctor.service';

import { Doctor, SaveDoctor } from '../models/doctor';
import { Patient, SavePatient } from '../models/patient';
import { Bill } from '../models/bill';

@Component({
	selector: 'patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.css']
})


export class PatientFormComponent implements OnInit {

  @Input() amIChildOfMainView: any;
  @Output() addVisitToAddedPatient = new EventEmitter<number>();

  sources: any[];
  status: any = 0;
  isEditForm: boolean = false;

  doctors: SaveDoctor[] = [];

  bill: Bill = {
    patientId: 0,
    expectedPayment: 0,
    actualPayment: 0,
    eligiblePayment: 0,
    clinicServices: [],
    payments: []
  };

  savePatient: SavePatient = {
    id: 0,
    name: '',
    mobile: '',
    gender: '',
    address: '',
    age: '',
    occupation: '',
    joiningDate: null,
    email: '',
    nationality: '',
    referral: "",
    insuranceCompany: '',
    doctorId: null,
    doctorName: '',
    bills: [],
    visits: []
  };

  patient: Patient = {
    name: '',
    mobile: '',
    gender: '',
    address: '',
    age: '',
    occupation: "",
    email: "",
    nationality: "",
    referral: "",
    insuranceCompany: "",
    doctorId: null,
    bills: [],
    visits: []
  };

  formH1Text = "";
  showSpinner = true;

  constructor(
		private route: ActivatedRoute,
    private billService: BillService,
    private patientService: PatientService,
    private doctorService: DoctorService,
    private toaster: ToastrService,
    private router: Router) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
		this.route.params.subscribe(p => {
			this.savePatient.id = +p['id'] || 0;
		});
	}

	ngOnInit() {

      this.sources = [
        this.doctorService.getDoctors(),
      ];

      if (this.savePatient.id) {
        this.sources.push(this.patientService.getPatient(this.savePatient.id));
        this.formH1Text = "Edit Patient Details";
        this.isEditForm = true;
      }
      else {
        this.formH1Text = "Add New Patient";
      }

      Observable.forkJoin(this.sources).subscribe(data => {        
        this.setDoctors(data[0]);
        if (this.savePatient.id) {
          this.setSavePatient(data[1]);
        }
      },
        error => console.log(error),
        () => this.showSpinner = false);
	}

  private setSavePatient(savePatient: any) {
    this.savePatient.id = savePatient.id;
    this.savePatient.name = savePatient.name;
    this.savePatient.mobile = savePatient.mobile;
    this.savePatient.gender = savePatient.gender;
    this.savePatient.address = savePatient.address;
    this.savePatient.age = savePatient.age;
    this.savePatient.occupation = savePatient.occupation;
    this.savePatient.joiningDate = savePatient.joiningDate;
    this.savePatient.email = savePatient.email;
    this.savePatient.nationality = savePatient.nationality;
    this.savePatient.referral = savePatient.referral;
    this.savePatient.insuranceCompany = savePatient.insuranceCompany;
    if (savePatient.doctorId != 0) {
      this.savePatient.doctorId = savePatient.doctorId;
      this.savePatient.doctorName = savePatient.doctorName;
    }
    
  }

  private setPatient(savePatient: any) {
    this.patient.name = savePatient.name;
    this.patient.mobile = savePatient.mobile;
    this.patient.gender = savePatient.gender;
    this.patient.age = savePatient.age;
    this.patient.address = savePatient.address;
    this.patient.occupation = savePatient.occupation;
    this.patient.email = savePatient.email;
    this.patient.nationality = savePatient.nationality;
    this.patient.referral = savePatient.referral;
    this.patient.insuranceCompany = savePatient.insuranceCompany;
    this.patient.doctorId = savePatient.doctorId;

  }

  private setDoctors(doctors: any) {
    this.doctors = doctors;
  }

  private setBill(patientId: any) {
    this.bill.patientId = patientId;
  }


	submit() {
		// Check if its existing patient so we are updating it
      if (this.savePatient.id) {
          this.patientService.updatePatient(this.savePatient).subscribe(x => {
            this.toaster.success('Edit Patient!', 'Update successfully.');
            this.router.navigateByUrl('/patients/view/' + this.savePatient.id);
          }, error => {
            this.toaster.error('Edit patient!', error.error);
          });          
		}
		// Check if its new patient so we are adding it
    //TODO This should be removed because this is view only for existing patient. 
    else {
          this.setPatient(this.savePatient);
          this.patientService.createPatient(this.patient).subscribe(x => {
            this.toaster.success('Add Patient!', 'Added successfully.');
            this.setBill(x.id);
            this.billService.createBill(this.bill)
              .subscribe(x => {
                this.toaster.success('New Bill!', 'New Bill Created for the patient successfully');
                this.status = 1
              });
            this.router.navigateByUrl('/patients/view/'+x.id);
          }, error => {
            this.toaster.error('Add Patient!', error.error);
          });
		}
  }


  submitChildofMainView() {
    // Check if its existing patient so we are updating it
    if (this.savePatient.id) {
      this.patientService.updatePatient(this.savePatient).subscribe(x => {
        this.toaster.success('Edit Patient!', 'Update successfully.');
        this.router.navigateByUrl('/patients/view/' + this.savePatient.id);
      }, error => {
        this.toaster.error('Edit patient!', error.error);
      });
    }
    // Check if its new patient so we are adding it
    //TODO This should be removed because this is view only for existing patient. 
    else {
      this.setPatient(this.savePatient);
      this.patientService.createPatient(this.patient).subscribe(x => {
        this.toaster.success('Add Patient!', 'Added successfully.');
        this.setBill(x.id);
        this.billService.createBill(this.bill)
          .subscribe(y => {
            this.toaster.success('New Bill!', 'New Bill Created for the patient successfully');
            this.status = 1;
            this.addVisitToAddedPatient.emit(x.id)
          });
        //this.router.navigateByUrl('/patient/newVisit/' + x.id);
       

      }, error => {
        this.toaster.error('Add Patient!', error.error);
      });
    }
  }
}
