import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { FilterDates } from '../models/filterDates';
import { ReportResult } from '../models/reportResult';

@Injectable()
export class ReportService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getReports() {
    return this.http.get<ReportResult>(this.baseUrl + 'api/reports');
  }

  getFilteredReports(filterDates: FilterDates) {
    return this.http.get<ReportResult>(this.baseUrl + 'api/reports' + '/' + '?' + this.toQueryString(filterDates));
  }

  toQueryString(obj: any) {
    var parts = [];
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined)
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
    }
    return parts.join('&');
  }

}
