import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServiceDoctorShare } from '../models/serviceDoctorShare';

@Injectable()
export class ServiceDoctorShareService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getSeriveceDoctorShares() {
    return this.http.get<ServiceDoctorShare[]>(this.baseUrl + 'api/ServiceDoctorShares');
	}

  getSeriveceDoctorSharesByDoctorId(doctorId: number) {
    return this.http.get<ServiceDoctorShare>(this.baseUrl + 'api/ServiceDoctorShares' + '/' + doctorId);
	}

  updateServiceDoctorShare(serviceDoctorShare: ServiceDoctorShare) {
    return this.http.put<ServiceDoctorShare>(this.baseUrl + 'api/ServiceDoctorShares/', serviceDoctorShare);
	}
}
