import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SaveDoctorSalary } from '../models/doctorSalary';
import { DoctorVisitService } from '../models/DoctorVisitService';
import { FilterDates } from '../models/filterDates';

@Injectable()
export class DoctorExpenseService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getDoctorServiceReport(filterDates: FilterDates) {
    
    return this.http.get<DoctorVisitService[]>(this.baseUrl + 'api/DoctorExpenses' + '/doctorServiceReport' + '/' + '?' + this.toQueryString(filterDates) );
	}

  getDoctorSalaries(filterDates: FilterDates) {
    return this.http.get<SaveDoctorSalary>(this.baseUrl + 'api/DoctorExpenses' + '/doctorSalaries' + '/' + '?' + this.toQueryString(filterDates) );
  }

  toQueryString(obj: any) {
    var parts = [];
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined)
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
    }
    return parts.join('&');
  }
}

