import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { ClinicServiceService } from '../services/clinicService.service'
import { ClinicService, SaveClinicService } from '../models/clinicService';



@Component({
	selector: 'clinicService-form',
  templateUrl: './clinicService-form.component.html'
})


export class ClinicServiceFormComponent implements OnInit {

  clinicService: ClinicService = {
    name: '',
    price: 0,
    isMedical: true,
    discount: 0
  };
  saveClinicService: SaveClinicService = {
    id : 0,
    name: '',
    price: 0,
    isMedical: true,
    discount: 0
  };


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clinicServiceService: ClinicServiceService,
    private toaster: ToastrService    ) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
		this.route.params.subscribe(p => {
			this.saveClinicService.id = +p['id'] || 0;
		});
	}

	ngOnInit() {
      if (this.saveClinicService.id) {
        this.clinicServiceService.getClinicService(this.saveClinicService.id).subscribe(result => {
          this.saveClinicService = result;
        })
				//TODO _ assign the CEO data here 
				};
	}
	
  submit() {
    // Check if its existing service so we are updating it
    if (this.saveClinicService.id) {
      this.clinicServiceService.updateClinicService(this.saveClinicService)
        .subscribe(x => {
          this.toaster.success('Edit Service!', 'Update successfully.');
        }, error => {
          this.toaster.error('Edit Service!', error.error);
          });
      this.router.navigateByUrl('/clinicServices');
      
    }
    // Check if its new service so we are adding it
    else {
      this.setClinicService(this.saveClinicService);
      this.clinicServiceService.createClinicService(this.clinicService).subscribe(x => {
        this.toaster.success('Add Service!', 'Add successful.');
        this.router.navigateByUrl('/clinicServices');
      }, error => {
          this.toaster.error('Add Service!', error.error);
          this.router.navigateByUrl('/clinicServices');
        });
      
    }
    
  }

  private setClinicService(saveClinicService : SaveClinicService) {
    this.clinicService.name = saveClinicService.name;
    this.clinicService.price = saveClinicService.price;
    this.clinicService.isMedical = saveClinicService.isMedical
    this.clinicService.discount = saveClinicService.discount;
  }
}
