import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MedicalProfile , SaveMedicalProfile } from '../models/medicalProfile';

@Injectable()
export class MedicalProfileService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getMedicalProfiles() {
    return this.http.get<SaveMedicalProfile[]>(this.baseUrl + 'api/medicalProfiles');
	}

  getMedicalProfile(id: number) {
    return this.http.get<SaveMedicalProfile>(this.baseUrl + 'api/medicalProfiles' + '/' + id);
  }

  getMedicalProfileByPatietnId(patientId: number) {
    return this.http.get<SaveMedicalProfile>(this.baseUrl + 'api/medicalProfiles' + '/' + patientId);
  }

  createMedicalProfile(medicalProfile: MedicalProfile) {
    console.log(medicalProfile);
    return this.http.post<MedicalProfile>(this.baseUrl + 'api/medicalProfiles', medicalProfile);
	}

  updateMedicalProfile(saveMedicalProfile: SaveMedicalProfile) {
    console.log(saveMedicalProfile);
    return this.http.put<SaveMedicalProfile>(this.baseUrl + 'api/medicalProfiles'  , saveMedicalProfile);
	}
}
