import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Expense , SaveExpense } from '../models/expense';
import { Observable } from 'rxjs';
import { Pagination } from '../models/pagination';

@Injectable()
export class ExpenseService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getExpenses() {
    return this.http.get<SaveExpense[]>(this.baseUrl + 'api/expenses');
  }

  getSomeExpenses(pagination: Pagination) {
    return this.http.get<SaveExpense[]>(this.baseUrl + 'api/expenses' + '/' + '?' + this.toQueryString(pagination));
  }

  toQueryString(obj: any) {
    var parts = [];
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined)
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
    }
    return parts.join('&');
  }

  getExpense(id: number) {
    return this.http.get<SaveExpense>(this.baseUrl + 'api/expenses' + '/' + id);
	}

  createExpense(expense: Expense) {
    return this.http.post<Expense>(this.baseUrl + 'api/expenses', expense);
	}

  updateExpense(saveExpense: SaveExpense) {
    return this.http.put<SaveExpense>(this.baseUrl + 'api/expenses' + '/' + saveExpense.id, saveExpense);
  }

  deleteExpense(id: number) : Observable<void> {
    return this.http.delete<void>(this.baseUrl + 'api/expenses' + '/' + id);

  }

}
