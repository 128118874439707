import { Component, OnInit, Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ServiceDoctorShare } from '../models/serviceDoctorShare';
import { ServiceDoctorShareService } from '../services/serviceDoctorShare.service';


@Component({
  selector: 'serviceDoctorShare-list',
  templateUrl: './serviceDoctorShare-list.component.html',
  styleUrls: ['./serviceDoctorShare-list.component.css']
})

export class ServiceDoctorShareListComponent implements OnInit {

  public serviceDoctorShares: ServiceDoctorShare[];

  serviceDoctorShare: ServiceDoctorShare = {
    doctorId: 0,
    clinicServiceId: 0,
    doctorShare: 0
  }
  showSpinner = true;

  constructor(
    private serviceDoctorShareService: ServiceDoctorShareService,
    private toaster: ToastrService) { }


	ngOnInit() {
      this.serviceDoctorShareService.getSeriveceDoctorShares().subscribe(result => {
        this.serviceDoctorShares = result;
        console.log(result);
      },
        error => console.log(error),
        () => this.showSpinner = false);
  }

  save(doctorId: number, clinicServiceId: number, doctorShare: number) {
    this.setServiceDoctorShare(doctorId, clinicServiceId, doctorShare);
    this.serviceDoctorShareService.updateServiceDoctorShare(this.serviceDoctorShare).subscribe(x => {
      this.toaster.success('Edit Share!', 'Update successfully.');
    });
  }

  private setServiceDoctorShare(doctorId: number, clinicServiceId: number, doctorShare: number) {
    this.serviceDoctorShare.doctorId = doctorId;
    this.serviceDoctorShare.clinicServiceId = clinicServiceId;
    this.serviceDoctorShare.doctorShare = doctorShare;
  }

  validate(doctorId) {
    var input, table, i;
    //input = document.getElementById("doctorShare");

    //if (input.value > 100 || input.value < 0 )
    // input.classList.add('is-invalid');

    // else
    // input.classList.remove('is-invalid');

    for (i = 0; i < this.serviceDoctorShares.length; i++) {
      input = document.getElementById("doctorShare" + doctorId);

      if (input.value > 100 || input.value < 0 ||  input.value == null ||  input.value == undefined ||  input.value.length == 0 )
        input.classList.add('is-invalid');

      else
       input.classList.remove('is-invalid');
    }
  }

  disable(input) {
    if (input > 100 || input < 0 || input == null || input == undefined || input.length == 0)
      return true;
    else
      return false;

  }

  filterByDocotr() {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("doctorName");
    filter = input.value.toUpperCase();
    table = document.getElementById("sharesTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[0];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  filterByService() {
    var input, filter, table, tr, td, i, txtValue;
    input = document.getElementById("serviceName");
    filter = input.value.toUpperCase();
    table = document.getElementById("sharesTable");
    tr = table.getElementsByTagName("tr");
    for (i = 0; i < tr.length; i++) {
      td = tr[i].getElementsByTagName("td")[1];
      if (td) {
        txtValue = td.textContent || td.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }


}

