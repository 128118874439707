import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Employee , SaveEmployee } from '../models/employee';

@Injectable()
export class EmployeeService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getEmployees() {
    return this.http.get<SaveEmployee[]>(this.baseUrl + 'api/employees');
	}

	getEmployee(id: number) {
      return this.http.get<SaveEmployee>(this.baseUrl + 'api/employees' + '/' + id);
	}

  createEmployee(employee: Employee) {
    
      return this.http.post<Employee>(this.baseUrl + 'api/employees', employee);
	}

  updateEmployee(saveEmployee: SaveEmployee) {
		 return this.http.put<SaveEmployee>(this.baseUrl + 'api/employees' + '/'+ saveEmployee.id, saveEmployee);
	}
}
