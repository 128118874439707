import { Component, OnInit, Inject } from '@angular/core';

import { SaveLab } from '../models/lab';
import { LabService } from '../services/lab.service' 
import { Pagination } from '../models/pagination';
import { Router, NavigationEnd } from '@angular/router';

@Component({
	selector: 'lab-list',
  templateUrl: './lab-list.component.html',
  styleUrls: ['/lab-list.component.css']
})

export class LabListComponent implements OnInit {

  public labs: SaveLab[];

  labEdited: SaveLab = {
    id: 0,
    name: '',
    address: '',
    phone: '',
    email: ''
  } // holds the lab being currently edited

  contentEdit: boolean; // used to enable table contents editing
  labId: number; //passes chosen lab ID from template to logic
  isSaved: boolean; // indicates whether saveChanges button is pressed

  navigationSubscription;
  showSpinner = true;
  showLabPaymentForm: boolean = false;

  constructor(private labService: LabService, private router: Router) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {

        this.labService.getLabs().subscribe(result => {
        this.labs = result;
        });
      }
    });
  }

  ngOnInit() {
      
      // this.expenseService.getExpenses().subscribe(result => {
      //  this.expenses = result;});

    this.labService.getLabs().subscribe(result => {
      this.labs = result;
    },
      error => console.log(error),
      () => this.showSpinner = false);

      this.contentEdit = false;
      this.labId = 0;
      this.isSaved = true;
  }

  onRequest() {
    this.labEdited.id = this.labId;
    
    for (var i = 0; i < document.getElementsByClassName((this.labId).toString()).length; i++) {
      var td = document.getElementsByClassName((this.labId).toString())[i];
      td.setAttribute('contenteditable', 'true');
      td.setAttribute('style', 'background-color:lightgray;');
    }

    this.isSaved = false;

    for (var i = 0; i < document.getElementsByClassName('edit-btn').length; i++) {
      var btn = <HTMLInputElement> document.getElementsByClassName('edit-btn')[i];
      btn.disabled = true;
    }

    for (var i = 0; i < document.getElementsByClassName('delete-btn').length; i++) {
      var btn = <HTMLInputElement>document.getElementsByClassName('delete-btn')[i];
      btn.disabled = true;
    }
    
  }

  onSaveChanges() {
    this.labService.updateLab(this.labEdited).subscribe(
      () => this.router.navigateByUrl('/labs')
    );
    
  }

  deleteLab(): void {
    
    this.labService.deleteLab(this.labId).subscribe(
      () => this.router.navigateByUrl('/labs')
    );
    
  }

  afterRequest() {
    for (var i = 0; i < document.getElementsByClassName((this.labId).toString()).length; i++) {
      var td = document.getElementsByClassName((this.labId).toString())[i];
      td.setAttribute('contenteditable', 'false');
      td.setAttribute('style', 'background-color:white;');
    }

    for (var i = 0; i < document.getElementsByClassName('delete-btn').length; i++) {
      var btn = <HTMLInputElement>document.getElementsByClassName('delete-btn')[i];
      btn.disabled = false;
    }

    this.isSaved = true;

    for (var i = 0; i < document.getElementsByClassName('edit-btn').length; i++) {
      var btn = <HTMLInputElement>document.getElementsByClassName('edit-btn')[i];
      btn.disabled = false;
    }
    this.router.navigateByUrl('/labs');
    
  }

  activateLabPaymentModal() {
    this.showLabPaymentForm = true;
  }

  exit() {
    window.location.reload();
  }
}


