import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClinicService , SaveClinicService } from '../models/clinicService';
import { Observable } from 'rxjs';

@Injectable()
export class ClinicServiceService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getClinicServices() {
    return this.http.get<SaveClinicService[]>(this.baseUrl + 'api/clinicServices');
	}

  getClinicService(id: number) {
    return this.http.get<SaveClinicService>(this.baseUrl + 'api/clinicServices' + '/' + id);
	}

  createClinicService(clinicService: ClinicService) {
    return this.http.post<ClinicService>(this.baseUrl + 'api/clinicServices', clinicService);
	}

  updateClinicService(saveClinicService: SaveClinicService) {
    return this.http.put<SaveClinicService>(this.baseUrl + 'api/clinicServices' + '/' + saveClinicService.id, saveClinicService);
  }

  deleteClinicService(id: number): Observable<void> {
    return this.http.delete<void>(this.baseUrl + 'api/clinicServices' + '/' + id);

  }

}
