import { Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/forkJoin';
import { ToastrService } from 'ngx-toastr';

import { PatientService } from '../services/patient.service';
import { DoctorService } from '../services/doctor.service';
import { LabService } from '../services/lab.service';


import { Visit, SaveVisit } from '../models/visit';
import { SaveClinicService } from '../models/clinicService';
import { VisitClinicService } from '../models/visitClinicService';
import { BillClinicService } from '../models/billClinicService';
import { SaveDoctor } from '../models/doctor';
import { SavePatient } from '../models/patient';
import { LabRequest, SaveLabRequest } from '../models/labRequest';
import { MOMENT } from 'angular-calendar';
import { retry } from 'rxjs/operators';
import { SaveLab } from '../models/lab';
import { LabServiceService } from '../services/labService.service';
import { FrontEndService } from '../services/fronEnd.service';

@Component({
  selector: 'labService-form',
  templateUrl: './labService-form.component.html',
  styleUrls: ['./labService-form.component.css']
})
  

export class LabServiceFormComponent implements OnInit {

  today = "";
  formH1Text = "New Lab Service Request";
  showSpinner = true;

  clinicServices: SaveClinicService[];
  visitClinicServices: VisitClinicService[];
  billClinicServices: BillClinicService[];
  doctors: SaveDoctor[];
  labs: SaveLab[];


  savePatient: SavePatient = {
    id: 0,
    name: '',
    mobile: '',
    gender: '',
    address: '',
    age: '',
    occupation: '',
    joiningDate: null,
    email: '',
    nationality: '',
    referral: "",
    insuranceCompany: '',
    doctorId: null,
    doctorName:'',
    bills: [],
    visits: []
  };

  labRequest: LabRequest = {
    patientId: 0,
    doctorId: 0,
    labId: 0,
    name: "",
    sendDate: null,
    expDeliveryDate: null,
    actDeliveryDate: null,
    labPrice: 0,
    status: ""
  };

  saveLabRequest: SaveLabRequest = {
    id: 0,
    patientId: null,
    doctorId: null,
    labId: null,
    name: "",
    sendDate: null,
    expDeliveryDate: null,
    actDeliveryDate: null,
    labPrice: 0,
    status: "",
    patient: {
      id: 0,
      name: '',
      mobile: '',
      gender: '',
      address: '',
      age: '',
      occupation: "",
      joiningDate: null,
      email: '',
      nationality: '',
      referral: "",
      insuranceCompany: '',
      doctorId: null,
      doctorName:'',
      bills: [],
      visits: []
    },
    lab: {
      id: 0,
      name: "",
      phone: "",
      email: "",
      address:""
    }
  };

  sources: any[];
  tabId: any = 1;
  showText: string = 'Show ';
  calenderSendDate: any;
  calenderExpDate: any;
  calenderActDate: any;
  isSendDatePicked: boolean = false;
  isExpDatePicked: boolean = false;
  isActDatePicked: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private patientService: PatientService,
    private labService: LabService,
    private doctorService: DoctorService,
    private labServicesService: LabServiceService,
    private toaster: ToastrService,
    private router: Router,
    private frontEndService: FrontEndService) {

    // this route to get the Id from the route or set it to zero to be able 
    // to add new element without "|| 0" I will not be able to add new patient
    // because id will be set to null and the server will feedback with error.
    route.params.subscribe(p => {
      this.saveLabRequest.id = +p['id'] || 0;
      this.saveLabRequest.patientId = +p['patientId'] || 0;
    });
  }

  ngOnInit() {
    this.sources = [this.doctorService.getDoctors(),
                    this.labService.getLabs()];

    if (this.saveLabRequest.id) {
      this.formH1Text = "Edit Lab Request";
      this.sources.push(this.labServicesService.getLabService(this.saveLabRequest.id));
    }
    else {
      this.formH1Text = "New Lab Service Request";
      this.sources.push(this.patientService.getPatient(this.saveLabRequest.patientId));
    }

    Observable.forkJoin(this.sources).subscribe(data => {
      this.setDoctors(data[0]);
      this.setLabs(data[1]);

      if (this.saveLabRequest.id) {
        this.setSaveLabRequest(data[2]);
      }
      else {
        this.setLabRequestPatient(data[2]);
      }

    },
      error => console.log(error),
      () => this.showSpinner = false);
    
  }

  private setDoctors(doctors: any) {
    this.doctors = doctors;
  }

  private setLabs(labs: any) {
    this.labs = labs;
  }

  private setLabRequestPatient(patient: any) {
    this.saveLabRequest.patient = patient;
  }

  submit() {
    // Check if its existing patient so we are updating it
    if (this.saveLabRequest.id) {
      this.labServicesService.updateLabService(this.saveLabRequest).subscribe(x => {
        this.toaster.success('Edit Lab Request!', 'Update successfully.');
        this.router.navigateByUrl('/labServices');
      }, error => {
        this.toaster.error('Edit Lab Request!', error.error);
      });
    }
    // Check if its new patient so we are adding it
    else {
      this.setLabRequest(this.saveLabRequest);
      this.labServicesService.createLabService(this.labRequest).subscribe(x => {
        this.toaster.success('Add Lab Request!', 'Add successfully.');
        //this.router.navigateByUrl('/visits');
      }, error => {
        this.toaster.error('Add Lab Request!', error.error);
        },
        () => this.router.navigateByUrl('/labServices'));
    }

  }

  private setLabRequest(labRequest: any) {
    this.labRequest.name = labRequest.name;
    this.labRequest.patientId = labRequest.patientId;
    this.labRequest.doctorId = labRequest.doctorId;
    this.labRequest.labId = labRequest.labId;
    this.labRequest.sendDate = labRequest.sendDate;
    this.labRequest.expDeliveryDate = labRequest.expDeliveryDate;
    this.labRequest.actDeliveryDate = labRequest.actDeliveryDate;
    this.labRequest.labPrice = labRequest.labPrice;
    this.labRequest.status = labRequest.status;
  }

  private setSaveLabRequest(labRequest: any) {
    this.saveLabRequest.id = labRequest.id;
    this.saveLabRequest.name = labRequest.name;
    this.saveLabRequest.patientId = labRequest.patientId;
    this.saveLabRequest.doctorId = labRequest.doctorId;
    this.saveLabRequest.labId = labRequest.labId;
    this.calenderSendDate = this.frontEndService.formatBkEndDate(labRequest.sendDate);
    this.saveLabRequest.sendDate = labRequest.sendDate;
    this.calenderExpDate = this.frontEndService.formatBkEndDate(labRequest.expDeliveryDate);
    this.saveLabRequest.expDeliveryDate = labRequest.expDeliveryDate;
    this.calenderActDate = this.frontEndService.formatBkEndDate(labRequest.actDeliveryDate);
    this.saveLabRequest.actDeliveryDate = labRequest.actDeliveryDate;
   
    this.saveLabRequest.labPrice = labRequest.labPrice;
    this.saveLabRequest.status = labRequest.status;
    this.saveLabRequest.patient = labRequest.patient
    this.saveLabRequest.lab = labRequest.lab;
  }

  sendDatePicked() {
    var input;
    input = document.getElementById("sendDate");
    this.saveLabRequest.sendDate = input.value;
    this.isSendDatePicked = true;
  }

  expDatePicked() {
    var input;
    input = document.getElementById("expDeliveryDate");
    this.saveLabRequest.expDeliveryDate = input.value;
    this.isExpDatePicked = true;
  }

  actDatePicked() {
    var input;
    input = document.getElementById("actDeliveryDate");
    this.saveLabRequest.actDeliveryDate = input.value;
    this.isActDatePicked = true;
  }
}
