import { Component, OnInit, Inject } from '@angular/core';
import { SaveIdentityRole } from '../models/identityRole';
import { IdentityRoleService } from '../services/identityRole.service'
import { AuthService } from '../services/auth.service';

@Component({
	selector: 'identityRole-list',
	templateUrl: './identityRole-list.component.html'
})

export class IdentityRoleListComponent implements OnInit {

  public identityRoles: SaveIdentityRole[];
  showSpinner = true;
  userRoleValue: number = 100;

  constructor(private identityRoleService: IdentityRoleService,
    private authService: AuthService) {  }


  ngOnInit() {

    this.userRoleValue = this.authService.getRoleValue();

    this.identityRoleService.getIdentityRoles().subscribe(result => {
      this.identityRoles = result;
    },
      error => console.log(error),
      () => this.showSpinner = false);
      
	}
}


