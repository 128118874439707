import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';

import { LabService } from '../services/lab.service';
import { Lab, SaveLab } from '../models/lab';
import { ToastrService } from 'ngx-toastr';


interface ExpenseItem {
  value: string;
}

@Component({
	selector: 'lab-form',
	templateUrl: './lab-form.component.html'
})


export class LabFormComponent implements OnInit {
  lab: Lab = {
    name: '',
    address: '',
    phone: '',
    email: ''
  }

  saveLab: SaveLab ={
    id: 0,
    name: '',
    address: '',
    phone: '',
    email: ''
  }

  labId: number;

	constructor(
      private route: ActivatedRoute,
      private rte: Router,
      private labService: LabService,
      private toaster: ToastrService
        ) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
		this.route.params.subscribe(p => {
			this.saveLab.id = +p['id'] || 0;
		});
	}

  ngOnInit() {


    if (this.saveLab.id) {
      this.labService.getLab(this.saveLab.id).subscribe(result => {
        this.saveLab = result;
      })
      
    };

	}
	
	submit() {
		// Check if its existing expense so we are updating it
      if (this.saveLab.id) {
        this.labService.updateLab(this.saveLab).subscribe(console.clear);
        this.rte.navigateByUrl('/labs');
		}
		// Check if its new expense so we are adding it
      else {
        this.setLab(this.saveLab);
        this.labService.createLab(this.lab).subscribe(x => {
          this.toaster.success('Lab', 'Added successfully.');
          this.rte.navigateByUrl('/labs');
        });
        this.rte.navigateByUrl('/labs');
      }
  }

  private setLab(saveLab : SaveLab) {
    this.lab.address = saveLab.address;
    this.lab.name = saveLab.name;
    this.lab.phone = saveLab.phone;
    this.lab.email = saveLab.email;
    
  }

  deleteLab(): void {
    this.labService.deleteLab(this.labId).subscribe(
      () => console.log(this.labId)
    );
  }

}
