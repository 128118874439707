import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Lab , SaveLab } from '../models/lab';
import { Observable } from 'rxjs';

@Injectable()
export class LabService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getLabs() {
    return this.http.get<SaveLab[]>(this.baseUrl + 'api/labs');
  }

  getLab(id: number) {
    return this.http.get<SaveLab>(this.baseUrl + 'api/labs' + '/' + id);
	}

  createLab(lab: Lab) {
    return this.http.post<Lab>(this.baseUrl + 'api/labs', lab);
	}

  updateLab(saveLab: SaveLab) {
    return this.http.put<SaveLab>(this.baseUrl + 'api/labs' + '/' + saveLab.id, saveLab);
  }

  deleteLab(id: number) : Observable<void> {
    return this.http.delete<void>(this.baseUrl + 'api/labs' + '/' + id);

  }

}
