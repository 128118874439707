import { Component, OnInit, Inject } from '@angular/core';
import { SaveCEO } from '../models/ceo';
import { CEOService } from '../services/ceo.service';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { UserManager } from 'oidc-client';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { VisitService } from '../services/visit.service';
import { FilterDates } from '../models/filterDates';

@Component({
	selector: 'mainView',
  templateUrl: './mainView.component.html',
  styleUrls:['./mainView.component.css']
})

export class MainViewComponent implements OnInit {

  dayPicked: Date;
  refreshtoggleMyVisits: boolean = false;
  refreshVisitForm: boolean = false;
  addnewVisitRequest: boolean = false;
  refreshPatientView: boolean = false;
  refreshCalender: boolean = false;
  moveFromCalender: boolean = false;

  patientId: number;
  visitId: number
  billId: number;

  //the following flags are to adjust the view
  isBookingWindow = true;
  isVisitsSection: boolean = false;
  isPatientSection: boolean = false;
  isVisitFormSection: boolean = false;
  isPatientProfile: boolean = false;
  isHomeWindow: boolean = false;
  ////////////////////////////////////////////

  showSpinner = true;

  filterDates: FilterDates = {
    id:0,
    startDateDay: 0,
    startDateMonth: 0,
    startDateYear: 0,
    endDateDay: 0,
    endDateMonth: 0,
    endDateYear: 0,
  }

  constructor(private visitService: VisitService,
              private toaster: ToastrService) { }


  ngOnInit() {
    var today = new Date();
    this.isHomeWindow = true;
    //this.setDayPicked(today);
  }

  setDayPicked(dayPicked: Date) {
    //the following flags are to adjust the view
    this.isVisitFormSection = false;
    this.isPatientSection = false;
    this.isPatientProfile = false;
    this.isHomeWindow = false;
    this.moveFromCalender = false;
    //////////////////////////

    this.dayPicked = new Date(dayPicked);


    if (dayPicked.getTimezoneOffset() < 0)
      this.dayPicked.setTime(this.dayPicked.getTime() - dayPicked.getTimezoneOffset() * 60000);

    if (dayPicked.getTimezoneOffset() > 0)
      this.dayPicked.setTime(this.dayPicked.getTime() + dayPicked.getTimezoneOffset() * 60000);


    //initialize the Myvisit
    this.filterDates.startDateDay = dayPicked.getUTCDate() + 1;
    this.filterDates.startDateMonth = dayPicked.getUTCMonth() + 1;
    this.filterDates.startDateYear = dayPicked.getFullYear();
    this.visitService.setVisitsDate(this.filterDates);

    this.isVisitsSection = true;
    this.refreshtoggleMyVisits = !this.refreshtoggleMyVisits;
  }

  viewNewPatinetForm() {
    //the following flags are to adjust the view
    this.isPatientSection = true;
    this.isVisitsSection = false;
    this.isVisitFormSection = false;
    this.isPatientProfile = false;
    this.isHomeWindow = false;
    //////////////////////////
    //this.refreshCalender = !this.refreshCalender
    this.moveFromCalender = true;
  }

  viewPatientProfile(patinetId: number) {

    this.patientId = patinetId;

    //2. set the view to visit form
    this.isVisitsSection = false;
    this.isPatientSection = false;
    this.isVisitFormSection = false;
    this.isPatientProfile = true;
    this.isHomeWindow = false;
    this.moveFromCalender = true;
    ///////////////////////////

    // refresh the patinet view
    this.refreshPatientView = !this.refreshPatientView;

   
  }

  addVisitToPatient(patinetId: number) {

    if (this.isVisitFormSection) {
      this.toaster.warning('Close the current visit form first');
    }
    else {
      this.refreshVisitForm = false;
      this.patientId = patinetId;
      //the following flags are to adjust the view
      this.isVisitsSection = false;
      this.isPatientSection = false;
      this.isVisitFormSection = true;
        this.isPatientProfile = false;
        this.moveFromCalender = true;
      this.isHomeWindow = false;
      this.refreshCalender = false;
  ////////////////////////////////////////////

      this.addnewVisitRequest = true;

    }

  }

  editVisit(data: number[]) {
    this.addnewVisitRequest = false;
    //1. fill the data from myVisit List
    this.visitId = data[0];
    this.billId = data[1];

    //2. set the view to visit form
    this.isVisitsSection = false;
    this.isPatientSection = false;
    this.isVisitFormSection = true;
    this.isPatientProfile = false;
    this.isHomeWindow = false;
    this.moveFromCalender = true;
    this.refreshCalender = false;
    ///////////////////////////

    //2. Change the refreshVisitForm to initialze the visitForm
    this.refreshVisitForm = true;
   
     
  }

  showBooking() {
    this.isBookingWindow = true;
  }

  hideBooking() {
    this.isBookingWindow = false;
  }

  closeForm() {
    //the following flags are to adjust the view
    this.isPatientSection = false;
    this.isVisitsSection = false;
    this.isVisitFormSection = false;
    this.isPatientProfile = false;
    this.isHomeWindow = true;
    this.refreshCalender = true;
    //////////////////////////
  }

}


