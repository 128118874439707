import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VisitMedicine } from '../models/visitMedicine';

@Injectable()
export class VisitMedicineService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getVisitMedicines() {
    return this.http.get<VisitMedicine[]>(this.baseUrl + 'api/visitMedicines');
	}

  getVisitMedicinesByVisitId(visitId: number) {
    return this.http.get<VisitMedicine>(this.baseUrl + 'api/visitMedicines' + '/' + visitId);
	}

  addVisitMedicine(visitMedicine: VisitMedicine) {
    return this.http.post<VisitMedicine>(this.baseUrl + 'api/visitMedicines', visitMedicine);
	}
}
