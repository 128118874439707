import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'printForm-footer',
  templateUrl: './printForm-footer.component.html',
  styleUrls: ['./printForm-footer.component.css']
})

export class printFormFooterComponent implements OnInit {

  constructor() { }

	ngOnInit() {
  }
}


