import { Component, OnInit, Inject } from '@angular/core';

import { SaveExpense } from '../models/expense';
import { ExpenseService } from '../services/expense.service' 
import { DatePipe } from '@angular/common';
import { Pagination } from '../models/pagination';
import { Router, NavigationEnd } from '@angular/router';

@Component({
	selector: 'expense-list',
  templateUrl: './expense-list.component.html',
  styleUrls: ['/expense-list.component.css']
})

export class ExpenseListComponent implements OnInit {

  public expenses: SaveExpense[];
  showSpinner = true;

  expEdited: SaveExpense = {
    id: 0,
    description: '',
    amount: 0,
    date: null,
    comment: ''
  } // holds the expense being currently edited

  contentEdit: boolean; // used to enable table contents editing
  expId: number; //passes chosen expense ID from template to logic
  isSaved: boolean; // indicates whether saveChanges button is pressed

  pagination: Pagination = { page: 1, limit: 15, mobile:"", name:"" };
  total = 0;
  page = 1;
  limit = 15;

  ExpenseOptions: string[] = [
    "Tools", "Monthly Rent Fees",
    "Electricty Bill", "Water Bill",
    "Gas Bill", "Phone Bill", "Salaries",
    "Maintanence","Marketing", "Others"
  ]

  navigationSubscription;
  constructor(private expenseService: ExpenseService,
              private pipeDate: DatePipe,
              private router: Router) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.getExpenses();    
      }
    });}


  ngOnInit() {
      
      // this.expenseService.getExpenses().subscribe(result => {
      //  this.expenses = result;});

      this.getExpenses();

      this.contentEdit = false;
      this.expId = 0;
      this.isSaved = true;
  }

  getExpenses(): void {

    this.expenseService.getSomeExpenses(this.pagination)
      .subscribe(res => {
        this.expenses = res['page']['data'];
        this.total = res['page'].total;
        //this.loading = false;
      },
        error => console.log("Error: ", error),
        () => { this.showSpinner = false }
      );
  }

  goToPrevious(): void {
    // console.log('Previous Button Clicked!');
    this.page--;
    this.pagination.page--;
    this.getExpenses();
  }

  goToNext(): void {
    // console.log('Next Button Clicked!');
    this.page++;
    this.pagination.page++;
    this.getExpenses();
  }

  goToPage(n: number): void {
    this.page = n;
    this.pagination.page = n;
    this.getExpenses();
  }

  onRequest() {
    this.expEdited.id = this.expId;
    
    for (var i = 0; i < document.getElementsByClassName((this.expId).toString()).length; i++) {
      var td = document.getElementsByClassName((this.expId).toString())[i];
      td.setAttribute('contenteditable', 'true');
      td.setAttribute('style', 'background-color:lightgray;');
    }

    this.isSaved = false;

    for (var i = 0; i < document.getElementsByClassName('edit-btn').length; i++) {
      var btn = <HTMLInputElement> document.getElementsByClassName('edit-btn')[i];
      btn.disabled = true;
    }

    for (var i = 0; i < document.getElementsByClassName('delete-btn').length; i++) {
      var btn = <HTMLInputElement>document.getElementsByClassName('delete-btn')[i];
      btn.disabled = true;
    }
    
  }

  onSaveChanges() {
    this.expEdited.date = new Date(this.pipeDate.transform(this.expEdited.date, 'medium'));
    this.expenseService.updateExpense(this.expEdited).subscribe(
      () => this.router.navigateByUrl('/expenses')
    );
    
  }

  deleteExpense(): void {
    
    this.expenseService.deleteExpense(this.expId).subscribe(
      () => this.router.navigateByUrl('/expenses')
    );
  }

  afterRequest() {
    for (var i = 0; i < document.getElementsByClassName((this.expId).toString()).length; i++) {
      var td = document.getElementsByClassName((this.expId).toString())[i];
      td.setAttribute('contenteditable', 'false');
      td.setAttribute('style', 'background-color:white;');
    }

    for (var i = 0; i < document.getElementsByClassName('delete-btn').length; i++) {
      var btn = <HTMLInputElement>document.getElementsByClassName('delete-btn')[i];
      btn.disabled = false;
    }

    this.isSaved = true;

    for (var i = 0; i < document.getElementsByClassName('edit-btn').length; i++) {
      var btn = <HTMLInputElement>document.getElementsByClassName('edit-btn')[i];
      btn.disabled = false;
    }
    this.router.navigateByUrl('/expenses');
  }
}


