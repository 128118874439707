import { Component, NgModule, AfterViewInit, ElementRef, ViewChild, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common'; 
import { delay, ninvoke } from 'q';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  patientsCounter: number;
  teethCounter: number;
  appointmentsCounter: number;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.patientsCounter = 0;
    this.teethCounter = 0;
    this.appointmentsCounter = 0;
    this.showAnimatedCounter();  
  }

  showAnimatedCounter(): void {
    let patientsTarget: number = 1200;
    let teethTarget: number = 3100;
    let appointmentsTarget: number = 2000;
    let speed: number = 50;

    let inc: number = patientsTarget / speed;

    if (this.patientsCounter < patientsTarget) {
      this.patientsCounter += inc;
      this.teethCounter += inc;
      this.appointmentsCounter += inc;
      setTimeout(() => { this.showAnimatedCounter(); }, 100);
    }
    else {
      this.patientsCounter = patientsTarget;
      this.teethCounter = teethTarget;
      this.appointmentsCounter = appointmentsTarget;
    }
}
}
