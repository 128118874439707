import { Component, OnInit, Inject } from '@angular/core';
import { SaveBill } from '../models/bill';
import { BillService} from '../services/bill.service' 
import { Pagination } from '../models/pagination';

@Component({
	selector: 'bill-list',
  templateUrl: './bill-list.component.html',
  styleUrls: ['./bill-list.component.css']
})

export class BillListComponent implements OnInit {

  public bills: SaveBill[];
  showSpinner = true;

  pagination: Pagination = { page: 1, limit: 15, mobile: '', name: '' };
  total = 0;
  page = 1;
  limit = 15;

  constructor(private billService : BillService) {  }


	ngOnInit() {
      this.getBills();
  }

  getBills() {
    this.billService.getSomeBills(this.pagination)
      .subscribe(res => {
        this.bills = res['page']['data'];
        this.total = res['page'].total;
        //this.loading = false;
      },
        error => console.log("Error: ", error),
        () => { this.showSpinner = false }
      );
  }

  goToPrevious(): void {
    this.page--;
    this.pagination.page--;
    this.getBills();
  }

  goToNext(): void {
    this.page++;
    this.pagination.page++;
    this.getBills();
  }

  goToPage(n: number): void {
    this.page = n;
    this.pagination.page = n;
    this.getBills();
  }
}


