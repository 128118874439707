import { Component, OnInit, Inject, Input, SimpleChanges } from '@angular/core';
import { SavePayment } from '../models/payment';
import { PaymentService } from '../services/payment.service';
import { PaymentQuery } from '../models/paymentQuery';

import { AuthService } from '../services/auth.service';

@Component({
	selector: 'payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['/payment-list.component.css']
})

export class PaymentListComponent implements OnInit {

  @Input() billIdModal: any;
  @Input() amChildOfBillForm: any;

  paymentQuery: PaymentQuery = {
    paymentId: 0,
    visitId: 0,
    billId: 0
  }
  showSpinner = true;

  public payments: SavePayment[] = [];

  userRoleValue: number = 100;

  constructor(private paymentService: PaymentService,
              private authService: AuthService) { }

  ngOnInit() {

    if (!this.amChildOfBillForm)
    this.initialize();

  }

  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    if (changes['billIdModal']) {
      this.paymentQuery.billId = this.billIdModal;
      this.initialize();

    }
  }

  initialize() {

    this.userRoleValue = this.authService.getRoleValue();

    this.paymentService.getPayments(this.paymentQuery).subscribe(result => {
      this.payments = result;
    },
      error => console.log(error),
      () => this.showSpinner = false);
  }
}


