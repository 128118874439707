import { Component, NgModule, Input, Output, EventEmitter, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/forkJoin';
import { ToastrService } from 'ngx-toastr';

import { VisitService } from '../services/visit.service';
import { PatientService } from '../services/patient.service';
import { DoctorService } from '../services/doctor.service';
import { ClinicServiceService } from '../services/clinicService.service';
import { VisitClinicServiceService } from '../services/visitClinicService.service';
import { BillClinicServiceService } from '../services/billClinicService.service';


import { Visit, SaveVisit } from '../models/visit';
import { SaveClinicService } from '../models/clinicService';
import { VisitClinicService } from '../models/visitClinicService';
import { BillServiceWithDrProgress } from '../models/billServiceWithDrProgress';
import { SaveDoctor } from '../models/doctor';
import { SavePatient } from '../models/patient';
import { concat } from 'rxjs';


@Component({
  selector: 'visit-form',
  templateUrl: './visit-form.component.html',
  styleUrls: ['./visit-form.component.css']
})


export class VisitFormComponent implements OnInit {

  @Input() patientIdModal: any;
  @Input() visitIdModal: any;
  @Input() billIdModal: any;
  @Input() refreshVisitForm: any;
  @Input() addnewVisitRequest: any;
  @Input() amIChildOfMainView: any;
  @Output() closeVisitForm = new EventEmitter<boolean>();

  visitTime: any;


  today = "";
  showCalender = false;
  doctorWorkingDays: number[] = [];
  formH1Text = "Schedule a New Visit";
  showSpinner = true;

  disableDoctor = true;

  clinicServices: SaveClinicService[] = [];
  visitClinicServices: VisitClinicService[] = [];
  billClinicServices: BillServiceWithDrProgress[] = [];
  doctors: SaveDoctor[] = [];

  newBillClinicService: BillServiceWithDrProgress = {
    billClinicService:
    {
      billId: 0,
      clinicServiceId: 0,
      specialDiscount: 0,
      toothId: 0,
      serviceProgress: 0,
      servicePrice: 0,
      note: ''
    },
    doctorServiceProgress: []
  };


  visitStuts: string[] = ["Reserved", "Canceled", "Confirmed", "Finished", "Postponed", "Ongoing"];
  visitTypes: string[] = ["New", "Follow-Up"];

  

  savePatient: SavePatient = {
    id: 0,
    name: '',
    mobile: '',
    gender: '',
    address: '',
    age: '',
    occupation: '',
    joiningDate: null,
    email: '',
    nationality: '',
    referral: "",
    insuranceCompany: '',
    doctorId: null,
    doctorName: '',
    bills: [],
    visits: []
  };

  visit: Visit = {
    patientId: 0,
    doctorId: 0,
    billId: 0,
    status: "",
    type: "",
    diagnosis: "",
    date: null,
    visitClinicServices: []
  };

  saveVisit: SaveVisit = {
    id: 0,
    patientId: null,
    doctorId: null,
    billId: 0,
    status: "",
    type: "",
    diagnosis: "",
    date: null,
    visitClinicServices: [],
    patient: {
      id: 0,
      name: '',
      mobile: '',
      gender: '',
      address: '',
      age: '',
      occupation: "",
      joiningDate: null,
      email: '',
      nationality: '',
      referral: "",
      insuranceCompany: '',
      doctorId: null,
      doctorName: '',
      bills: [],
      visits: []
    }
  };

  sources: any[];
  tabId: any = 1;
  showText: string = 'Show ';
  disableSubmitButton: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private visitService: VisitService,
    private clinicServiceService: ClinicServiceService,
    private billClinicServiceService: BillClinicServiceService,
    private visitClinicServiceService: VisitClinicServiceService,
    private patientService: PatientService,
    private doctorService: DoctorService,
    private toaster: ToastrService,
    private router: Router) {

    // this route to get the Id from the route or set it to zero to be able 
    // to add new element without "|| 0" I will not be able to add new patient
    // because id will be set to null and the server will feedback with error.
    route.params.subscribe(p => {
      this.saveVisit.id = +p['id'] || 0;
      this.saveVisit.billId = +p['billId'] || 0;
      this.saveVisit.patientId = +p['patientId'] || 0;
    });
  }

  ngOnInit() {

    if (!this.amIChildOfMainView)
      this.initialize();
    else {
      if (this.patientIdModal != null && this.addnewVisitRequest == true) {
        this.saveVisit = {
          id: 0,
          patientId: this.patientIdModal,
          doctorId: null,
          billId: 0,
          status: "Reserved",
          type: "New",
          diagnosis: "",
          date: null,
          visitClinicServices: [],
          patient: {
            id: 0,
            name: '',
            mobile: '',
            gender: '',
            address: '',
            age: '',
            occupation: "",
            joiningDate: null,
            email: '',
            nationality: '',
            referral: "",
            insuranceCompany: '',
            doctorId: null,
            doctorName: '',
            bills: [],
            visits: []
          }
        };

      }
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    // only run when property "data" changed
    if (this.addnewVisitRequest == true) {
      this.saveVisit.patientId = this.patientIdModal;
      this.saveVisit.id = null;
      this.initialize();

    }

    if (this.refreshVisitForm == true) {
      console.log("now only editing visit");
      this.saveVisit.patientId = null;
      this.saveVisit.id = this.visitIdModal;
      this.saveVisit.billId = this.billIdModal;
      this.initialize();
    }
  }


  initialize() {
    // to prevent adding or edit visit to a day before today. 
    this.today = new Date().toISOString().slice(0, 16);

    this.sources = [
      this.clinicServiceService.getClinicServices(),
      this.doctorService.getDoctors(),
    ];

    if (this.saveVisit.id) {
      
      this.formH1Text = "Edit Visit Schedule";
      //Must: BillID should be recieved with the visitId to be able to list the bill services at the same time with the visit details 
      // or I will have to wait to recieve the Visit details first then call the BillClinicService
      this.sources.push(this.billClinicServiceService.getBillClinicServicesByBillId(this.saveVisit.billId));
      this.sources.push(this.visitService.getVisit(this.saveVisit.id));
      this.sources.push(this.visitClinicServiceService.getVisitClinicServicesByVisitId(this.saveVisit.id));
    }
    else {
      this.disableDoctor = false; 
      this.formH1Text = "Schedule a New Visit";
      this.sources.push(this.patientService.getPatient(this.saveVisit.patientId));
      this.saveVisit.status = "Reserved";
      this.saveVisit.type = "New";
      this.saveVisit.date = null;
    }

    Observable.forkJoin(this.sources).subscribe(data => {
      this.setClinciServices(data[0]);
      this.setDoctors(data[1]);

      if (this.saveVisit.id) {
        this.setBillClinciServices(data[2]);
        this.setSaveVisit(data[3]);
        this.seVisitClinciServices(data[4]);
      }
      else {
        this.setVisitPatient(data[2]);
      }

    },
      error => console.log(error),
      () => this.showSpinner = false);
  }

  private setClinciServices(clinicServices: any) {
    this.clinicServices = clinicServices;
  }


  private setDoctors(doctors: any) {
    this.doctors = doctors;
  }

  private setVisitPatient(patient: any) {
    this.saveVisit.patient = patient;
  }

  private setBillClinciServices(billClinicServices: any) {
    this.billClinicServices = billClinicServices;
  }

  private seVisitClinciServices(visitClinicServices: any) {
    this.visitClinicServices = visitClinicServices;
  }



  private setVisit(SaveVisit: any) {
    this.visit.doctorId = SaveVisit.doctorId;
    this.visit.patientId = SaveVisit.patientId;
    this.visit.billId = SaveVisit.billId;
    this.visit.status = SaveVisit.status;
    this.visit.type = SaveVisit.type;
    this.visit.date = SaveVisit.date;
    this.visit.visitClinicServices = SaveVisit.clinicServices;
  }

  private setSaveVisit(SaveVisit: any) {
    this.saveVisit.id = SaveVisit.id;
    this.saveVisit.doctorId = SaveVisit.doctorId;
    this.setDoctorWorkingDays(SaveVisit.doctorId);
    this.saveVisit.patientId = SaveVisit.patientId;
    this.saveVisit.billId = SaveVisit.billId;
    this.saveVisit.status = SaveVisit.status;
    this.saveVisit.type = SaveVisit.type;
    this.saveVisit.date = SaveVisit.date;
    this.setVisitTime(this.saveVisit.date);
    this.saveVisit.visitClinicServices = SaveVisit.clinicServices;
    this.saveVisit.patient = SaveVisit.patient;
  }
    setVisitTime(date: Date) {
      var date = new Date(date);
      var hours = date.getHours().toString();
      var minutes = date.getMinutes().toString();
      this.visitTime = hours.concat(":", minutes);
    }

  setDayPicked(dayPicked: Date) {

    this.saveVisit.date = new Date(dayPicked);

    if (dayPicked.getTimezoneOffset() < 0)
      this.saveVisit.date.setTime(this.saveVisit.date.getTime() - dayPicked.getTimezoneOffset() * 60000);

    if (dayPicked.getTimezoneOffset() > 0)
      this.saveVisit.date.setTime(this.saveVisit.date.getTime() + dayPicked.getTimezoneOffset() * 60000);
  }

  timePicked() {
    //I have to set the visit date as a type script date to be able to call .getTime() function 
    this.saveVisit.date = new Date(this.saveVisit.date);

    var input;
    input = document.getElementById("time");
    var inputAsString : string  = input.value.toString();
    var splittedInput = inputAsString.split(":");

    if (this.saveVisit.date.getTimezoneOffset() < 0)
      this.saveVisit.date.setTime(this.saveVisit.date.getTime() - this.saveVisit.date.getTimezoneOffset() * 60000);

    if (this.saveVisit.date.getTimezoneOffset() > 0)
      this.saveVisit.date.setTime(this.saveVisit.date.getTime() + this.saveVisit.date.getTimezoneOffset() * 60000);

    this.saveVisit.date.setUTCHours(parseInt(splittedInput[0]));
    this.saveVisit.date.setUTCMinutes(parseInt(splittedInput[1]));
  }



  submit() {
    // Check if its existing visit so we are updating it
    this.disableSubmitButton = true;
    if (this.saveVisit.id) {
      this.visitService.updateVisit(this.saveVisit).subscribe(x => {
        this.toaster.success('Edit Visit!', 'Update successfully.');
        this.router.navigateByUrl('/mainView');
 
      }, error => {
        this.toaster.error('Edit Visit!', error.error);
      });
    }
    // Check if its new visit so we are adding it
    else {

      this.setVisit(this.saveVisit);
      this.visitService.createVisit(this.visit).subscribe(x => {
        this.toaster.success('Add Visit!', 'Add successfully.');


      }, error => {
        console.log("Error");
        this.toaster.error('Add Visit!', error.error);
      });



    }


  }

  


  submitChildOfMainView() {
    this.disableSubmitButton = true;
    // Check if its existing visit so we are updating it
    if (this.saveVisit.id) {
      this.visitService.updateVisit(this.saveVisit).subscribe(x => {
        this.toaster.success('Edit Visit!', 'Update successfully.');
        this.closeVisitForm.emit(true);
      }, error => {
        this.toaster.error('Edit Visit!', error.error);
      });
    }
    // Check if its new visit so we are adding it
    else {
      console.log(this.saveVisit);
      this.setVisit(this.saveVisit);
      this.visitService.createVisit(this.visit).subscribe(x => {
        this.toaster.success('Add Visit!', 'Add successfully.');
        this.closeVisitForm.emit(true);
      }, error => {
        this.toaster.error('Add Visit!', error.error);
      });

    }
  }

  onClinicServiceToggle(serviceId: any, $event: any) {
    if ($event.target.checked)
      this.saveVisit.visitClinicServices.push(serviceId);
    else {
      var index = this.visit.visitClinicServices.indexOf(serviceId);
      this.saveVisit.visitClinicServices.splice(index, 1);
    }
  }

  setDoctorWorkingDays(doctorId) {

    //reset visit date
    this.saveVisit.date = null;
    //Reset Doctor Working days
    this.doctorWorkingDays = []

    for (let i = 0; i < this.doctors.length; i++) {
      if (this.doctors[i].id == doctorId)
        this.doctorWorkingDays = this.doctors[i].workingDays;
    }
    this.toggleShowCalender();
  }


  navTabToggle(tabId: any) {
    this.tabId = tabId;
  }

  toggleShowCalender() {
    this.showCalender = !this.showCalender;
    if (this.showText == 'Show ') { this.showText = 'Hide ' }
    else { this.showText = 'Show ' }
  }


}
