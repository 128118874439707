import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'printForm-header',
  templateUrl: './printForm-header.component.html',
  styleUrls: ['./printForm-header.component.css']
})

export class printFormHeaderComponent implements OnInit {

  @Input() patientName;

  date = new Date();
  constructor() { }

	ngOnInit() {
  }
}


