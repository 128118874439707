import { Component, OnInit, Inject, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { splitAtColon } from '@angular/compiler/src/util';

import { SaveVisit } from '../models/visit';
import { VisitService } from '../services/visit.service';
import { AuthService } from '../services/auth.service';
import { FilterDates } from '../models/filterDates';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { skip } from 'rxjs/operators';
import { BillServiceWithDrProgress } from '../models/billServiceWithDrProgress';
import { BillClinicServiceService } from '../services/billClinicService.service';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';



@Component({
	selector: 'myVisits-list',
  templateUrl: './myVisits-list.component.html',
  styleUrls: ['./myVisits-list.component.css']
})

export class MyVisitsListComponent implements OnInit {

  @Input() refreshtoggleMyVisits: any;
  @Output() editVisitInMainViewEmitter = new EventEmitter<number[]>();

  public visits: SaveVisit[] = [];
  isServiceModalActivated: boolean = false;
  visitId: number;
  billId: number;

  visitsDate = new Date();
  visitsCount: number;
  filterDates: FilterDates = {
    id:0,
    startDateDay: 0,
    startDateMonth: 0,
    startDateYear: 0,
    endDateDay: 0,
    endDateMonth: 0,
    endDateYear: 0,
  };

  diagnosisClinicService: BillServiceWithDrProgress = {
    billClinicService:
    {
      billId: 0,
      clinicServiceId: 0,
      specialDiscount: 0,
      toothId: 0,
      serviceProgress: 0,
      servicePrice: 0,
      note: ''
    },
    doctorServiceProgress: []
  }


  showSpinner = true;

  constructor(private visitService: VisitService,
              private router: Router,
              private authService: AuthService,
              private toaster: ToastrService,
              private billClinicServiceService: BillClinicServiceService) { }


  ngOnInit() {     
  }

  ngOnChanges(changes: SimpleChanges) {
    this.showSpinner = true;
    // only run when property "data" changed
    if (changes['refreshtoggleMyVisits']) {
      this.initialize();
    }
  }

  initialize() {
    this.filterDates = this.visitService.getVisitsDate();

    this.visitsDate = new Date(this.filterDates.startDateYear, this.filterDates.startDateMonth - 1, this.filterDates.startDateDay);

    if (this.authService.getRoleValue() == 3) {
      this.visitService.getMyVisits().subscribe(result => {
        this.visits = result;
        this.visitsCount = this.visits.length;
        
      },
        error => console.log(error),
        () => {
          this.showSpinner = false;
          this.checkFinished();    
        });
    }
    else {
      this.visitService.getOneDayVisits().subscribe(result => {
        this.visits = result;
        this.visitsCount = this.visits.length;
        this.checkFinished()
      },
        error => console.log(error),
        () => {
          this.showSpinner = false;
          this.checkFinished();
        });
    }

    
  }

  isOnRole(roleList) {
    return this.authService.roleMatch(roleList);
  }


  editVisitInMainView(visitId : number, billId: number) {
    this.editVisitInMainViewEmitter.emit([visitId, billId]);
  }

  activateVisitPaymentModal() {
    this.isServiceModalActivated = true;
  }


  filter() {
  var input, filter, table, tr, td, i, txtValue;
  input = document.getElementById("myInput");
  filter = input.value.toUpperCase();
  table = document.getElementById("myTable");
  tr = table.getElementsByTagName("tr");
  for (i = 0; i < tr.length; i++) {
    td = tr[i].getElementsByTagName("td")[1];
    if (td) {
      txtValue = td.textContent || td.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        tr[i].style.display = "";
      } else {
        tr[i].style.display = "none";
      }
    }
  }
  }

  markAsFinished(visit: SaveVisit) {  
    if ( this.checkEqualToday(visit.date)) {
      visit.status = "Finished";
      this.visitService.updateVisit(visit).subscribe(x => {
        this.toaster.success('Edit Visit!', 'Update successfully.');
        this.router.navigateByUrl('/mainView');
      }, error => {
        this.toaster.error('Edit Visit!', error.error);
      });
    }  
  }

  checkFinished() {
    this.visits.forEach(function (visit) {
      var td = document.getElementsByClassName((visit.id).toString())[0];
      if (td != null) {
        if (visit.status == "Finished") {
          td.setAttribute('style', 'background-color:lightgray');
          var anchor = document.getElementsByClassName('anchor' + (visit.id).toString())[0];
          anchor.classList.add('disable-anchor');
        }
      }
    });
  }

  ngAfterViewChecked() {
    this.checkFinished();
    this.markOngoing();
  }

  startVisit(visit: SaveVisit) {
    if (this.checkEqualToday(visit.date)) {
      var result: SaveVisit = {
        id: visit.id,
        patientId: visit.patientId,
        doctorId: visit.doctorId,
        billId: visit.billId,
        status: "Ongoing",
        type: visit.type,
        diagnosis: visit.diagnosis,
        date: visit.date,
        visitClinicServices: visit.visitClinicServices,
        patient : visit.patient
      };
      this.visitService.updateVisit(result).subscribe(x => {
        visit.status = "Ongoing";
        this.toaster.success('Edit Visit!', 'Update successfully.');

        // the following condition to add a fees price to visit in case its new visit. 
        // if (visit.type == "New") {
       //   this.createDiagnosisService(visit);
       // }

        this.router.navigateByUrl('/mainView');
      }, error => {
        this.toaster.error('Edit Visit!', error.error);
      });
    }  
  }

  markOngoing() {
    this.visits.forEach(function (visit) {
      var td = document.getElementsByClassName((visit.id).toString())[0];
      if (td != null) {
        if (visit.status == "Ongoing") {
          td.setAttribute('style', 'background-color:lightgreen');
        }
      }
    });
  }

  checkEqualToday(visitDate: any): boolean {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    var nowDate = new Date(visitDate);
    nowDate.setHours(0, 0, 0, 0);
    var sameDay = (nowDate.getDate() === today.getDate());
    var sameMonth = (nowDate.getMonth() === today.getMonth());
    var sameYear = (nowDate.getFullYear() === today.getFullYear());
    return sameDay && sameMonth && sameYear;
  }

  createDiagnosisService(visit: SaveVisit) {
    this.diagnosisClinicService.billClinicService.billId = toInteger(visit.billId);

    this.diagnosisClinicService.billClinicService.clinicServiceId = 1;
    this.diagnosisClinicService.billClinicService.toothId = 0;

    this.billClinicServiceService.createBillClinicService(this.diagnosisClinicService.billClinicService).subscribe(x => {
      this.toaster.success('Add Diagnosis Service!', 'Add successfully.');

    }, error => {
      this.toaster.error('Diagnosis Service!', error.error);
    });
  }
}
