import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SaveVisitPurpose, VisitPurpose } from '../models/visitPurpose';

@Injectable()
export class VisitPurposeService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getVisitPurpose(id: number) {
    return this.http.get<SaveVisitPurpose>(this.baseUrl + 'api/visitPurpose' + '/' + id);
	}

  createVisitPurpose(visitPurpose: VisitPurpose) {
    return this.http.post<VisitPurpose>(this.baseUrl + 'api/visitPurpose', visitPurpose);
	}

  updateVisitPurpose(saveVisitPurpose: SaveVisitPurpose) {
    return this.http.put<SaveVisitPurpose>(this.baseUrl + 'api/visitPurpose' , saveVisitPurpose);
  }

  //delete no added Yet.

}
