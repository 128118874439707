import { Component, NgModule, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/forkJoin';
import { ToastrService } from 'ngx-toastr';
import { MOMENT } from 'angular-calendar';
import { retry } from 'rxjs/operators';

import { FrontEndService } from '../services/fronEnd.service';
import { VisitPurposeService } from '../services/visitPurpose.service';


import { VisitPurpose, SaveVisitPurpose } from '../models/visitPurpose';


@Component({
  selector: 'visitPurpose-form',
  templateUrl: './visitPurpose-form.component.html',
  styleUrls: ['./visitPurpose-form.component.css']
})
  

export class VisitPurposeFormComponent implements OnInit {

  @Input() visitId: number;
  today = "";
  formH1Text = "New Visit Purpose";

  isVisitPurposeExist: boolean = false;

  visitPurpose: VisitPurpose = {
    visitId: 0,
    reason: '',
    hestOfCheifCompl: '',
  lastVisitDate: null,
    procedure: '',
    brushFloss: '',
    habits: '',
    seriousIllOpr: false, 
  seriousIllOprDescrp: '',
    familyHistory: '',
    bloodTrans: false,
    bloodTransDate: null,
    currentMedication: '',
    allergies: false,
    allergiesDescrp: '',
    pregnancy: false,
    nursing: false,
    extraOralExam: '',
  }

  saveVisitPurpose: SaveVisitPurpose = {
    id : 0,
    visitId: 0,
    reason: '',
    hestOfCheifCompl: '',
    lastVisitDate: null,
    procedure: '',
    brushFloss: '',
    habits: '',
    seriousIllOpr: false,
    seriousIllOprDescrp: '',
    familyHistory: '',
    bloodTrans: false,
    bloodTransDate: null,
    currentMedication: '',
    allergies: false,
    allergiesDescrp: '',
    pregnancy: false,
    nursing: false,
    extraOralExam: '',
  }


  sources: any[];
  tabId: any = 1;
  showText: string = 'Show ';
  calenderLastVisitDate: any;
  calenderbloodTransDate: any;
  isLastVisitDatePicked: boolean = false;
  isbloodTransDatePicked: boolean = false;

    

  constructor(
    private route: ActivatedRoute,
    private visitPurposeService: VisitPurposeService,
    private toaster: ToastrService,
    private router: Router,
    private frontEndService: FrontEndService) {

    // this route to get the Id from the route or set it to zero to be able 
    // to add new element without "|| 0" I will not be able to add new patient
    // because id will be set to null and the server will feedback with error.
   
  }

  ngOnInit() {
    this.visitPurpose.visitId = this.visitId;
    this.visitPurposeService.getVisitPurpose(this.visitId).subscribe(x => {
      if (x != null) {
        this.isVisitPurposeExist = true;
        this.formH1Text = "Edit Visit Purpose";
        this.setSaveVisitPurpose(x);
      }
    });
    
  }

  private setVisitPurpose(visitPurpose: any) {
    this.visitPurpose.visitId = this.visitId;
    this.visitPurpose.reason = visitPurpose.reason; 
    this.visitPurpose.hestOfCheifCompl = visitPurpose.hestOfCheifCompl; 
    this.visitPurpose.lastVisitDate = visitPurpose.lastVisitDate;
    this.visitPurpose.procedure = visitPurpose.procedure;
    this.visitPurpose.brushFloss = visitPurpose.brushFloss;
    this.visitPurpose.habits = visitPurpose.habits;
    this.visitPurpose.seriousIllOpr = visitPurpose.seriousIllOpr;
    this.visitPurpose.seriousIllOprDescrp = visitPurpose.seriousIllOprDescrp;
    this.visitPurpose.familyHistory = visitPurpose.familyHistory;
    this.visitPurpose.bloodTrans = visitPurpose.bloodTrans;
    this.visitPurpose.bloodTransDate = visitPurpose.bloodTransDate;
    this.visitPurpose.currentMedication = visitPurpose.currentMedication;
    this.visitPurpose.allergies = visitPurpose.allergies;
    this.visitPurpose.allergiesDescrp = visitPurpose.allergiesDescrp;
    this.visitPurpose.pregnancy = visitPurpose.pregnancy;
    this.visitPurpose.nursing = visitPurpose.nursing;
    this.visitPurpose.extraOralExam = visitPurpose.extraOralExam;
  }

  private setSaveVisitPurpose(visitPurpose: any) {
    this.saveVisitPurpose.id = visitPurpose.id;
    this.saveVisitPurpose.visitId = this.visitId;
    this.saveVisitPurpose.reason = visitPurpose.reason;
    this.saveVisitPurpose.hestOfCheifCompl = visitPurpose.hestOfCheifCompl;
    this.calenderLastVisitDate = this.frontEndService.formatBkEndDate(visitPurpose.lastVisitDate);
    this.saveVisitPurpose.lastVisitDate = visitPurpose.lastVisitDate;
    this.saveVisitPurpose.procedure = visitPurpose.procedure;
    this.saveVisitPurpose.brushFloss = visitPurpose.brushFloss;
    this.saveVisitPurpose.habits = visitPurpose.habits;
    this.saveVisitPurpose.seriousIllOpr = visitPurpose.seriousIllOpr;
    this.saveVisitPurpose.seriousIllOprDescrp = visitPurpose.seriousIllOprDescrp;
    this.saveVisitPurpose.familyHistory = visitPurpose.familyHistory;
    this.saveVisitPurpose.bloodTrans = visitPurpose.bloodTrans;
    this.calenderbloodTransDate = this.frontEndService.formatBkEndDate(visitPurpose.bloodTransDate);
    this.saveVisitPurpose.bloodTransDate = visitPurpose.bloodTransDate;
    this.saveVisitPurpose.currentMedication = visitPurpose.currentMedication;
    this.saveVisitPurpose.allergies = visitPurpose.allergies;
    this.saveVisitPurpose.allergiesDescrp = visitPurpose.allergiesDescrp;
    this.saveVisitPurpose.pregnancy = visitPurpose.pregnancy;
    this.saveVisitPurpose.nursing = visitPurpose.nursing;
    this.saveVisitPurpose.extraOralExam = visitPurpose.extraOralExam;
  }

  lastVisitDatePicked() {
    var input;
    input = document.getElementById("lastVisitDate");
    this.saveVisitPurpose.lastVisitDate = input.value;
    this.isLastVisitDatePicked = true;
  }

  bloodTransDatePicked() {
    var input;
    input = document.getElementById("bloodTransDate");
    this.saveVisitPurpose.bloodTransDate = input.value;
    this.isbloodTransDatePicked = true;
  }

  submit() {
    // Check if its existing visit purpose so we are updating it
    if (this.isVisitPurposeExist) {
      this.visitPurposeService.updateVisitPurpose(this.saveVisitPurpose).subscribe(x => {
        this.toaster.success('Edit Visit Purpose!', 'Update successfully.');
      }, error => {
        this.toaster.error('Edit Visit Purpose!', error.error);
      });
    }
    // Check if its new visit prupose so we are adding it
    else {
      this.setVisitPurpose(this.saveVisitPurpose);
      this.visitPurposeService.createVisitPurpose(this.visitPurpose).subscribe(x => {
        this.toaster.success('Add Visit Purpose!', 'Add successfully.');
        //this.router.navigateByUrl('/visits');
      }, error => {
          this.toaster.error('Add Visit Purpose!', error.error);
      });
    }

  }
}
