import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LabRequest , SaveLabRequest } from '../models/labRequest';
import { Pagination } from '../models/pagination';

@Injectable()
export class LabServiceService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getLabServices() {
   return this.http.get<SaveLabRequest[]>(this.baseUrl + 'api/labServices');
  }

  getSomeLabServices(pagination: Pagination) {
    console.log(this.http.get<SaveLabRequest[]>(this.baseUrl + 'api/labServices' + '/' + '?' + this.toQueryString(pagination)));
    return this.http.get<SaveLabRequest[]>(this.baseUrl + 'api/labServices' + '/' + '?' + this.toQueryString(pagination));
  }

  toQueryString(obj: any) {
    var parts = [];
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined)
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
    }
    return parts.join('&');
  }

  getLabService(id: any) {
    return this.http.get<SaveLabRequest>(this.baseUrl + 'api/labServices' + '/' + 'labService' + '/' + id);
  }

  getLabServiceByLab(id: any) {
    console.log("Done");
    return this.http.get<SaveLabRequest[]>(this.baseUrl + 'api/labServices' + '/' + 'lab' + '/' + id);
  }

  createLabService(labRequest: LabRequest) {
    return this.http.post<LabRequest>(this.baseUrl + 'api/labServices', labRequest);
	}

  updateLabService(saveLabRequest: SaveLabRequest) {
    return this.http.put<SaveLabRequest>(this.baseUrl + 'api/labServices' + '/' + saveLabRequest.id, saveLabRequest);
	}
}
