import { Component, OnInit, Inject } from '@angular/core';
import { SaveMedicine } from '../models/medicine';
import { MedicineService } from '../services/medicine.service';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { UserManager } from 'oidc-client';
import { Observable } from 'rxjs';

@Component({
  selector: 'medicine-list',
  templateUrl: './medicine-list.component.html',
  styleUrls: ['./medicine-list.component.css']
})

export class MedicineListComponent implements OnInit {

  public medicines: SaveMedicine[];
  showSpinner = true;

  constructor(private medicineService: MedicineService,
    private authorizeService: AuthorizeService) { }


	ngOnInit() {
      this.medicineService.getMedicines().subscribe(result => {
        this.medicines = result;
      },
        error => console.log(error),
        () => this.showSpinner = false);
  }
}


