import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { BookingService } from '../services/booking.service';

import { Booking , SaveBooking } from '../models/booking';

@Component({
	selector: 'booking-list',
  templateUrl: './booking-list.component.html',
  styleUrls: ['./booking-list.component.css']
})


export class BookingListComponent implements OnInit {

  bookings: SaveBooking[] = [];

  booking: SaveBooking = {
    id:0,
    patientName: '',
    mobile: '',
    date: new Date(),
    isHandeled: false,
    isNewPatient: true
  };

  showSpinner = false;
   

	constructor(
		private route: ActivatedRoute,
      private bookingService: BookingService,
      private toaster: ToastrService,
      private router: Router) {

	}

  ngOnInit() {

    this.showSpinner = true;

    this.bookingService.getBookings().subscribe(x => {
      this.bookings = x;
      this.showSpinner = false;
    });
  }

  markAsFinished(booking: SaveBooking) {
    booking.isHandeled = true;
    this.bookingService.updateBookingStatus(booking).subscribe(x => {
      this.toaster.success('Handle Booking!', 'Update successfully.');
      this.router.navigateByUrl('/mainView');
    }, error => {
      this.toaster.error('Handle Booking!', error.error);
    });  
  }

  checkFinished() {
    this.bookings.forEach(function (booking) {
      var td = document.getElementsByClassName((booking.id).toString())[0];
      if (td != null) {
        if (booking.isHandeled == true) {
          td.setAttribute('style', 'background-color:lightgray');
          var anchor = document.getElementsByClassName('anchor' + (booking.id).toString())[0];
          anchor.classList.add('disable-anchor');
        }
      }
    });
  }

  ngAfterViewChecked() {
    this.checkFinished();
  }

}
