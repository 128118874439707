import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VisitClinicService } from '../models/visitClinicService';

@Injectable()
export class VisitClinicServiceService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getVisitClinicServices() {
    return this.http.get<VisitClinicService[]>(this.baseUrl + 'api/visitClinicServices');
	}

  getVisitClinicServicesByVisitId(visitId: number) {
    return this.http.get<VisitClinicService>(this.baseUrl + 'api/visitClinicServices' + '/' + visitId);
	}

  addVisitClinicService(visitClinicService: VisitClinicService) {
    return this.http.post<VisitClinicService>(this.baseUrl + 'api/visitClinicServices', visitClinicService);
	}
}
