import { Component, OnInit, Inject } from '@angular/core';
import { SaveDoctor } from '../models/doctor';
import { DoctorService} from '../services/doctor.service' 
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
	selector: 'doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.css']
})

export class DoctorListComponent implements OnInit {

  navigationSubscription;
  showSpinner = true;
  public doctors: SaveDoctor[];

  constructor(private doctorService: DoctorService, private route : ActivatedRoute) {
    
    //route.params.subscribe(val => this.ngOnInit());
  }


  ngOnInit() {
    //this.router.navigate(['/doctors']);
      this.doctorService.getDoctors().subscribe(result => {
        this.doctors = result;
      },
        error => console.log(error),
        () => this.showSpinner = false);
	}
}


