import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Component({
  selector: 'login-menu',
  templateUrl: './login-menu.component.html',
  styleUrls: ['./login-menu.component.css']
})
export class LoginMenuComponent implements OnInit {

  userDetails: any = {
    userName: '',
    title: '',
    email: ''
  };

  constructor(private router:Router, private authService : AuthService) { }

  ngOnInit() {
    this.authService.getUserProfile().subscribe(
      result => {
        this.userDetails = result;
      },
      err => {
        console.log(err);
      }
    );
  }

  logout() {
    localStorage.removeItem('token');
    //If already on the home page reload the home page.
    if (this.router.url == '/')
      window.location.reload();
    else
      this.router.navigateByUrl('');
  }

}
