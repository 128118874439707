import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Visit, SaveVisit } from '../models/visit';
import { FilterDates } from '../models/filterDates';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class VisitService {

  filterDates: FilterDates = {
    id:0,
    startDateDay: 0,
    startDateMonth: 0,
    startDateYear: 0,
    endDateDay: 0,
    endDateMonth: 0,
    endDateYear: 0,
  };

  constructor(private http: HttpClient,
    private authService: AuthService,
    private router: Router,
    @Inject('BASE_URL') private baseUrl: string) { }

  getVisitAccess(id: any) {
    return this.http.get<SaveVisit[]>(this.baseUrl + 'api/visits/getAccess/' + id);
  }


  getVisits() {
   return this.http.get<SaveVisit[]>(this.baseUrl + 'api/visits');
  }

  getMyVisits() { // get logged-in doctor's visits for one chosen day
    return this.http.get<SaveVisit[]>(this.baseUrl + 'api/visits/myVisits' + '/' + '?' + this.toQueryString(this.filterDates));
  }

  getOneDayVisits() { // get all clinic visits for one chosen day 
    return this.http.get<SaveVisit[]>(this.baseUrl + 'api/visits/thisDayVisits' + '/' + '?' + this.toQueryString(this.filterDates));
  }

  getDoctorMonthlyVisits() { //get logged-in doctor's visits for a chosen month to be displayed @ Calender
    console.log(this.filterDates);
    return this.http.get<SaveVisit[]>(this.baseUrl + 'api/visits/doctorMonthlyVisits' + '/' + '?' + this.toQueryString(this.filterDates) );
  }

  getMonthlyVisits() { //get all clinic visits for a chosen month to be displayed @ Calender
    return this.http.get<SaveVisit[]>(this.baseUrl + 'api/visits/clinicMonthlyVisits' + '/' + '?' + this.toQueryString(this.filterDates));
  }

  toQueryString(obj: any) {
    var parts = [];
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined)
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
    }
    return parts.join('&');
  }

  getVisit(id: any) {
    return this.http.get<SaveVisit>(this.baseUrl + 'api/visits' + '/' + id);
	}

  createVisit(visit: Visit) {
    return this.http.post<Visit>(this.baseUrl + 'api/visits', visit);
    
	}

  updateVisit(saveVisit: SaveVisit) {
    return this.http.put<SaveVisit>(this.baseUrl + 'api/visits' + '/' + saveVisit.id, saveVisit);
  }

  setVisitsDate(date: FilterDates) {
     this.filterDates = date;
  }

  getVisitsDate():FilterDates {
    return this.filterDates;
  }

  goToTodayVisits() {
    var date = new Date();
    this.filterDates.startDateDay = date.getUTCDate() ;
    this.filterDates.startDateMonth = date.getUTCMonth() + 1;
    this.filterDates.startDateYear = date.getFullYear();


    if (this.authService.getRoleValue() == 3) {
      if (this.router.url === '/visits/myVisits') {
        this.router.navigateByUrl('/calender');
      }     
      else
      this.router.navigateByUrl('/visits/myVisits');
    }
    else {
      if (this.router.url === '/visits/thisDayVisits') {
        this.router.navigateByUrl('/calender');
      }
      else
      this.router.navigateByUrl('/visits/thisDayVisits');
    }
  }

}
