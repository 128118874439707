import { Component, OnInit, Inject } from '@angular/core';
import { SaveDoctorSalary } from '../models/doctorSalary';
import { DoctorSalaryService } from '../services/doctorSalary.service';
import { UserManager } from 'oidc-client';
import { Observable } from 'rxjs';

@Component({
  selector: 'doctorSalary-list',
  templateUrl: './doctorSalary-list.component.html',
  styleUrls: ['./doctorSalary-list.component.css']
})

export class DoctorSalaryListComponent implements OnInit {

  public doctorSalaries: SaveDoctorSalary[];
  showSpinner = true;

  constructor(private doctorSalaryService: DoctorSalaryService) { }


	ngOnInit() {
      this.doctorSalaryService.getDoctorSalaries().subscribe(result => {
        this.doctorSalaries = result;
      },
        error => console.log(error),
        () => this.showSpinner = false);
  }
}


