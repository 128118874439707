import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { BookingService } from '../services/booking.service';

import { Booking } from '../models/booking';

@Component({
	selector: 'booking-form',
  templateUrl: './booking-form.component.html',
  styleUrls: ['./booking-form.component.css']
})


export class BookingFormComponent implements OnInit {

  booking: Booking = {
    patientName: '',
    mobile: '',
    date: new Date(),
    isHandeled: false,
    isNewPatient: true
  };

  selectedDate = new Date();

  //validation flags
  isNameValid = true;
  isMobileValid = true;
  isDateValid = true;
  nameExpressiong = new RegExp("^([a-zA-Z ]*$)");// only charachters
  mobileExpressiong = new RegExp("^([+]{0,1})([0-9]{11})$"); // allowing 0 or 1 + and 11 number 

  showSpinner = false;
  isDateChanged = false;
   

	constructor(
		private route: ActivatedRoute,
      private bookingService: BookingService,
      private toaster: ToastrService,
      private router: Router) {

	}

	ngOnInit() {
  }

  changeDate(date) {
    this.isDateChanged = true;
    this.selectedDate = new Date(this.booking.date);
  }

  validateBooking() {

    this.isNameValid = true;
    this.isMobileValid = true;
    this.isDateValid = true;

    //check that name is entered and has no number in it
    if (this.booking.patientName == '' || !this.nameExpressiong.test(this.booking.patientName))
      this.isNameValid = false;

    //check that phone is entered and han no characters
    if (this.booking.mobile == '' || !this.mobileExpressiong.test(this.booking.mobile))
      this.isMobileValid = false;

    //chech that dat is entered and is not < than today
    //if (this.booking.date == null || )
    if (this.isDateChanged == false)
      this.isDateValid = false;
    else
      if (this.compareDates(this.selectedDate))
        this.isDateValid = false;

    if (this.isNameValid && this.isMobileValid && this.isDateValid)
      this.submit();
  }

  private compareDates(date: Date) {
    //to check that the selected day is not before today 
    var today = new Date();
    if (today > date)
      return true;
    else
      return false;
  }
	
	submit() {
    this.showSpinner = true;

    this.bookingService.createBooking(this.booking).subscribe(x => {
      this.toaster.success('Our team will contact you for confirmation!');
      this.showSpinner = false;
      this.router.navigateByUrl('/home');
    }, error => {
        this.toaster.error( error.error);
        this.showSpinner = false;
    });   
      
      
  }

}
