import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

import { CEOService } from '../services/ceo.service';
import { CEO, SaveCEO } from '../models/ceo';
import { error } from '@angular/compiler/src/util';

@Component({
	selector: 'tooth-chart',
  templateUrl: './tooth-chart.component.html',
  styleUrls: ['./tooth-chart.component.less']
})


export class ToothChartComponent implements OnInit {


  @Output() toothPicked = new EventEmitter<string>();

  showChart: string = "adult";

	constructor() {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
	}

	ngOnInit() {
	}

  tootClicked(chartId: string) {
    this.toothPicked.emit(chartId)
  }
}
