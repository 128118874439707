import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/forkJoin';
import { ToastrService } from 'ngx-toastr';

import { BillService } from '../services/bill.service';
import { PatientService } from '../services/patient.service';
import { DoctorService } from '../services/doctor.service';
import { VisitService } from '../services/visit.service';
import { MedicalProfileService } from '../services/medicalProfile.service';
import { BillClinicServiceService } from '../services/billClinicService.service';
import { ClinicServiceService } from '../services/clinicService.service';


import { AuthService } from '../services/auth.service';

import { Visit, SaveVisit } from '../models/visit';
import { SaveDoctor } from '../models/doctor';
import { Patient, SavePatient } from '../models/patient';
import { SaveBill } from '../models/bill';
import { BillServiceWithDrProgress } from '../models/billServiceWithDrProgress';
import { MedicalProfile, SaveMedicalProfile } from '../models/medicalProfile';
import { VisitClinicService } from '../models/visitClinicService';
import { VisitClinicServiceService } from '../services/visitClinicService.service';
import { ClinicService } from '../models/clinicService';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'visit-view',
  templateUrl: './visit-view.component.html',
  styleUrls: ['./visit-view.component.css']
})


export class VisitViewComponent implements OnInit {

  refreshVisitServiceToggle: boolean;
  sources: any[];
  tabId: any = 1;
  medicalProfileId: number;
  isMedicalProfileExists: boolean = false;
  isServiceModalActivated: boolean = false;
  userRoleValue: number = 100;


  visit: Visit = {
    patientId: 0,
    doctorId: 0,
    billId: 0,
    status: "",
    type: "",
    diagnosis: "",
    date: null,
    visitClinicServices: []
  };

  saveVisit: SaveVisit = {
    id: 0,
    patientId: 0,
    doctorId: 0,
    billId: 0,
    status: "",
    type: "",
    diagnosis: "",
    date: null,
    visitClinicServices: [],
    patient: {
      id: 0,
      name: '',
      mobile: '',
      gender: '',
      address: '',
      age: '',
      occupation: "",
      joiningDate: null,
      email: '',
      nationality: '',
      referral: "",
      insuranceCompany: '',
      doctorId: null,
      doctorName: '',
      bills: [],
      visits: []
    }
  };

  bills: SaveBill[];

  openedBillId: number = 0;
  openedBill: SaveBill;

  billClinicServices: BillServiceWithDrProgress[]= [];

  doctors: SaveDoctor[];
  visits: SaveVisit[];

  savePatient: SavePatient = {
    id: 0,
    name: '',
    mobile: '',
    gender: '',
    address: '',
    age: '',
    occupation: '',
    joiningDate: null,
    email: '',
    nationality: '',
    referral: "",
    insuranceCompany: '',
    doctorId: 0,
    doctorName:'',
    bills: [],
    visits: []
  };

  patient: Patient = {
    name: '',
    mobile: '',
    gender: '',
    address: '',
    age: '',
    occupation: "",
    email: "",
    nationality: "",
    referral: "",
    insuranceCompany: "",
    doctorId: 0,
    bills: [],
    visits: []
  };

  clinicServices: ClinicService[] = [];
  visitClinicServices: VisitClinicService[] = [];
 
  visitClinicService: VisitClinicService = {
    billClinicServiceId: 0,
    visitId: 0, //filled through route
    billId: 0, //filled through route
    clinicServiceId: 0, // from table
    toothId: 0, // from table 
    specialDiscount: 0, // from table 
    serviceProgress: 0, // from table 
    visitProgress: 0, //  from input table 
    doctorFees: 0 // in server
  };

  showSpinner = true;
   

  constructor(
		private route: ActivatedRoute,
		private router: Router,
    private billService: BillService,
    private patientService: PatientService,
    private doctorService: DoctorService,
    private visitService: VisitService,
    private medicalProfileService: MedicalProfileService,
    private billClinicServiceService: BillClinicServiceService,
    private visitClinicServiceService: VisitClinicServiceService,
    private clinicServiceService: VisitClinicServiceService,
    private toaster: ToastrService,
    private authService: AuthService) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
    this.route.params.subscribe(p => {
      this.savePatient.id = +p['patientId'] || 0;
      this.saveVisit.id = +p['visitId'] || 0;
		});
	}

  ngOnInit() {

    this.billClinicServices.forEach(srv => {
      srv.billClinicService = {
        billId: 0,
        clinicServiceId: 0,
        toothId: 0,
        servicePrice: 0,
        serviceProgress: 0,
        specialDiscount: 0,
        note: ""
      };
      srv.doctorServiceProgress = [];
    });

    this.visitService.getVisitAccess(this.saveVisit.id).subscribe(x => {
      this.initialize();
    }, error => {
        this.toaster.error('Not Allowed', error.error);
        this.router.navigateByUrl('/mainView');
    });

   

  }

  initialize() {
    this.userRoleValue = this.authService.getRoleValue();

    this.sources = [
      this.doctorService.getDoctors(),
    ];

    if (this.savePatient.id) {
      this.sources.push(this.patientService.getPatient(this.savePatient.id));

    }
    if (this.saveVisit.id) {
      this.sources.push(this.visitService.getVisit(this.saveVisit.id));
    }

    Observable.forkJoin(this.sources).subscribe(data => {
      this.setDoctors(data[0]);

      if (this.savePatient.id) {
        this.setSavePatient(data[1]);
      }
      if (this.saveVisit.id) {
        this.setSaveVisit(data[2]);
      }
    },
      error => console.log(),
      () => this.showSpinner = false);

    this.medicalProfileService.getMedicalProfileByPatietnId(this.savePatient.id).subscribe(data => {
      //Chech if there is a Medical Profile or not.
      if (data != null) {
        this.setMedicalProfile(data);
        this.isMedicalProfileExists = true;
      }

    });
  }


  private setSaveVisit(SaveVisit: any) {
    this.saveVisit.id = SaveVisit.id;
    this.saveVisit.doctorId = SaveVisit.doctorId;
    this.saveVisit.diagnosis = SaveVisit.diagnosis;
    this.saveVisit.patientId = SaveVisit.patientId;
    this.saveVisit.billId = SaveVisit.billId;
    this.saveVisit.status = SaveVisit.status;
    this.saveVisit.type = SaveVisit.type;
    this.saveVisit.date = SaveVisit.date;
    this.saveVisit.visitClinicServices = SaveVisit.clinicServices;
    this.saveVisit.patient = SaveVisit.patient;
  }

  private setSavePatient(savePatient: any) {
    this.savePatient.id = savePatient.id;
    this.savePatient.name = savePatient.name;
    this.savePatient.address = savePatient.address;
    this.savePatient.mobile = savePatient.mobile;
    this.savePatient.age = savePatient.age;
    this.savePatient.address = savePatient.address;
    this.savePatient.gender = savePatient.gender;
    this.savePatient.occupation = savePatient.occupation;
    this.savePatient.joiningDate = savePatient.joiningDate;
    this.savePatient.email = savePatient.email;
    this.savePatient.nationality = savePatient.nationality;
    this.savePatient.referral = savePatient.referral;
    this.savePatient.insuranceCompany = savePatient.insuranceCompany;
    this.savePatient.doctorId = savePatient.doctorId;
    this.savePatient.doctorName = savePatient.doctorName;
    this.savePatient.bills = savePatient.bills;
    this.savePatient.visits = savePatient.visits;
    this.setBills(this.savePatient.bills);
    this.setVisits(this.savePatient.visits);
  }


  private setDoctors(doctors: any) {
    this.doctors = doctors;
  }


  private setMedicalProfile(medicalProfile: any) {
    this.medicalProfileId = medicalProfile.id;
  }

  private setBills(bills: any) {
    //I have done this code in this way to get the list of Bills for this patient so I have collect all httpRequests in the "billSource"
    //then I have called all with frokJoin and the data that I got I filled with it the bills.

    // TODO -- Try to minimize the code here as its repeated in setBills and setVisits
    var billSources = [];
    for (let i = 0; i < bills.length; i++) {
      billSources.push(this.billService.getBill(bills[i]));
    }

    Observable.forkJoin(billSources).subscribe(data => {
      this.setBillList(data);
      this.billClinicServiceService.getBillClinicServicesByBillId(this.setOpenedBillId(data)).subscribe(data => {
        this.setBillClinicServices(data)
      });
    });
  }

  private setBillList(b: any) {
    this.bills = b;
  }

  private setOpenedBillId(b: any ) {

    for (let i = 0; i <= b.length; i++) {
      if (b[i].status == "Open") {
        this.openedBillId = b[i].id;
        this.openedBill = b[i];
        return this.openedBillId;
      }
    }

  }

  private setBillClinicServices(b: any) {
    if (b.length > 0) {
      for (var i = 0; i < b.length; i++) {
        if (b[i].billClinicService.clinicService.isMedical) 
          this.billClinicServices.push(b[i]);
      }

    }
  }


  private setVisits(visits: any) {

    // TODO -- Try to minimize the code here as its repeated in setBills and setVisits
    var visitSources = [];
    for (let i = 0; i < visits.length; i++) {
      visitSources.push(this.visitService.getVisit(visits[i]));
    }

    Observable.forkJoin(visitSources).subscribe(data => {
      this.setVisitList(data);
    });
  }

  private setVisitList(v: any) {
    this.visits = v;
  }


  saveDiagnosis() {
    this.visitService.updateVisit(this.saveVisit).subscribe(x => {
      this.toaster.success('Diagnosis.', 'Update successfully.');
    });
  }

  //this method is to inhibit Service Modal to be loaded unless the button for it is pressed
  // and this to avoid having an error due to id not defined for bill. 
  activateServiceModal() {
    this.isServiceModalActivated = true;
  }

  exit() {
    this.billClinicServices = [];
    // to update the bill clinic services
    this.setBills(this.savePatient.bills);

    //to update the visit clinic services
    if (this.refreshVisitServiceToggle == null)
      this.refreshVisitServiceToggle = false;
    if (this.refreshVisitServiceToggle != null)
      this.refreshVisitServiceToggle = !this.refreshVisitServiceToggle;


    window.location.reload();
  }

  navTabToggle(tabId: any) {
    this.tabId = tabId;
  }

}
