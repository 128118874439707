import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportService } from '../services/report.service';
import { ReportResult } from '../models/reportResult';
import { Expense } from '../models/expense';
import { FilterDates } from '../models/filterDates';
import { FrontEndService } from '../services/fronEnd.service';


@Component({
	selector: 'report',
  templateUrl: './report.component.html',
  styleUrls:['./report.component.css']
})

export class ReportComponent implements OnInit {

  reportResults: ReportResult = {
    expenses: [],
    patientPayments: [],
    labPayments: [],
    doctorSalaries: [],
    visits: [],
    totalExpenses: 0,
    totalPatientPayments: 0,
    totalLabPayments: 0,
    totalDoctorSalaries: 0,
    VisitsCount: 0
  };
    

  filterDates: FilterDates = {
    id:0,
    startDateDay: 0,
    startDateMonth: 0,
    startDateYear: 0,
    endDateDay: 0,
    endDateMonth: 0,
    endDateYear: 0,
  }

  today = new Date();
  dailyDate = new Date();
  startDate = new Date();
  calenderStartDate: any;
  calenderEndDate: any;
  endDate = new Date();
  
  details: string = "visitDetails"; // holds the details category to be displayed: expenses, payments or visits
  showDetails: boolean = false;
  showText: string = 'Show'; // used to hold either 'Show' or 'Hide'
  showSpinner = true;
  isDayPicked: boolean = false;

  constructor(private reportService: ReportService,
              private frontEndService: FrontEndService) { }

  ngOnInit()
  {
    this.showSpinner = false;
  }

  initalize() {
    this.reportResults = {
      expenses: [],
      patientPayments: [],
      labPayments: [],
      doctorSalaries: [],
      visits: [],
      totalExpenses: 0,
      totalPatientPayments: 0,
      totalLabPayments: 0,
      totalDoctorSalaries: 0,
      VisitsCount: 0
    };
    this.showDetails = false;
    this.showText = 'Show';
    this.details = '';
  }

  runReport(strDate: Date, endDate: Date) {
    this.showSpinner = true;
    var tempstart = new Date(strDate);
    var tempend = new Date(endDate);
   
    this.formFilterDates(tempstart, tempend);

    this.reportService.getFilteredReports(this.filterDates).subscribe(
      result => {
        this.reportResults = result;
        this.showSpinner = false
      },
      error => this.showSpinner = false,
      () => {
        this.calculate();
        this.showSpinner = false;
      });
  }


  formFilterDates(strDate: Date, endDate: Date) {
    //This function forms the start/end filter dates to be sent through api to backend
    this.filterDates.startDateDay = strDate.getUTCDate();
    this.filterDates.startDateMonth = strDate.getUTCMonth() + 1;
    this.filterDates.startDateYear = strDate.getFullYear();
    this.filterDates.endDateDay = endDate.getUTCDate();
    this.filterDates.endDateMonth = endDate.getUTCMonth() + 1;
    this.filterDates.endDateYear = endDate.getFullYear();
  }

  calculate(): void {
    //This function calculates the total expenses/payments and visits count
    for (var i = 0; i < this.reportResults.expenses.length; i++) {
      this.reportResults.totalExpenses += this.reportResults.expenses[i].amount;
    }

    for (var i = 0; i < this.reportResults.patientPayments.length; i++) {
      this.reportResults.totalPatientPayments += this.reportResults.patientPayments[i].amount;
    }

    for (var i = 0; i < this.reportResults.labPayments.length; i++) {
      this.reportResults.totalLabPayments += this.reportResults.labPayments[i].paymentAmount;
    }

    for (var i = 0; i < this.reportResults.doctorSalaries.length; i++) {
      this.reportResults.totalDoctorSalaries += this.reportResults.doctorSalaries[i].salaryAmount;
    }

    this.reportResults.VisitsCount = this.reportResults.visits.length;
  }

  show() {
    this.showDetails = !this.showDetails;
    if (this.showText == 'Show')
    {
      this.showText = 'Hide';
      this.details = 'expDetails';
    }
    else { this.showText = 'Show';}
  }

  datePicked() {
    var fromDate;
    var toDate;
    fromDate = document.getElementById("startDate");
    toDate = document.getElementById("endDate");
    this.startDate = fromDate.value;
    this.endDate = toDate.value;
    this.isDayPicked = true;
  }

  print() {
    window.print();
  }
}


