import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { ToastrService } from 'ngx-toastr';


import { DoctorSalaryService } from '../services/doctorSalary.service';
import { DoctorService } from '../services/doctor.service';
import { FrontEndService } from '../services/fronEnd.service';

import { DoctorSalary, SaveDoctorSalary } from '../models/doctorSalary';
import { SaveDoctor } from '../models/doctor';
import { BillDoctorEligiblePaymentsService } from '../services/billDoctorEligiblePayments.service';
import { DoctorBillExpense } from '../models/DoctorBillExpense';
import { SalaryBillDistribution } from '../models/SalaryBillDistribution';
import { DoctorSalarywithBillsDistribution } from '../models/doctorSalarywithBillsDistribution';

@Component({
  selector: 'doctorSalary-form',
  templateUrl: './doctorSalary-form.component.html',
  styleUrls: ['./doctorSalary-form.component.css']
})


export class DoctorSalaryFormComponent implements OnInit {

  isEditForm: boolean = false; // to identify either adding new enmployee / editing employee
  isSalaryValid: boolean = false;
  isDayPicked: boolean = false; // is used in new Doctor salary.

  calenderDate: any;

  doctors: SaveDoctor[];

  doctorSalary: DoctorSalary = {
    doctorId: 0,
    paymentDate: new Date(),
    salaryAmount: 0
  };
  saveDoctorSalary: SaveDoctorSalary = {
    id : 0,
    doctorId: 0,
    paymentDate: new Date(),
    salaryAmount: 0
  };
  sources: any[];

  doctorBillExpenses: DoctorBillExpense[] = [];
  salaryBillDistributions: SalaryBillDistribution[] = [];
  doctorSalarywithBillsDistribution: DoctorSalarywithBillsDistribution = {
    salary: {
      doctorId: 0,
      paymentDate: new Date(),
      salaryAmount:0
    },
    salaryBilldistributions : []
  }


  formH1Text = "";
  showSpinner = true;
  showSalaryDist = false;

	constructor(
    private route: ActivatedRoute,
    private doctorSalaryService: DoctorSalaryService,
    private doctorService: DoctorService,
    private billDoctorEligiblePaymentsService: BillDoctorEligiblePaymentsService,
    private frontEndService: FrontEndService,
    private toaster: ToastrService,
    private router: Router) {

		// this route to get the Id from the route or set it to zero to be able 
		// to add new element without "|| 0" I will not be able to add new patient
		// because id will be set to null and the server will feedback with error.
		this.route.params.subscribe(p => {
      this.saveDoctorSalary.id = +p['id'] || 0;
		});
	}

  ngOnInit() {

    this.sources = [
      this.doctorService.getDoctors(),
    ];

    if (this.saveDoctorSalary.id) {
      this.isEditForm = true;
      this.sources.push(this.doctorSalaryService.getDoctorSalary(this.saveDoctorSalary.id));
    }

    Observable.forkJoin(this.sources).subscribe(data => {
      this.setDoctors(data[0]);

      if (this.saveDoctorSalary.id) {
        this.setSaveDoctorSalary(data[1]);
        this.formH1Text = "Edit Doctor Salary";
      }
      else {
        this.formH1Text = "Add New Doctor Salary";
      }
    },
      error => console.log(error),
      () => this.showSpinner = false);
  }

  private setDoctors(doctors: any) {
    this.doctors = doctors;
  }

  submit() {
    // Check if its existing patient so we are updating it
    if (this.saveDoctorSalary.id) {
      this.doctorSalaryService.updateDoctorSalary(this.saveDoctorSalary).subscribe(x => {
        this.toaster.success('Edit salary!', 'Update successfully.');
        this.router.navigateByUrl('/doctorSalaries');
      }, error => {
        this.toaster.error('Edit salary!', error.error);
      });
    }
    // Check if its new patient so we are adding it
    else {

      this.setDoctorSalary(this.saveDoctorSalary);
      this.setSalaryBillDistributions();
      this.doctorSalaryService.createDoctorSalary(this.doctorSalarywithBillsDistribution).subscribe(x => {
        this.toaster.success('Add salary!', 'Add successfully.');
        this.router.navigateByUrl('/doctorSalaries');
      }, error => {
        this.toaster.error('Add salary!', error.error);
        });
      
    }
  }

  private setSaveDoctorSalary(saveDoctorSalary: any) {

    this.saveDoctorSalary.id = saveDoctorSalary.id;
    this.saveDoctorSalary.doctorId = saveDoctorSalary.doctorId;
    this.calenderDate =  this.frontEndService.formatBkEndDate(saveDoctorSalary.paymentDate);
    this.saveDoctorSalary.paymentDate = saveDoctorSalary.paymentDate;
    this.saveDoctorSalary.salaryAmount = saveDoctorSalary.salaryAmount;

  }

  private setDoctorSalary(saveDoctorSalary: SaveDoctorSalary) {

    this.doctorSalary.doctorId = saveDoctorSalary.doctorId;
    this.doctorSalary.paymentDate = saveDoctorSalary.paymentDate;
    this.doctorSalary.salaryAmount = saveDoctorSalary.salaryAmount;
    this.doctorSalarywithBillsDistribution.salary = this.doctorSalary;
  }

  private setSalaryBillDistributions() {
    this.doctorBillExpenses.forEach(exp => {
      if (exp.newPaidAmount != 0) {
        var item: SalaryBillDistribution = {
          billId: exp.billId,
          doctorId: exp.doctorId,
          salaryId: 0,
          PaidAmount: exp.newPaidAmount
        }
        this.salaryBillDistributions.push(item);
      }
    });
    this.doctorSalarywithBillsDistribution.salaryBilldistributions = this.salaryBillDistributions;
  }

  validateSalary() {
    var input;
    input = document.getElementById("salaryAmount");

    if (input.value <= 0 || input.value == null || input.value == undefined || input.value.length == 0)
      this.isSalaryValid = false;
    else
      this.isSalaryValid = true;
  }

  datePiced() {
    var input;
    input = document.getElementById("doctorSalaryDate");
    this.saveDoctorSalary.paymentDate = input.value;
    this.isDayPicked = true;
  }

  getDoctorExpenses() {
    this.showSpinner = true;
    this.billDoctorEligiblePaymentsService.getBillDoctorEligiblePaymentsByDoctorId(this.saveDoctorSalary.doctorId).subscribe(
      data => {
        this.doctorBillExpenses = data;
        this.showSalaryDist = true;
      },
      error => console.log(error),
      () => {
        this.showSpinner = false;
        this.setInitialNewPaidAmount();
      }
    );

  }

  setInitialNewPaidAmount() {
    this.saveDoctorSalary.salaryAmount = 0;
    this.doctorBillExpenses.forEach(bill => {
      if ((bill.drBillEligiblePayment - bill.drBillPaidAmount) >= 0) {
        bill.newPaidAmount = bill.drBillEligiblePayment - bill.drBillPaidAmount;
      }
      else {
        bill.newPaidAmount = 0;
      }
      this.saveDoctorSalary.salaryAmount = this.saveDoctorSalary.salaryAmount + bill.newPaidAmount;
    });  
  }

  updateTotalSalaryAmount() {
    this.saveDoctorSalary.salaryAmount = 0;
    this.doctorBillExpenses.forEach(bill => {
       
      this.saveDoctorSalary.salaryAmount = Number(this.saveDoctorSalary.salaryAmount) + Number(bill.newPaidAmount);
    
    });
  }
}
