import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IdentityRoleUsers } from '../models/identityRoleUsers';
import { IdentityRoleUsersEdit } from '../models/identityRoleUsersEdit';


@Injectable()
export class IdentityRoleUsersService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getIdentityRoleUsers(id: string) {
    return this.http.get<IdentityRoleUsers>(this.baseUrl + 'api/identity/roles/users' + '/' + id);
	}

  updateIdentityRoleUsers(identityRoleUsersEdit: IdentityRoleUsersEdit) {
    return this.http.put<IdentityRoleUsersEdit>(this.baseUrl + 'api/identity/roles/users', identityRoleUsersEdit);
	}
}
