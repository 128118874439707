import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  ViewEncapsulation, // Used to enable changing the CSS of the elements. 
  OnInit,
  Input,
  Output,
  EventEmitter,

  SimpleChanges
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
  DAYS_OF_WEEK,
  CalendarMonthViewDay,

  CalendarDateFormatter
} from 'angular-calendar';

import { SaveVisit } from '../models/visit';
import { VisitService } from '../services/visit.service';
import { skip } from 'rxjs/operators';
import { parse } from 'url';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { FilterDates } from '../models/filterDates';
import { CustomDateFormatter } from './custom-date-formatter.provider';



const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};



@Component({
  selector: 'calender',
  changeDetection: ChangeDetectionStrategy.OnPush, // Used to enable changing the CSS of the elements.
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
  ],
  styleUrls: ['./calender.css'],
  templateUrl: './calender.html',
  encapsulation: ViewEncapsulation.None // Used to enable changing the CSS of the elements.
})

export class CalenderComponent implements OnInit {

  // the following elemet shoud be initialized to be able to .push()
  _doctorWorkingDays: number[] = [];

  filterDates: FilterDates = {
    id:0,
    startDateDay: 0,
    startDateMonth: 0,
    startDateYear: 0,
    endDateDay: 0,
    endDateMonth: 0,
    endDateYear: 0,
  }

  showSpinner = true;

  @Input() visitDate: Date;
  @Input() amIChildOfVisit: boolean = false;
  @Input() amIChildOfMainView: boolean = false;
  @Input() refreshCalender: boolean;
  @Input() moveFromCalender: boolean;
  @Input('doctorWorkingDays') set doctorWorkingDays(doctorWorkingDays: string[]) {
    for (var i = 0; i < doctorWorkingDays.length; i++)
    {
      if (doctorWorkingDays[i] != ',') {
        //var day: number = parseInt(doctorWorkingDays[i]);
        this._doctorWorkingDays.push(parseInt(doctorWorkingDays[i]));
      }
    }
   
  };

  @Output() dayPicked = new EventEmitter<Date>();

  public visits: SaveVisit[];
  
  constructor(private visitService: VisitService,
              private authService: AuthService,
              private router: Router ) { }

  ngOnInit() {
    this.getMonthVisits();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.refreshCalender == true) {
      this.getMonthVisits();
      this.refreshCalender = !this.refreshCalender;
    }
    // only run when property "data" changed
    if (changes['refreshCalender']) {
 
    }

    if (this.moveFromCalender == true) {
      if (this.amIChildOfMainView) {
        var cell = document.getElementsByClassName('cal-visitDate')[0];
        cell.classList.remove('cal-visitDate');
      }
    }
  }

  getMonthVisits() {
    this.emptyEvents();
    this.filterDates.startDateMonth = this.viewDate.getUTCMonth() + 1;
    this.visitService.setVisitsDate(this.filterDates);
    this.events.toLocaleString;
    if (this.authService.getRoleValue() == 3) {
      this.visitService.getDoctorMonthlyVisits().subscribe(result => {
        this.visits = result;
        this.fillEvents(this.visits);
      },
        error => console.log(error),
        () => this.showSpinner = false)
    }
    else {
      this.visitService.getMonthlyVisits().subscribe(result => {
        this.visits = result;
        this.fillEvents(this.visits);
      },
        error => console.log(error),
        () => this.showSpinner = false)
    }
  }

  //@ViewChild('modalContent', { read: true }) modalContent: TemplateRef<any>;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fa fa-fw fa-pencil"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      }
    },
    {
      label: '<i class="fa fa-fw fa-times"></i>',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter(iEvent => iEvent !== event);
        this.handleEvent('Deleted', event);
      }
    }
  ];

  refresh: Subject<any> = new Subject();

  events: CalendarEvent[] = [];
    /*
    {
      start: subDays(startOfDay(new Date()), 1),
      end: addDays(new Date(), 1),
      title: 'A 5 day event',
      color: colors.red,
      actions: this.actions,
      allDay: true,
      resizable: {
        beforeStart: true,
        afterEnd: true
      },
      draggable: true
    },
    {
      start: startOfDay(new Date()),
      title: 'An event with no end date',
      color: colors.yellow,
      actions: this.actions
    },
    {
      start: subDays(endOfMonth(new Date()), 3),
      end: addDays(endOfMonth(new Date()), 3),
      title: 'A long event that spans 2 months',
      color: colors.blue,
      allDay: true
    },
    {
      start: addHours(startOfDay(new Date()), 2),
      end: new Date(),
      title: 'A draggable and resizable event',
      color: colors.yellow,
      actions: this.actions,
      resizable: {
        beforeStart: true,
        afterEnd: true
      },
      draggable: true
    }
  ];
   */

  //excludeWeekEnd
  excludeDays: number[] = [];
  weekStartsOn = DAYS_OF_WEEK.SUNDAY;

  activeDayIsOpen: boolean = false;

 

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      //this line it to send date to visitForm 
      this.dayPicked.emit(date);

      //Comment this part to disable active day events to show on the calender
      /*
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      } */

      this.viewDate = date;
    }

    if (!this.amIChildOfVisit && !this.amIChildOfMainView) {
      this.filterDates.startDateDay = date.getUTCDate() + 1;
      this.filterDates.startDateMonth = date.getUTCMonth() + 1;
      this.filterDates.startDateYear = date.getFullYear();

      this.visitService.setVisitsDate(this.filterDates);

      if (this.authService.getRoleValue() == 2) {
        this.router.navigateByUrl('/visits/myVisits');
      }
      else {
        this.router.navigateByUrl('/visits/thisDayVisits');
      }
    }
    
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map(iEvent => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    //this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true
        }
      }
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(day => {
      //to validate if the view is called from visit 
      //if (this.visitDate != null) {
      if (this.amIChildOfVisit != false) {
        
        //check that doctor is selected
        //if (this.doctorWorkingDays.length < 0)
          

        //console.log(this._doctorWorkingDays[1]);
        
       var isDayOn = false;
        // Disable non-DoctorWorkingDays
        for (var i = 0; i < this._doctorWorkingDays.length; i++) {
          
          // if I used (day.day != this._doctorWorkingDays[i]) ut doesn't work
          // if I used (!(day.day != this._doctorWorkingDays[i])) it work
          // and this is because the array is passed through component as a string not and array 
          if (day.day == this._doctorWorkingDays[i]) {
            isDayOn = true;
            skip;
          }  
        }

        if (!isDayOn) {
          day.cssClass = 'cal-disabled';
        }

        //Comparing date needs 1- to reset the time , 2- to get the compare value in a variable then use it in the if condition.
        var d1 = new Date(day.date);
        var d2 = new Date();
        d1.setHours(0, 0, 0, 0);
        d2.setHours(0, 0, 0, 0);

        var before = d1.getTime() < d2.getTime(); 

        if (before) {
          day.cssClass = 'cal-disabled';
        }
          
      }

      // to mark the selected day with green color in the visit view
      // and to initialize the day with green color in case we are editing a visit 
      if (this.amIChildOfVisit != false) {
        this.setTheSelectedDayCssCLass(day, this.visitDate);
      }

      // to mark the selected day with a green colro in main view
      if (this.amIChildOfMainView) {
        this.setTheSelectedDayCssCLass(day, this.viewDate);
      }
      
    });
  }

  setTheSelectedDayCssCLass(day: CalendarMonthViewDay, selectedDate : Date) {
    var d1 = new Date(day.date);
    var d2 = new Date(selectedDate);

    //Comparing date needs 1- to reset the time , 2- to get the compare value in a variable then use it in the if condition.
    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);
    var same = d1.getTime() === d2.getTime();
    if (same) {
      day.cssClass = 'cal-visitDate';
    }
  }


  fillEvents(visits: any) {
    var i;
    for (i = 0; i < this.visits.length; i++) {
      
      this.events.push({
        title: "Visit ID: " + this.visits[i].id + ", Visit Status: " + this.visits[i].status,
        start: startOfDay(this.visits[i].date),        
        resizable: {
          beforeStart: true,
          afterEnd: true
        },
        draggable: true,
        color: colors.blue,
        //actions: this.actions,
        allDay: false
      });
    }
    this.refresh.next();
  }

  emptyEvents() {
    this.events.length = 0; // Empty the events list
  }
}



