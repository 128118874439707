import { Component, NgModule, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/observable/forkJoin';

import { SaveLab } from '../models/lab';
import { LabService } from '../services/lab.service';
import { LabServiceService } from '../services/labService.service';
import { LabPaymentService } from '../services/labPayment.service';
import { SaveLabPayment } from '../models/labPayment';
import { SaveLabRequest } from '../models/labRequest';



@Component({
  selector: 'labExpenses',
  templateUrl: './labExpenses.component.html',
  styleUrls: ['./labExpenses.component.css']
})

export class LabExpensesComponent implements OnInit {

  labId: number = 0;
  labs: SaveLab[];


  labPayments: SaveLabPayment[];
  labRequests: SaveLabRequest[];
  
  labTotalPayments: number = 0;
  labTotalFees: number = 0;
  labBalance: number = 0;

  sources: any[];
  details: string; // holds the details category to be displayed: expenses or payments
 

  constructor(
    private route: ActivatedRoute,
    private labService: LabService,
    private labServiceService: LabServiceService,
    private labPaymentService: LabPaymentService)
  {
    this.route.params.subscribe(p => {
      this.labId = +p['id'] || 0;
    });

  }

  showSpinner = true;

  ngOnInit() {
    this.details = "expDetails";
    this.labService.getLabs().subscribe(result => {
      this.labs = result;
    },
      error => console.log(error),
      () => this.showSpinner = false);

    if (this.labId != 0) {
      this.showSpinner = true;
      this.fillLabsLists(this.labId);
    }
  }

  fillLabsLists(id) {
    this.sources = [
      this.labServiceService.getLabServiceByLab(id),
      this.labPaymentService.getPaymentsByLab(id)
    ];
   
    Observable.forkJoin(this.sources).subscribe(data => {
      this.setLabRequests(data[0]);
      this.setLabPayments(data[1]);
      this.setLabBalance(data[0], data[1]);
    },
      error => console.log(error),
      () => this.showSpinner = false);
  }
  setLabBalance(fees: any, payments: any) {

      if (payments.length > 0) {
        for (var i = 0; i < payments.length; i++) {
          this.labTotalPayments += payments[i].paymentAmount;
        }
      }
      else {
        this.labTotalPayments = 0;
      }

      if (fees.length > 0) {
        for (var i = 0; i < fees.length; i++) {
          this.labTotalFees += fees[i].labPrice;
        }
      }
      else {
        this.labTotalFees = 0;
      }

    this.labBalance = this.labTotalPayments - this.labTotalFees;
     

    }

  setLabPayments(payments : any) {
    this.labPayments = payments;
  }

  setLabRequests(requests : any) {
    this.labRequests = requests;
  }

  labChanged(id) {
    this.labTotalPayments = 0;
    this.labTotalFees= 0;
    this.labBalance = 0;
    this.labPayments = [];
    this.labRequests = [];
    this.fillLabsLists(id);
  }

  print() {
    window.print();
  }

}


