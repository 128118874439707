import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Patient, SavePatient } from '../models/patient';
import { Pagination } from '../models/pagination';

@Injectable()
export class PatientService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }


  getPatients() {
    return this.http.get<SavePatient[]>(this.baseUrl + 'api/patients');
  }

  getSomePatients(pagination: Pagination) {
    return this.http.get<SavePatient[]>(this.baseUrl + 'api/patients' + '/' + '?' + this.toQueryString(pagination));
  }

  toQueryString(obj: any) {
    var parts = [];
    for (var property in obj) {
      var value = obj[property];
      if (value != null && value != undefined)
        parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value));
    }
    return parts.join('&');
  }

	getPatient(id: number) {
      return this.http.get<SavePatient>(this.baseUrl + 'api/patients' + '/' + id);
	}

  createPatient(patient: Patient) {
    return this.http.post<SavePatient>(this.baseUrl + 'api/patients', patient);
	}

  updatePatient(savePatient: SavePatient) {
    return this.http.put<SavePatient>(this.baseUrl + 'api/patients' + '/' + savePatient.id, savePatient);
	}
}
